import "./Loader.css";

import { useEffect, useState } from "react";

import {
  DefaultLoader,
  FullscreenLoader,
  LoaderColorType,
  LoaderVariantType,
  SpinnerBase,
  SpinnerDot,
} from "./Loader.style";

function Spinner({ color, variant }: { color?: LoaderColorType; variant?: LoaderVariantType }) {
  return (
    <SpinnerBase role="alert" variant={variant}>
      <SpinnerDot
        data-animation-bounce
        color={color}
        style={{
          animationDelay: "-0.32s",
        }}
      />
      <SpinnerDot
        data-animation-bounce
        color={color}
        style={{
          animationDelay: "-0.16s",
        }}
      />
      <SpinnerDot data-animation-bounce color={color} />
    </SpinnerBase>
  );
}

export function Loader({
  variant,
  color,
  delay = variant === "fullScreen" ? 0 : 100,
}: {
  variant?: LoaderVariantType;
  color?: LoaderColorType;
  delay?: number;
}) {
  const [pastDelay, setPassedDelay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setPassedDelay(true), delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  if (!pastDelay) {
    return null;
  }

  if (variant === "fullScreen") {
    return (
      <FullscreenLoader>
        <Spinner color={color} variant={variant} />
      </FullscreenLoader>
    );
  }

  if (variant === "small") {
    return <Spinner color={color} variant={variant} />;
  }

  //regular and default
  return (
    <DefaultLoader>
      <Spinner color={color} variant={variant} />
    </DefaultLoader>
  );
}
