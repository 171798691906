/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Blox Client API
 * API specification for Blox Client API.
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  BankAccountResponse,
  CheckVersionRequest,
  CheckVersionResponse,
  CreateOrderRequest,
  CreateOrderResponse,
  DropInPaymentMethodsRequest,
  DropInPaymentMethodsResponse,
  DropInPaymentRequest,
  DropInPaymentResponseDto,
  FeatureResponse,
  FinancialOverviewsResponse,
  GetBankAccountsParams,
  GetMarketsPeriodsParams,
  GetOrdersParams,
  GetUserPreferences200,
  IdentityProvider,
  LedgerResponse,
  MarketsGraphResponse,
  MarketsResponse,
  MisnapData,
  OrdersResponse,
  PaymentResult,
  PersonalVoucher,
  PersonalVoucherConfigResponse,
  QuoteRequest,
  QuoteResponse,
  RedeemVoucherParams,
  TradedCoinsResponse,
  UnitPricesResponse,
  UpdateUserPreferences200,
  UpdateUserPreferencesBody,
  VerificationGetResponse,
  VerificationPostResponse,
  VerificationTokenResponse,
  VersionResponse,
  VouchersRevenueResponse
} from './model'
import { apiInstance } from '../../lib/api-instance';
import type { ErrorType } from '../../lib/api-instance';



              // @ts-ignore
              import { Updater, SetDataOptions, QueryFilters } from "@tanstack/react-query";
              // @ts-ignore
              import { queryClient } from "~queryClient";

              type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



;
            export const redeemVoucherMutation = (
    voucher: string,
    params?: RedeemVoucherParams,
 ) => {
      
      
      return apiInstance<void>(
      {url: `/vouchers/redeem/${voucher}`, method: 'POST',
        params
    },
      );
    }
  


export const getRedeemVoucherMutationMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof redeemVoucherMutation>>, TError,{voucher: string;params?: RedeemVoucherParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof redeemVoucherMutation>>, TError,{voucher: string;params?: RedeemVoucherParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof redeemVoucherMutation>>, {voucher: string;params?: RedeemVoucherParams}> = (props) => {
          const {voucher,params} = props ?? {};

          return  redeemVoucherMutation(voucher,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RedeemVoucherMutationMutationResult = NonNullable<Awaited<ReturnType<typeof redeemVoucherMutation>>>
    
    export type RedeemVoucherMutationMutationError = ErrorType<void>

    export const useRedeemVoucherMutation = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof redeemVoucherMutation>>, TError,{voucher: string;params?: RedeemVoucherParams}, TContext>, }
) => {

      const mutationOptions = getRedeemVoucherMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    

              export const getPersonalVoucherQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<PersonalVoucher>(
      {url: `/vouchers/generate`, method: 'GET', signal
    },
      );
    }
  

export const getGetPersonalVoucherQueryQueryKey = () => {
    return [`/vouchers/generate`] as const;
    }

    
export const getGetPersonalVoucherQueryQueryOptions = <TData = Awaited<ReturnType<typeof getPersonalVoucherQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPersonalVoucherQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPersonalVoucherQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPersonalVoucherQuery>>> = ({ signal }) => getPersonalVoucherQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPersonalVoucherQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPersonalVoucherQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getPersonalVoucherQuery>>>
export type GetPersonalVoucherQueryQueryError = ErrorType<void>

export const useGetPersonalVoucherQuery = <TData = Awaited<ReturnType<typeof getPersonalVoucherQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPersonalVoucherQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPersonalVoucherQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetPersonalVoucherQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getPersonalVoucherQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPersonalVoucherQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPersonalVoucherQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPersonalVoucherQuery>>> = ({ signal }) => getPersonalVoucherQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPersonalVoucherQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPersonalVoucherQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPersonalVoucherQuery>>>
export type GetPersonalVoucherQuerySuspenseQueryError = ErrorType<void>

export const useGetPersonalVoucherQuerySuspense = <TData = Awaited<ReturnType<typeof getPersonalVoucherQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPersonalVoucherQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPersonalVoucherQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetPersonalVoucherQuery.queryKey = () => getGetPersonalVoucherQueryQueryKey();

    useGetPersonalVoucherQuery.updateCache = ({updater, options}: {updater: Updater<PersonalVoucher | undefined, PersonalVoucher | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<PersonalVoucher>(getGetPersonalVoucherQueryQueryKey(), updater, options);
    
    useGetPersonalVoucherQuery.getQueryState = () => queryClient.getQueryState<PersonalVoucher>(getGetPersonalVoucherQueryQueryKey());
    
    useGetPersonalVoucherQuery.getQueryData = () => queryClient.getQueryData<PersonalVoucher>(getGetPersonalVoucherQueryQueryKey());
  
    useGetPersonalVoucherQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetPersonalVoucherQueryQueryKey() , queryFn: () => getPersonalVoucherQuery() });
  
    useGetPersonalVoucherQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetPersonalVoucherQueryQueryKey() })
  
    useGetPersonalVoucherQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetPersonalVoucherQueryQueryKey() });
  
    useGetPersonalVoucherQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetPersonalVoucherQueryQueryKey(), stale: true });
  
            

              export const getPersonalVoucherConfigQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<PersonalVoucherConfigResponse>(
      {url: `/vouchers/personal`, method: 'GET', signal
    },
      );
    }
  

export const getGetPersonalVoucherConfigQueryQueryKey = () => {
    return [`/vouchers/personal`] as const;
    }

    
export const getGetPersonalVoucherConfigQueryQueryOptions = <TData = Awaited<ReturnType<typeof getPersonalVoucherConfigQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPersonalVoucherConfigQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPersonalVoucherConfigQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPersonalVoucherConfigQuery>>> = ({ signal }) => getPersonalVoucherConfigQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPersonalVoucherConfigQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPersonalVoucherConfigQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getPersonalVoucherConfigQuery>>>
export type GetPersonalVoucherConfigQueryQueryError = ErrorType<void>

export const useGetPersonalVoucherConfigQuery = <TData = Awaited<ReturnType<typeof getPersonalVoucherConfigQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPersonalVoucherConfigQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPersonalVoucherConfigQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetPersonalVoucherConfigQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getPersonalVoucherConfigQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPersonalVoucherConfigQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPersonalVoucherConfigQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPersonalVoucherConfigQuery>>> = ({ signal }) => getPersonalVoucherConfigQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPersonalVoucherConfigQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPersonalVoucherConfigQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPersonalVoucherConfigQuery>>>
export type GetPersonalVoucherConfigQuerySuspenseQueryError = ErrorType<void>

export const useGetPersonalVoucherConfigQuerySuspense = <TData = Awaited<ReturnType<typeof getPersonalVoucherConfigQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPersonalVoucherConfigQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPersonalVoucherConfigQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetPersonalVoucherConfigQuery.queryKey = () => getGetPersonalVoucherConfigQueryQueryKey();

    useGetPersonalVoucherConfigQuery.updateCache = ({updater, options}: {updater: Updater<PersonalVoucherConfigResponse | undefined, PersonalVoucherConfigResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<PersonalVoucherConfigResponse>(getGetPersonalVoucherConfigQueryQueryKey(), updater, options);
    
    useGetPersonalVoucherConfigQuery.getQueryState = () => queryClient.getQueryState<PersonalVoucherConfigResponse>(getGetPersonalVoucherConfigQueryQueryKey());
    
    useGetPersonalVoucherConfigQuery.getQueryData = () => queryClient.getQueryData<PersonalVoucherConfigResponse>(getGetPersonalVoucherConfigQueryQueryKey());
  
    useGetPersonalVoucherConfigQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetPersonalVoucherConfigQueryQueryKey() , queryFn: () => getPersonalVoucherConfigQuery() });
  
    useGetPersonalVoucherConfigQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetPersonalVoucherConfigQueryQueryKey() })
  
    useGetPersonalVoucherConfigQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetPersonalVoucherConfigQueryQueryKey() });
  
    useGetPersonalVoucherConfigQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetPersonalVoucherConfigQueryQueryKey(), stale: true });
  
            

              export const getPartnerRevenueQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<VouchersRevenueResponse[]>(
      {url: `/vouchers/revenue`, method: 'GET', signal
    },
      );
    }
  

export const getGetPartnerRevenueQueryQueryKey = () => {
    return [`/vouchers/revenue`] as const;
    }

    
export const getGetPartnerRevenueQueryQueryOptions = <TData = Awaited<ReturnType<typeof getPartnerRevenueQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPartnerRevenueQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPartnerRevenueQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPartnerRevenueQuery>>> = ({ signal }) => getPartnerRevenueQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPartnerRevenueQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPartnerRevenueQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getPartnerRevenueQuery>>>
export type GetPartnerRevenueQueryQueryError = ErrorType<void>

export const useGetPartnerRevenueQuery = <TData = Awaited<ReturnType<typeof getPartnerRevenueQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPartnerRevenueQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPartnerRevenueQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetPartnerRevenueQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getPartnerRevenueQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPartnerRevenueQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPartnerRevenueQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPartnerRevenueQuery>>> = ({ signal }) => getPartnerRevenueQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPartnerRevenueQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPartnerRevenueQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPartnerRevenueQuery>>>
export type GetPartnerRevenueQuerySuspenseQueryError = ErrorType<void>

export const useGetPartnerRevenueQuerySuspense = <TData = Awaited<ReturnType<typeof getPartnerRevenueQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPartnerRevenueQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPartnerRevenueQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetPartnerRevenueQuery.queryKey = () => getGetPartnerRevenueQueryQueryKey();

    useGetPartnerRevenueQuery.updateCache = ({updater, options}: {updater: Updater<VouchersRevenueResponse[] | undefined, VouchersRevenueResponse[] | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<VouchersRevenueResponse[]>(getGetPartnerRevenueQueryQueryKey(), updater, options);
    
    useGetPartnerRevenueQuery.getQueryState = () => queryClient.getQueryState<VouchersRevenueResponse[]>(getGetPartnerRevenueQueryQueryKey());
    
    useGetPartnerRevenueQuery.getQueryData = () => queryClient.getQueryData<VouchersRevenueResponse[]>(getGetPartnerRevenueQueryQueryKey());
  
    useGetPartnerRevenueQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetPartnerRevenueQueryQueryKey() , queryFn: () => getPartnerRevenueQuery() });
  
    useGetPartnerRevenueQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetPartnerRevenueQueryQueryKey() })
  
    useGetPartnerRevenueQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetPartnerRevenueQueryQueryKey() });
  
    useGetPartnerRevenueQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetPartnerRevenueQueryQueryKey(), stale: true });
  
            

              export const getBankAccountsQuery = (
    params?: GetBankAccountsParams,
 signal?: AbortSignal
) => {
      
      
      return apiInstance<BankAccountResponse[]>(
      {url: `/bankaccounts`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetBankAccountsQueryQueryKey = (params?: GetBankAccountsParams,) => {
    return [`/bankaccounts`, ...(params ? [params]: [])] as const;
    }

    
export const getGetBankAccountsQueryQueryOptions = <TData = Awaited<ReturnType<typeof getBankAccountsQuery>>, TError = ErrorType<unknown>>(params?: GetBankAccountsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBankAccountsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBankAccountsQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBankAccountsQuery>>> = ({ signal }) => getBankAccountsQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getBankAccountsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetBankAccountsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getBankAccountsQuery>>>
export type GetBankAccountsQueryQueryError = ErrorType<unknown>

export const useGetBankAccountsQuery = <TData = Awaited<ReturnType<typeof getBankAccountsQuery>>, TError = ErrorType<unknown>>(
 params?: GetBankAccountsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBankAccountsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetBankAccountsQueryQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetBankAccountsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getBankAccountsQuery>>, TError = ErrorType<unknown>>(params?: GetBankAccountsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getBankAccountsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBankAccountsQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBankAccountsQuery>>> = ({ signal }) => getBankAccountsQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getBankAccountsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetBankAccountsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getBankAccountsQuery>>>
export type GetBankAccountsQuerySuspenseQueryError = ErrorType<unknown>

export const useGetBankAccountsQuerySuspense = <TData = Awaited<ReturnType<typeof getBankAccountsQuery>>, TError = ErrorType<unknown>>(
 params?: GetBankAccountsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getBankAccountsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetBankAccountsQuerySuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetBankAccountsQuery.queryKey = (params?: GetBankAccountsParams,) => getGetBankAccountsQueryQueryKey(params,);

    useGetBankAccountsQuery.updateCache = ({params, updater, options}: {params?: GetBankAccountsParams, updater: Updater<BankAccountResponse[] | undefined, BankAccountResponse[] | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<BankAccountResponse[]>(getGetBankAccountsQueryQueryKey(params,), updater, options);
    
    useGetBankAccountsQuery.getQueryState = (params?: GetBankAccountsParams,) => queryClient.getQueryState<BankAccountResponse[]>(getGetBankAccountsQueryQueryKey(params,));
    
    useGetBankAccountsQuery.getQueryData = (params?: GetBankAccountsParams,) => queryClient.getQueryData<BankAccountResponse[]>(getGetBankAccountsQueryQueryKey(params,));
  
    useGetBankAccountsQuery.prefetch = (params?: GetBankAccountsParams,) => queryClient.prefetchQuery({ queryKey: getGetBankAccountsQueryQueryKey(params,) , queryFn: () => getBankAccountsQuery(params,) });
  
    useGetBankAccountsQuery.cancelQueries = (params?: GetBankAccountsParams,) => queryClient.cancelQueries({ queryKey: getGetBankAccountsQueryQueryKey(params,) })
  
    useGetBankAccountsQuery.invalidate = (params?: GetBankAccountsParams,) => queryClient.invalidateQueries({ queryKey: getGetBankAccountsQueryQueryKey(params,) });
  
    useGetBankAccountsQuery.refetchStale = (params?: GetBankAccountsParams,) => queryClient.refetchQueries({ queryKey: getGetBankAccountsQueryQueryKey(params,), stale: true });
  
            
/**
 * Returns a quote for the requested currency
 */
export const createQuoteMutation = (
    quoteRequest: QuoteRequest,
 ) => {
      
      
      return apiInstance<QuoteResponse>(
      {url: `/quote`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: quoteRequest
    },
      );
    }
  


export const getCreateQuoteMutationMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createQuoteMutation>>, TError,{data: QuoteRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createQuoteMutation>>, TError,{data: QuoteRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createQuoteMutation>>, {data: QuoteRequest}> = (props) => {
          const {data} = props ?? {};

          return  createQuoteMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateQuoteMutationMutationResult = NonNullable<Awaited<ReturnType<typeof createQuoteMutation>>>
    export type CreateQuoteMutationMutationBody = QuoteRequest
    export type CreateQuoteMutationMutationError = ErrorType<void>

    export const useCreateQuoteMutation = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createQuoteMutation>>, TError,{data: QuoteRequest}, TContext>, }
) => {

      const mutationOptions = getCreateQuoteMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postOrdersMutation = (
    createOrderRequest: CreateOrderRequest,
 ) => {
      
      
      return apiInstance<CreateOrderResponse>(
      {url: `/orders`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOrderRequest
    },
      );
    }
  


export const getPostOrdersMutationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrdersMutation>>, TError,{data: CreateOrderRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postOrdersMutation>>, TError,{data: CreateOrderRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrdersMutation>>, {data: CreateOrderRequest}> = (props) => {
          const {data} = props ?? {};

          return  postOrdersMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostOrdersMutationMutationResult = NonNullable<Awaited<ReturnType<typeof postOrdersMutation>>>
    export type PostOrdersMutationMutationBody = CreateOrderRequest
    export type PostOrdersMutationMutationError = ErrorType<unknown>

    export const usePostOrdersMutation = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrdersMutation>>, TError,{data: CreateOrderRequest}, TContext>, }
) => {

      const mutationOptions = getPostOrdersMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    

              export const getOrdersQuery = (
    params?: GetOrdersParams,
 signal?: AbortSignal
) => {
      
      
      return apiInstance<OrdersResponse>(
      {url: `/orders`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetOrdersQueryQueryKey = (params?: GetOrdersParams,) => {
    return [`/orders`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersQueryQueryOptions = <TData = Awaited<ReturnType<typeof getOrdersQuery>>, TError = ErrorType<unknown>>(params?: GetOrdersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersQuery>>> = ({ signal }) => getOrdersQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersQuery>>>
export type GetOrdersQueryQueryError = ErrorType<unknown>

export const useGetOrdersQuery = <TData = Awaited<ReturnType<typeof getOrdersQuery>>, TError = ErrorType<unknown>>(
 params?: GetOrdersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersQueryQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getOrdersQuery>>, TError = ErrorType<unknown>>(params?: GetOrdersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOrdersQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersQuery>>> = ({ signal }) => getOrdersQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOrdersQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersQuery>>>
export type GetOrdersQuerySuspenseQueryError = ErrorType<unknown>

export const useGetOrdersQuerySuspense = <TData = Awaited<ReturnType<typeof getOrdersQuery>>, TError = ErrorType<unknown>>(
 params?: GetOrdersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOrdersQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersQuerySuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetOrdersQuery.queryKey = (params?: GetOrdersParams,) => getGetOrdersQueryQueryKey(params,);

    useGetOrdersQuery.updateCache = ({params, updater, options}: {params?: GetOrdersParams, updater: Updater<OrdersResponse | undefined, OrdersResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<OrdersResponse>(getGetOrdersQueryQueryKey(params,), updater, options);
    
    useGetOrdersQuery.getQueryState = (params?: GetOrdersParams,) => queryClient.getQueryState<OrdersResponse>(getGetOrdersQueryQueryKey(params,));
    
    useGetOrdersQuery.getQueryData = (params?: GetOrdersParams,) => queryClient.getQueryData<OrdersResponse>(getGetOrdersQueryQueryKey(params,));
  
    useGetOrdersQuery.prefetch = (params?: GetOrdersParams,) => queryClient.prefetchQuery({ queryKey: getGetOrdersQueryQueryKey(params,) , queryFn: () => getOrdersQuery(params,) });
  
    useGetOrdersQuery.cancelQueries = (params?: GetOrdersParams,) => queryClient.cancelQueries({ queryKey: getGetOrdersQueryQueryKey(params,) })
  
    useGetOrdersQuery.invalidate = (params?: GetOrdersParams,) => queryClient.invalidateQueries({ queryKey: getGetOrdersQueryQueryKey(params,) });
  
    useGetOrdersQuery.refetchStale = (params?: GetOrdersParams,) => queryClient.refetchQueries({ queryKey: getGetOrdersQueryQueryKey(params,), stale: true });
  
            

              export const getTradedCoinsQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<TradedCoinsResponse>(
      {url: `/orders/tradedCoins`, method: 'GET', signal
    },
      );
    }
  

export const getGetTradedCoinsQueryQueryKey = () => {
    return [`/orders/tradedCoins`] as const;
    }

    
export const getGetTradedCoinsQueryQueryOptions = <TData = Awaited<ReturnType<typeof getTradedCoinsQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTradedCoinsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTradedCoinsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTradedCoinsQuery>>> = ({ signal }) => getTradedCoinsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTradedCoinsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTradedCoinsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getTradedCoinsQuery>>>
export type GetTradedCoinsQueryQueryError = ErrorType<unknown>

export const useGetTradedCoinsQuery = <TData = Awaited<ReturnType<typeof getTradedCoinsQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTradedCoinsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTradedCoinsQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTradedCoinsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getTradedCoinsQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTradedCoinsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTradedCoinsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTradedCoinsQuery>>> = ({ signal }) => getTradedCoinsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTradedCoinsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTradedCoinsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getTradedCoinsQuery>>>
export type GetTradedCoinsQuerySuspenseQueryError = ErrorType<unknown>

export const useGetTradedCoinsQuerySuspense = <TData = Awaited<ReturnType<typeof getTradedCoinsQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTradedCoinsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTradedCoinsQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetTradedCoinsQuery.queryKey = () => getGetTradedCoinsQueryQueryKey();

    useGetTradedCoinsQuery.updateCache = ({updater, options}: {updater: Updater<TradedCoinsResponse | undefined, TradedCoinsResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<TradedCoinsResponse>(getGetTradedCoinsQueryQueryKey(), updater, options);
    
    useGetTradedCoinsQuery.getQueryState = () => queryClient.getQueryState<TradedCoinsResponse>(getGetTradedCoinsQueryQueryKey());
    
    useGetTradedCoinsQuery.getQueryData = () => queryClient.getQueryData<TradedCoinsResponse>(getGetTradedCoinsQueryQueryKey());
  
    useGetTradedCoinsQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetTradedCoinsQueryQueryKey() , queryFn: () => getTradedCoinsQuery() });
  
    useGetTradedCoinsQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetTradedCoinsQueryQueryKey() })
  
    useGetTradedCoinsQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetTradedCoinsQueryQueryKey() });
  
    useGetTradedCoinsQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetTradedCoinsQueryQueryKey(), stale: true });
  
            

              export const getFinancialOverviewsQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<FinancialOverviewsResponse>(
      {url: `/financialOverviews`, method: 'GET', signal
    },
      );
    }
  

export const getGetFinancialOverviewsQueryQueryKey = () => {
    return [`/financialOverviews`] as const;
    }

    
export const getGetFinancialOverviewsQueryQueryOptions = <TData = Awaited<ReturnType<typeof getFinancialOverviewsQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFinancialOverviewsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFinancialOverviewsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFinancialOverviewsQuery>>> = ({ signal }) => getFinancialOverviewsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFinancialOverviewsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFinancialOverviewsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getFinancialOverviewsQuery>>>
export type GetFinancialOverviewsQueryQueryError = ErrorType<unknown>

export const useGetFinancialOverviewsQuery = <TData = Awaited<ReturnType<typeof getFinancialOverviewsQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFinancialOverviewsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFinancialOverviewsQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFinancialOverviewsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getFinancialOverviewsQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFinancialOverviewsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFinancialOverviewsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFinancialOverviewsQuery>>> = ({ signal }) => getFinancialOverviewsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFinancialOverviewsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFinancialOverviewsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getFinancialOverviewsQuery>>>
export type GetFinancialOverviewsQuerySuspenseQueryError = ErrorType<unknown>

export const useGetFinancialOverviewsQuerySuspense = <TData = Awaited<ReturnType<typeof getFinancialOverviewsQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFinancialOverviewsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFinancialOverviewsQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetFinancialOverviewsQuery.queryKey = () => getGetFinancialOverviewsQueryQueryKey();

    useGetFinancialOverviewsQuery.updateCache = ({updater, options}: {updater: Updater<FinancialOverviewsResponse | undefined, FinancialOverviewsResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<FinancialOverviewsResponse>(getGetFinancialOverviewsQueryQueryKey(), updater, options);
    
    useGetFinancialOverviewsQuery.getQueryState = () => queryClient.getQueryState<FinancialOverviewsResponse>(getGetFinancialOverviewsQueryQueryKey());
    
    useGetFinancialOverviewsQuery.getQueryData = () => queryClient.getQueryData<FinancialOverviewsResponse>(getGetFinancialOverviewsQueryQueryKey());
  
    useGetFinancialOverviewsQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetFinancialOverviewsQueryQueryKey() , queryFn: () => getFinancialOverviewsQuery() });
  
    useGetFinancialOverviewsQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetFinancialOverviewsQueryQueryKey() })
  
    useGetFinancialOverviewsQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetFinancialOverviewsQueryQueryKey() });
  
    useGetFinancialOverviewsQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetFinancialOverviewsQueryQueryKey(), stale: true });
  
            

              export const getMarketsQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<MarketsResponse>(
      {url: `/markets`, method: 'GET', signal
    },
      );
    }
  

export const getGetMarketsQueryQueryKey = () => {
    return [`/markets`] as const;
    }

    
export const getGetMarketsQueryQueryOptions = <TData = Awaited<ReturnType<typeof getMarketsQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMarketsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketsQuery>>> = ({ signal }) => getMarketsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMarketsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMarketsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getMarketsQuery>>>
export type GetMarketsQueryQueryError = ErrorType<unknown>

export const useGetMarketsQuery = <TData = Awaited<ReturnType<typeof getMarketsQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMarketsQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetMarketsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getMarketsQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMarketsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMarketsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketsQuery>>> = ({ signal }) => getMarketsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMarketsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMarketsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getMarketsQuery>>>
export type GetMarketsQuerySuspenseQueryError = ErrorType<unknown>

export const useGetMarketsQuerySuspense = <TData = Awaited<ReturnType<typeof getMarketsQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMarketsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMarketsQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetMarketsQuery.queryKey = () => getGetMarketsQueryQueryKey();

    useGetMarketsQuery.updateCache = ({updater, options}: {updater: Updater<MarketsResponse | undefined, MarketsResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<MarketsResponse>(getGetMarketsQueryQueryKey(), updater, options);
    
    useGetMarketsQuery.getQueryState = () => queryClient.getQueryState<MarketsResponse>(getGetMarketsQueryQueryKey());
    
    useGetMarketsQuery.getQueryData = () => queryClient.getQueryData<MarketsResponse>(getGetMarketsQueryQueryKey());
  
    useGetMarketsQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetMarketsQueryQueryKey() , queryFn: () => getMarketsQuery() });
  
    useGetMarketsQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetMarketsQueryQueryKey() })
  
    useGetMarketsQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetMarketsQueryQueryKey() });
  
    useGetMarketsQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetMarketsQueryQueryKey(), stale: true });
  
            

              export const getMarketsPeriodsQuery = (
    pair: string,
    params: GetMarketsPeriodsParams,
 signal?: AbortSignal
) => {
      
      
      return apiInstance<MarketsGraphResponse>(
      {url: `/markets/${pair}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetMarketsPeriodsQueryQueryKey = (pair: string,
    params: GetMarketsPeriodsParams,) => {
    return [`/markets/${pair}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetMarketsPeriodsQueryQueryOptions = <TData = Awaited<ReturnType<typeof getMarketsPeriodsQuery>>, TError = ErrorType<void>>(pair: string,
    params: GetMarketsPeriodsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketsPeriodsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMarketsPeriodsQueryQueryKey(pair,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketsPeriodsQuery>>> = ({ signal }) => getMarketsPeriodsQuery(pair,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(pair), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMarketsPeriodsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMarketsPeriodsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getMarketsPeriodsQuery>>>
export type GetMarketsPeriodsQueryQueryError = ErrorType<void>

export const useGetMarketsPeriodsQuery = <TData = Awaited<ReturnType<typeof getMarketsPeriodsQuery>>, TError = ErrorType<void>>(
 pair: string,
    params: GetMarketsPeriodsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketsPeriodsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMarketsPeriodsQueryQueryOptions(pair,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetMarketsPeriodsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getMarketsPeriodsQuery>>, TError = ErrorType<void>>(pair: string,
    params: GetMarketsPeriodsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMarketsPeriodsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMarketsPeriodsQueryQueryKey(pair,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketsPeriodsQuery>>> = ({ signal }) => getMarketsPeriodsQuery(pair,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(pair), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMarketsPeriodsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMarketsPeriodsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getMarketsPeriodsQuery>>>
export type GetMarketsPeriodsQuerySuspenseQueryError = ErrorType<void>

export const useGetMarketsPeriodsQuerySuspense = <TData = Awaited<ReturnType<typeof getMarketsPeriodsQuery>>, TError = ErrorType<void>>(
 pair: string,
    params: GetMarketsPeriodsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMarketsPeriodsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMarketsPeriodsQuerySuspenseQueryOptions(pair,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetMarketsPeriodsQuery.queryKey = (pair: string,
    params: GetMarketsPeriodsParams,) => getGetMarketsPeriodsQueryQueryKey(pair,
    params,);

    useGetMarketsPeriodsQuery.updateCache = ({pair,
    params, updater, options}: {pair: string,
    params: GetMarketsPeriodsParams, updater: Updater<MarketsGraphResponse | undefined, MarketsGraphResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<MarketsGraphResponse>(getGetMarketsPeriodsQueryQueryKey(pair,
    params,), updater, options);
    
    useGetMarketsPeriodsQuery.getQueryState = (pair: string,
    params: GetMarketsPeriodsParams,) => queryClient.getQueryState<MarketsGraphResponse>(getGetMarketsPeriodsQueryQueryKey(pair,
    params,));
    
    useGetMarketsPeriodsQuery.getQueryData = (pair: string,
    params: GetMarketsPeriodsParams,) => queryClient.getQueryData<MarketsGraphResponse>(getGetMarketsPeriodsQueryQueryKey(pair,
    params,));
  
    useGetMarketsPeriodsQuery.prefetch = (pair: string,
    params: GetMarketsPeriodsParams,) => queryClient.prefetchQuery({ queryKey: getGetMarketsPeriodsQueryQueryKey(pair,
    params,) , queryFn: () => getMarketsPeriodsQuery(pair,
    params,) });
  
    useGetMarketsPeriodsQuery.cancelQueries = (pair: string,
    params: GetMarketsPeriodsParams,) => queryClient.cancelQueries({ queryKey: getGetMarketsPeriodsQueryQueryKey(pair,
    params,) })
  
    useGetMarketsPeriodsQuery.invalidate = (pair: string,
    params: GetMarketsPeriodsParams,) => queryClient.invalidateQueries({ queryKey: getGetMarketsPeriodsQueryQueryKey(pair,
    params,) });
  
    useGetMarketsPeriodsQuery.refetchStale = (pair: string,
    params: GetMarketsPeriodsParams,) => queryClient.refetchQueries({ queryKey: getGetMarketsPeriodsQueryQueryKey(pair,
    params,), stale: true });
  
            

              export const getUnitPricesQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<UnitPricesResponse>(
      {url: `/prices`, method: 'GET', signal
    },
      );
    }
  

export const getGetUnitPricesQueryQueryKey = () => {
    return [`/prices`] as const;
    }

    
export const getGetUnitPricesQueryQueryOptions = <TData = Awaited<ReturnType<typeof getUnitPricesQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUnitPricesQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUnitPricesQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUnitPricesQuery>>> = ({ signal }) => getUnitPricesQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUnitPricesQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUnitPricesQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getUnitPricesQuery>>>
export type GetUnitPricesQueryQueryError = ErrorType<unknown>

export const useGetUnitPricesQuery = <TData = Awaited<ReturnType<typeof getUnitPricesQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUnitPricesQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUnitPricesQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUnitPricesQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUnitPricesQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUnitPricesQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUnitPricesQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUnitPricesQuery>>> = ({ signal }) => getUnitPricesQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUnitPricesQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUnitPricesQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUnitPricesQuery>>>
export type GetUnitPricesQuerySuspenseQueryError = ErrorType<unknown>

export const useGetUnitPricesQuerySuspense = <TData = Awaited<ReturnType<typeof getUnitPricesQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUnitPricesQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUnitPricesQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetUnitPricesQuery.queryKey = () => getGetUnitPricesQueryQueryKey();

    useGetUnitPricesQuery.updateCache = ({updater, options}: {updater: Updater<UnitPricesResponse | undefined, UnitPricesResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<UnitPricesResponse>(getGetUnitPricesQueryQueryKey(), updater, options);
    
    useGetUnitPricesQuery.getQueryState = () => queryClient.getQueryState<UnitPricesResponse>(getGetUnitPricesQueryQueryKey());
    
    useGetUnitPricesQuery.getQueryData = () => queryClient.getQueryData<UnitPricesResponse>(getGetUnitPricesQueryQueryKey());
  
    useGetUnitPricesQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetUnitPricesQueryQueryKey() , queryFn: () => getUnitPricesQuery() });
  
    useGetUnitPricesQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetUnitPricesQueryQueryKey() })
  
    useGetUnitPricesQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetUnitPricesQueryQueryKey() });
  
    useGetUnitPricesQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetUnitPricesQueryQueryKey(), stale: true });
  
            

              export const getPaymentsQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<PaymentResult[]>(
      {url: `/payments`, method: 'GET', signal
    },
      );
    }
  

export const getGetPaymentsQueryQueryKey = () => {
    return [`/payments`] as const;
    }

    
export const getGetPaymentsQueryQueryOptions = <TData = Awaited<ReturnType<typeof getPaymentsQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPaymentsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentsQuery>>> = ({ signal }) => getPaymentsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPaymentsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPaymentsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentsQuery>>>
export type GetPaymentsQueryQueryError = ErrorType<unknown>

export const useGetPaymentsQuery = <TData = Awaited<ReturnType<typeof getPaymentsQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPaymentsQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetPaymentsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getPaymentsQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPaymentsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPaymentsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentsQuery>>> = ({ signal }) => getPaymentsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPaymentsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPaymentsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentsQuery>>>
export type GetPaymentsQuerySuspenseQueryError = ErrorType<unknown>

export const useGetPaymentsQuerySuspense = <TData = Awaited<ReturnType<typeof getPaymentsQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPaymentsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPaymentsQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetPaymentsQuery.queryKey = () => getGetPaymentsQueryQueryKey();

    useGetPaymentsQuery.updateCache = ({updater, options}: {updater: Updater<PaymentResult[] | undefined, PaymentResult[] | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<PaymentResult[]>(getGetPaymentsQueryQueryKey(), updater, options);
    
    useGetPaymentsQuery.getQueryState = () => queryClient.getQueryState<PaymentResult[]>(getGetPaymentsQueryQueryKey());
    
    useGetPaymentsQuery.getQueryData = () => queryClient.getQueryData<PaymentResult[]>(getGetPaymentsQueryQueryKey());
  
    useGetPaymentsQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetPaymentsQueryQueryKey() , queryFn: () => getPaymentsQuery() });
  
    useGetPaymentsQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetPaymentsQueryQueryKey() })
  
    useGetPaymentsQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetPaymentsQueryQueryKey() });
  
    useGetPaymentsQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetPaymentsQueryQueryKey(), stale: true });
  
            
/**
 * Retrieve all available payment methods
 */
export const getDropInPaymentMethodsMutation = (
    dropInPaymentMethodsRequest: DropInPaymentMethodsRequest,
 ) => {
      
      
      return apiInstance<DropInPaymentMethodsResponse>(
      {url: `/payments/dropIn/methods`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dropInPaymentMethodsRequest
    },
      );
    }
  


export const getGetDropInPaymentMethodsMutationMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDropInPaymentMethodsMutation>>, TError,{data: DropInPaymentMethodsRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof getDropInPaymentMethodsMutation>>, TError,{data: DropInPaymentMethodsRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getDropInPaymentMethodsMutation>>, {data: DropInPaymentMethodsRequest}> = (props) => {
          const {data} = props ?? {};

          return  getDropInPaymentMethodsMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetDropInPaymentMethodsMutationMutationResult = NonNullable<Awaited<ReturnType<typeof getDropInPaymentMethodsMutation>>>
    export type GetDropInPaymentMethodsMutationMutationBody = DropInPaymentMethodsRequest
    export type GetDropInPaymentMethodsMutationMutationError = ErrorType<void>

    export const useGetDropInPaymentMethodsMutation = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDropInPaymentMethodsMutation>>, TError,{data: DropInPaymentMethodsRequest}, TContext>, }
) => {

      const mutationOptions = getGetDropInPaymentMethodsMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Performs a dropIn payment session with the PSP.
 */
export const dropInPayMutation = (
    dropInPaymentRequest: DropInPaymentRequest,
 ) => {
      
      
      return apiInstance<DropInPaymentResponseDto>(
      {url: `/payments/dropIn/pay`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dropInPaymentRequest
    },
      );
    }
  


export const getDropInPayMutationMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dropInPayMutation>>, TError,{data: DropInPaymentRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof dropInPayMutation>>, TError,{data: DropInPaymentRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dropInPayMutation>>, {data: DropInPaymentRequest}> = (props) => {
          const {data} = props ?? {};

          return  dropInPayMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DropInPayMutationMutationResult = NonNullable<Awaited<ReturnType<typeof dropInPayMutation>>>
    export type DropInPayMutationMutationBody = DropInPaymentRequest
    export type DropInPayMutationMutationError = ErrorType<void>

    export const useDropInPayMutation = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dropInPayMutation>>, TError,{data: DropInPaymentRequest}, TContext>, }
) => {

      const mutationOptions = getDropInPayMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Returns the ledger for user
 */

              export const getLedgerQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<LedgerResponse>(
      {url: `/ledger`, method: 'GET', signal
    },
      );
    }
  

export const getGetLedgerQueryQueryKey = () => {
    return [`/ledger`] as const;
    }

    
export const getGetLedgerQueryQueryOptions = <TData = Awaited<ReturnType<typeof getLedgerQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLedgerQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLedgerQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLedgerQuery>>> = ({ signal }) => getLedgerQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLedgerQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLedgerQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getLedgerQuery>>>
export type GetLedgerQueryQueryError = ErrorType<void>

export const useGetLedgerQuery = <TData = Awaited<ReturnType<typeof getLedgerQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLedgerQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLedgerQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetLedgerQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getLedgerQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getLedgerQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLedgerQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLedgerQuery>>> = ({ signal }) => getLedgerQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getLedgerQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLedgerQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getLedgerQuery>>>
export type GetLedgerQuerySuspenseQueryError = ErrorType<void>

export const useGetLedgerQuerySuspense = <TData = Awaited<ReturnType<typeof getLedgerQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getLedgerQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLedgerQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetLedgerQuery.queryKey = () => getGetLedgerQueryQueryKey();

    useGetLedgerQuery.updateCache = ({updater, options}: {updater: Updater<LedgerResponse | undefined, LedgerResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<LedgerResponse>(getGetLedgerQueryQueryKey(), updater, options);
    
    useGetLedgerQuery.getQueryState = () => queryClient.getQueryState<LedgerResponse>(getGetLedgerQueryQueryKey());
    
    useGetLedgerQuery.getQueryData = () => queryClient.getQueryData<LedgerResponse>(getGetLedgerQueryQueryKey());
  
    useGetLedgerQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetLedgerQueryQueryKey() , queryFn: () => getLedgerQuery() });
  
    useGetLedgerQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetLedgerQueryQueryKey() })
  
    useGetLedgerQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetLedgerQueryQueryKey() });
  
    useGetLedgerQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetLedgerQueryQueryKey(), stale: true });
  
            

              export const getVersionQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<VersionResponse>(
      {url: `/version`, method: 'GET', signal
    },
      );
    }
  

export const getGetVersionQueryQueryKey = () => {
    return [`/version`] as const;
    }

    
export const getGetVersionQueryQueryOptions = <TData = Awaited<ReturnType<typeof getVersionQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVersionQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionQuery>>> = ({ signal }) => getVersionQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVersionQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVersionQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionQuery>>>
export type GetVersionQueryQueryError = ErrorType<unknown>

export const useGetVersionQuery = <TData = Awaited<ReturnType<typeof getVersionQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVersionQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetVersionQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getVersionQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVersionQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVersionQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionQuery>>> = ({ signal }) => getVersionQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVersionQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVersionQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionQuery>>>
export type GetVersionQuerySuspenseQueryError = ErrorType<unknown>

export const useGetVersionQuerySuspense = <TData = Awaited<ReturnType<typeof getVersionQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVersionQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVersionQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetVersionQuery.queryKey = () => getGetVersionQueryQueryKey();

    useGetVersionQuery.updateCache = ({updater, options}: {updater: Updater<VersionResponse | undefined, VersionResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<VersionResponse>(getGetVersionQueryQueryKey(), updater, options);
    
    useGetVersionQuery.getQueryState = () => queryClient.getQueryState<VersionResponse>(getGetVersionQueryQueryKey());
    
    useGetVersionQuery.getQueryData = () => queryClient.getQueryData<VersionResponse>(getGetVersionQueryQueryKey());
  
    useGetVersionQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetVersionQueryQueryKey() , queryFn: () => getVersionQuery() });
  
    useGetVersionQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetVersionQueryQueryKey() })
  
    useGetVersionQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetVersionQueryQueryKey() });
  
    useGetVersionQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetVersionQueryQueryKey(), stale: true });
  
            
/**
 * Check support for the supplied version
 * @summary Check version support
 */
export const checkVersionMutation = (
    checkVersionRequest: CheckVersionRequest,
 ) => {
      
      
      return apiInstance<CheckVersionResponse>(
      {url: `/version`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: checkVersionRequest
    },
      );
    }
  


export const getCheckVersionMutationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkVersionMutation>>, TError,{data: CheckVersionRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof checkVersionMutation>>, TError,{data: CheckVersionRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkVersionMutation>>, {data: CheckVersionRequest}> = (props) => {
          const {data} = props ?? {};

          return  checkVersionMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CheckVersionMutationMutationResult = NonNullable<Awaited<ReturnType<typeof checkVersionMutation>>>
    export type CheckVersionMutationMutationBody = CheckVersionRequest
    export type CheckVersionMutationMutationError = ErrorType<unknown>

    /**
 * @summary Check version support
 */
export const useCheckVersionMutation = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkVersionMutation>>, TError,{data: CheckVersionRequest}, TContext>, }
) => {

      const mutationOptions = getCheckVersionMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Send json data to blox vault
 */
export const sendDocMutation = (
    misnapData: MisnapData,
 ) => {
      
      
      return apiInstance<VerificationPostResponse>(
      {url: `/verifications`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: misnapData
    },
      );
    }
  


export const getSendDocMutationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendDocMutation>>, TError,{data: MisnapData}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sendDocMutation>>, TError,{data: MisnapData}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendDocMutation>>, {data: MisnapData}> = (props) => {
          const {data} = props ?? {};

          return  sendDocMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SendDocMutationMutationResult = NonNullable<Awaited<ReturnType<typeof sendDocMutation>>>
    export type SendDocMutationMutationBody = MisnapData
    export type SendDocMutationMutationError = ErrorType<unknown>

    export const useSendDocMutation = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendDocMutation>>, TError,{data: MisnapData}, TContext>, }
) => {

      const mutationOptions = getSendDocMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Retrieve all docs for a user
 */

              export const getDocsQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<VerificationGetResponse>(
      {url: `/verifications`, method: 'GET', signal
    },
      );
    }
  

export const getGetDocsQueryQueryKey = () => {
    return [`/verifications`] as const;
    }

    
export const getGetDocsQueryQueryOptions = <TData = Awaited<ReturnType<typeof getDocsQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDocsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocsQuery>>> = ({ signal }) => getDocsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDocsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDocsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getDocsQuery>>>
export type GetDocsQueryQueryError = ErrorType<unknown>

export const useGetDocsQuery = <TData = Awaited<ReturnType<typeof getDocsQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDocsQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDocsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getDocsQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDocsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDocsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocsQuery>>> = ({ signal }) => getDocsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDocsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDocsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getDocsQuery>>>
export type GetDocsQuerySuspenseQueryError = ErrorType<unknown>

export const useGetDocsQuerySuspense = <TData = Awaited<ReturnType<typeof getDocsQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDocsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDocsQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetDocsQuery.queryKey = () => getGetDocsQueryQueryKey();

    useGetDocsQuery.updateCache = ({updater, options}: {updater: Updater<VerificationGetResponse | undefined, VerificationGetResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<VerificationGetResponse>(getGetDocsQueryQueryKey(), updater, options);
    
    useGetDocsQuery.getQueryState = () => queryClient.getQueryState<VerificationGetResponse>(getGetDocsQueryQueryKey());
    
    useGetDocsQuery.getQueryData = () => queryClient.getQueryData<VerificationGetResponse>(getGetDocsQueryQueryKey());
  
    useGetDocsQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetDocsQueryQueryKey() , queryFn: () => getDocsQuery() });
  
    useGetDocsQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetDocsQueryQueryKey() })
  
    useGetDocsQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetDocsQueryQueryKey() });
  
    useGetDocsQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetDocsQueryQueryKey(), stale: true });
  
            
/**
 * restart verification process and clear previously sent docs
 */
export const restartVerificationMutation = (
    
 ) => {
      
      
      return apiInstance<void>(
      {url: `/verifications/restart`, method: 'POST'
    },
      );
    }
  


export const getRestartVerificationMutationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof restartVerificationMutation>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof restartVerificationMutation>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof restartVerificationMutation>>, void> = () => {
          

          return  restartVerificationMutation()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RestartVerificationMutationMutationResult = NonNullable<Awaited<ReturnType<typeof restartVerificationMutation>>>
    
    export type RestartVerificationMutationMutationError = ErrorType<unknown>

    export const useRestartVerificationMutation = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof restartVerificationMutation>>, TError,void, TContext>, }
) => {

      const mutationOptions = getRestartVerificationMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * create and get verification token to initialize SumSub SDK
 */

              export const getVerificationTokenQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<VerificationTokenResponse>(
      {url: `/verificationToken`, method: 'GET', signal
    },
      );
    }
  

export const getGetVerificationTokenQueryQueryKey = () => {
    return [`/verificationToken`] as const;
    }

    
export const getGetVerificationTokenQueryQueryOptions = <TData = Awaited<ReturnType<typeof getVerificationTokenQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVerificationTokenQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVerificationTokenQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVerificationTokenQuery>>> = ({ signal }) => getVerificationTokenQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVerificationTokenQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVerificationTokenQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getVerificationTokenQuery>>>
export type GetVerificationTokenQueryQueryError = ErrorType<unknown>

export const useGetVerificationTokenQuery = <TData = Awaited<ReturnType<typeof getVerificationTokenQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVerificationTokenQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVerificationTokenQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetVerificationTokenQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getVerificationTokenQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVerificationTokenQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVerificationTokenQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVerificationTokenQuery>>> = ({ signal }) => getVerificationTokenQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVerificationTokenQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVerificationTokenQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getVerificationTokenQuery>>>
export type GetVerificationTokenQuerySuspenseQueryError = ErrorType<unknown>

export const useGetVerificationTokenQuerySuspense = <TData = Awaited<ReturnType<typeof getVerificationTokenQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVerificationTokenQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVerificationTokenQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetVerificationTokenQuery.queryKey = () => getGetVerificationTokenQueryQueryKey();

    useGetVerificationTokenQuery.updateCache = ({updater, options}: {updater: Updater<VerificationTokenResponse | undefined, VerificationTokenResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<VerificationTokenResponse>(getGetVerificationTokenQueryQueryKey(), updater, options);
    
    useGetVerificationTokenQuery.getQueryState = () => queryClient.getQueryState<VerificationTokenResponse>(getGetVerificationTokenQueryQueryKey());
    
    useGetVerificationTokenQuery.getQueryData = () => queryClient.getQueryData<VerificationTokenResponse>(getGetVerificationTokenQueryQueryKey());
  
    useGetVerificationTokenQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetVerificationTokenQueryQueryKey() , queryFn: () => getVerificationTokenQuery() });
  
    useGetVerificationTokenQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetVerificationTokenQueryQueryKey() })
  
    useGetVerificationTokenQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetVerificationTokenQueryQueryKey() });
  
    useGetVerificationTokenQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetVerificationTokenQueryQueryKey(), stale: true });
  
            

              export const getIdentityProvidersQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<IdentityProvider[]>(
      {url: `/identityProviders`, method: 'GET', signal
    },
      );
    }
  

export const getGetIdentityProvidersQueryQueryKey = () => {
    return [`/identityProviders`] as const;
    }

    
export const getGetIdentityProvidersQueryQueryOptions = <TData = Awaited<ReturnType<typeof getIdentityProvidersQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getIdentityProvidersQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIdentityProvidersQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIdentityProvidersQuery>>> = ({ signal }) => getIdentityProvidersQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getIdentityProvidersQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetIdentityProvidersQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getIdentityProvidersQuery>>>
export type GetIdentityProvidersQueryQueryError = ErrorType<void>

export const useGetIdentityProvidersQuery = <TData = Awaited<ReturnType<typeof getIdentityProvidersQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getIdentityProvidersQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIdentityProvidersQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetIdentityProvidersQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getIdentityProvidersQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getIdentityProvidersQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIdentityProvidersQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIdentityProvidersQuery>>> = ({ signal }) => getIdentityProvidersQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getIdentityProvidersQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetIdentityProvidersQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getIdentityProvidersQuery>>>
export type GetIdentityProvidersQuerySuspenseQueryError = ErrorType<void>

export const useGetIdentityProvidersQuerySuspense = <TData = Awaited<ReturnType<typeof getIdentityProvidersQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getIdentityProvidersQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIdentityProvidersQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetIdentityProvidersQuery.queryKey = () => getGetIdentityProvidersQueryQueryKey();

    useGetIdentityProvidersQuery.updateCache = ({updater, options}: {updater: Updater<IdentityProvider[] | undefined, IdentityProvider[] | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<IdentityProvider[]>(getGetIdentityProvidersQueryQueryKey(), updater, options);
    
    useGetIdentityProvidersQuery.getQueryState = () => queryClient.getQueryState<IdentityProvider[]>(getGetIdentityProvidersQueryQueryKey());
    
    useGetIdentityProvidersQuery.getQueryData = () => queryClient.getQueryData<IdentityProvider[]>(getGetIdentityProvidersQueryQueryKey());
  
    useGetIdentityProvidersQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetIdentityProvidersQueryQueryKey() , queryFn: () => getIdentityProvidersQuery() });
  
    useGetIdentityProvidersQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetIdentityProvidersQueryQueryKey() })
  
    useGetIdentityProvidersQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetIdentityProvidersQueryQueryKey() });
  
    useGetIdentityProvidersQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetIdentityProvidersQueryQueryKey(), stale: true });
  
            

              export const isFeatureEnabledQuery = (
    featureName: string,
 signal?: AbortSignal
) => {
      
      
      return apiInstance<FeatureResponse>(
      {url: `/features/${featureName}`, method: 'GET', signal
    },
      );
    }
  

export const getIsFeatureEnabledQueryQueryKey = (featureName: string,) => {
    return [`/features/${featureName}`] as const;
    }

    
export const getIsFeatureEnabledQueryQueryOptions = <TData = Awaited<ReturnType<typeof isFeatureEnabledQuery>>, TError = ErrorType<void>>(featureName: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof isFeatureEnabledQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getIsFeatureEnabledQueryQueryKey(featureName);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof isFeatureEnabledQuery>>> = ({ signal }) => isFeatureEnabledQuery(featureName, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(featureName), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof isFeatureEnabledQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type IsFeatureEnabledQueryQueryResult = NonNullable<Awaited<ReturnType<typeof isFeatureEnabledQuery>>>
export type IsFeatureEnabledQueryQueryError = ErrorType<void>

export const useIsFeatureEnabledQuery = <TData = Awaited<ReturnType<typeof isFeatureEnabledQuery>>, TError = ErrorType<void>>(
 featureName: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof isFeatureEnabledQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getIsFeatureEnabledQueryQueryOptions(featureName,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getIsFeatureEnabledQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof isFeatureEnabledQuery>>, TError = ErrorType<void>>(featureName: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof isFeatureEnabledQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getIsFeatureEnabledQueryQueryKey(featureName);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof isFeatureEnabledQuery>>> = ({ signal }) => isFeatureEnabledQuery(featureName, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(featureName), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof isFeatureEnabledQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type IsFeatureEnabledQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof isFeatureEnabledQuery>>>
export type IsFeatureEnabledQuerySuspenseQueryError = ErrorType<void>

export const useIsFeatureEnabledQuerySuspense = <TData = Awaited<ReturnType<typeof isFeatureEnabledQuery>>, TError = ErrorType<void>>(
 featureName: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof isFeatureEnabledQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getIsFeatureEnabledQuerySuspenseQueryOptions(featureName,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useIsFeatureEnabledQuery.queryKey = (featureName: string,) => getIsFeatureEnabledQueryQueryKey(featureName,);

    useIsFeatureEnabledQuery.updateCache = ({featureName, updater, options}: {featureName: string, updater: Updater<FeatureResponse | undefined, FeatureResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<FeatureResponse>(getIsFeatureEnabledQueryQueryKey(featureName,), updater, options);
    
    useIsFeatureEnabledQuery.getQueryState = (featureName: string,) => queryClient.getQueryState<FeatureResponse>(getIsFeatureEnabledQueryQueryKey(featureName,));
    
    useIsFeatureEnabledQuery.getQueryData = (featureName: string,) => queryClient.getQueryData<FeatureResponse>(getIsFeatureEnabledQueryQueryKey(featureName,));
  
    useIsFeatureEnabledQuery.prefetch = (featureName: string,) => queryClient.prefetchQuery({ queryKey: getIsFeatureEnabledQueryQueryKey(featureName,) , queryFn: () => isFeatureEnabledQuery(featureName,) });
  
    useIsFeatureEnabledQuery.cancelQueries = (featureName: string,) => queryClient.cancelQueries({ queryKey: getIsFeatureEnabledQueryQueryKey(featureName,) })
  
    useIsFeatureEnabledQuery.invalidate = (featureName: string,) => queryClient.invalidateQueries({ queryKey: getIsFeatureEnabledQueryQueryKey(featureName,) });
  
    useIsFeatureEnabledQuery.refetchStale = (featureName: string,) => queryClient.refetchQueries({ queryKey: getIsFeatureEnabledQueryQueryKey(featureName,), stale: true });
  
            
/**
 * Disables the account for the user
 */
export const disableAccountMutation = (
    
 ) => {
      
      
      return apiInstance<void>(
      {url: `/account/disable`, method: 'PUT'
    },
      );
    }
  


export const getDisableAccountMutationMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof disableAccountMutation>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof disableAccountMutation>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof disableAccountMutation>>, void> = () => {
          

          return  disableAccountMutation()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DisableAccountMutationMutationResult = NonNullable<Awaited<ReturnType<typeof disableAccountMutation>>>
    
    export type DisableAccountMutationMutationError = ErrorType<void>

    export const useDisableAccountMutation = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof disableAccountMutation>>, TError,void, TContext>, }
) => {

      const mutationOptions = getDisableAccountMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Gets the user preferences
 */

              export const getUserPreferencesQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<GetUserPreferences200>(
      {url: `/user-preferences`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserPreferencesQueryQueryKey = () => {
    return [`/user-preferences`] as const;
    }

    
export const getGetUserPreferencesQueryQueryOptions = <TData = Awaited<ReturnType<typeof getUserPreferencesQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserPreferencesQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserPreferencesQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserPreferencesQuery>>> = ({ signal }) => getUserPreferencesQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserPreferencesQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserPreferencesQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getUserPreferencesQuery>>>
export type GetUserPreferencesQueryQueryError = ErrorType<unknown>

export const useGetUserPreferencesQuery = <TData = Awaited<ReturnType<typeof getUserPreferencesQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserPreferencesQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserPreferencesQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserPreferencesQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUserPreferencesQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserPreferencesQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserPreferencesQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserPreferencesQuery>>> = ({ signal }) => getUserPreferencesQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserPreferencesQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserPreferencesQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUserPreferencesQuery>>>
export type GetUserPreferencesQuerySuspenseQueryError = ErrorType<unknown>

export const useGetUserPreferencesQuerySuspense = <TData = Awaited<ReturnType<typeof getUserPreferencesQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserPreferencesQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserPreferencesQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetUserPreferencesQuery.queryKey = () => getGetUserPreferencesQueryQueryKey();

    useGetUserPreferencesQuery.updateCache = ({updater, options}: {updater: Updater<GetUserPreferences200 | undefined, GetUserPreferences200 | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<GetUserPreferences200>(getGetUserPreferencesQueryQueryKey(), updater, options);
    
    useGetUserPreferencesQuery.getQueryState = () => queryClient.getQueryState<GetUserPreferences200>(getGetUserPreferencesQueryQueryKey());
    
    useGetUserPreferencesQuery.getQueryData = () => queryClient.getQueryData<GetUserPreferences200>(getGetUserPreferencesQueryQueryKey());
  
    useGetUserPreferencesQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetUserPreferencesQueryQueryKey() , queryFn: () => getUserPreferencesQuery() });
  
    useGetUserPreferencesQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetUserPreferencesQueryQueryKey() })
  
    useGetUserPreferencesQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetUserPreferencesQueryQueryKey() });
  
    useGetUserPreferencesQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetUserPreferencesQueryQueryKey(), stale: true });
  
            
/**
 * Stores the user preferences
 */
export const updateUserPreferencesMutation = (
    updateUserPreferencesBody: UpdateUserPreferencesBody,
 ) => {
      
      
      return apiInstance<UpdateUserPreferences200>(
      {url: `/user-preferences`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateUserPreferencesBody
    },
      );
    }
  


export const getUpdateUserPreferencesMutationMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserPreferencesMutation>>, TError,{data: UpdateUserPreferencesBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateUserPreferencesMutation>>, TError,{data: UpdateUserPreferencesBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserPreferencesMutation>>, {data: UpdateUserPreferencesBody}> = (props) => {
          const {data} = props ?? {};

          return  updateUserPreferencesMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateUserPreferencesMutationMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserPreferencesMutation>>>
    export type UpdateUserPreferencesMutationMutationBody = UpdateUserPreferencesBody
    export type UpdateUserPreferencesMutationMutationError = ErrorType<void>

    export const useUpdateUserPreferencesMutation = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserPreferencesMutation>>, TError,{data: UpdateUserPreferencesBody}, TContext>, }
) => {

      const mutationOptions = getUpdateUserPreferencesMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
