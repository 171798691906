import { styled, Text, View } from "@tamagui/core";

export const PaperBase = styled(View, {
  name: "Paper",
  display: "block",

  borderWidth: 0,
  borderRadius: 0,
  backgroundColor: "transparent",

  marginHorizontal: -20,

  $excludePhone: {
    borderWidth: 1,
    borderColor: "$border",
    borderRadius: "$regular",
    backgroundColor: "$white",

    marginHorizontal: 0,
  },
});

export const PaperTitle = styled(Text, {
  name: "PaperTitle",
  tag: "div",

  fontFamily: "$heading",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",

  paddingHorizontal: "$20",
  borderBottomWidth: 1,
  borderBottomColor: "$border",

  $excludePhone: {
    paddingHorizontal: "$32",
  },
});
