import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { CountryCode } from "../__generated__/api-keycloak/model";
import { iPApi } from "../api";

export interface IPInfoResponse {
  country_calling_code: string;
  high_risk: boolean;
  country_iso: CountryCode;
  risk: {
    is_spam: boolean;
    is_proxy: boolean;
    is_malware: boolean;
    is_tor: boolean;
  };
}

interface GetVideoItemsProps {
  query?: Partial<UseQueryOptions<IPInfoResponse, AxiosError, IPInfoResponse, any>>;
}

export function useGetIPInfo(props?: GetVideoItemsProps) {
  return useQuery({
    queryKey: ["ipInfo"],
    queryFn: async () => {
      const result = await iPApi.get<IPInfoResponse>("");
      return result.data;
    },
    ...props?.query,
    gcTime: Infinity,
    refetchOnReconnect: "always",
  });
}
