import ContentLoader from "react-content-loader";
import { matchPath, useLocation } from "react-router-dom";

import { useIsDarkMode } from "../../../hooks/useIsDarkMode";
import { useGetColor } from "../../../utils/getColor";
import { Col, ColSpan, Row, RowCols } from "../../UI/Columns/Columns";
import PaperBox from "../../UI/Paper/PaperBox";
import { PaperSkeleton } from "./PaperSkeleton";

function useCols(): (ColSpan | null)[] {
  const { pathname } = useLocation();

  if (matchPath(pathname, "/affiliate")) {
    return [2, 1];
  } else if (matchPath(pathname, "/vault/balance")) {
    return [2, 1];
  } else if (matchPath(pathname, "/vault/:shortName")) {
    return [2, 1];
  } else if (matchPath(pathname, "/vault")) {
    return [1, 2];
  } else if (matchPath(pathname, "/settings")) {
    return [2, null];
  } else if (matchPath(pathname, "/markets/:shortName")) {
    return [2, 1];
  } else if (matchPath(pathname, "/markets")) {
    return [1];
  } else if (matchPath(pathname, "/news")) {
    return [1, 1];
  } else if (matchPath(pathname, "/dashboard")) {
    return [1, 1];
  } else {
    return [1, 1];
  }
}

export function PageSkeleton() {
  const cols = useCols();
  const isDarkMode = useIsDarkMode();
  const getColor = useGetColor();

  const totalCols = cols.reduce<number>((acc, value) => acc + (value === null ? 1 : value), 0) as RowCols;

  return (
    <>
      <ContentLoader
        backgroundColor={getColor(isDarkMode ? "lighter" : "light")}
        foregroundColor={getColor(isDarkMode ? "light" : "button")}
        width="400"
        viewBox="0 0 400 130"
      >
        <rect x="0" y="45" rx="4" ry="4" width="250" height="40" />
      </ContentLoader>
      <Row cols={totalCols}>
        {cols.map((span, index) => (
          <Col key={index} span={span ?? 1}>
            {span !== null && (
              <PaperBox contentPadding>
                <PaperSkeleton />
              </PaperBox>
            )}
          </Col>
        ))}
      </Row>
    </>
  );
}
