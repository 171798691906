import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import * as React from "react";

import configParams from "./env";

const IS_DEV = window.location.hostname.includes("localhost");

Bugsnag.start({
  apiKey: configParams.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  appVersion: import.meta.env.PACKAGE_VERSION,
  releaseStage: IS_DEV ? "DEVELOPMENT" : configParams.ENVIRONMENT,
  enabledReleaseStages: ["STAGING", "PRODUCTION"],
});
BugsnagPerformance.start({ apiKey: configParams.BUGSNAG_API_KEY });

export const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);
