import { useIsFeatureEnabledQuerySuspense } from "@blox/api";
import { Box, Flex, Heading, Paragraph, Stack } from "@blox/design-system";
import { doc } from "firebase/firestore";
import { ReactNode } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { AppContent, AppWrapper } from "../../App.style";
import MaintenanceImage from "../../assets/img/maintenance.png";
import LanSelector from "../../components/LanSelector/LanSelector";
import Logo from "../../components/Logo/Logo";
import PaperBox from "../../components/UI/Paper/PaperBox";
import { useLocalisation } from "../../context/LocalizeProvider";
import configParams from "../../env";
import { firestore } from "../../firebase";
import { useFirestore } from "../../hooks/useFirestore";
import { useLocale } from "../../hooks/useStore";

function Maintenance({ children }: { children: ReactNode }) {
  const t = useLocalisation();
  const locale = useLocale();

  const { data: showMaintenance } = useIsFeatureEnabledQuerySuspense("BLOX", {
    query: {
      select: (data) => data && !data.enabled,
      refetchInterval: (query) => (query.state.data && !query.state.data.enabled ? 10000 : false),
    },
  });

  const maintenanceMessage = useFirestore(
    (s) =>
      (s.config.configSet.maintenanceMessage?.[locale] || s.config.configSet.maintenanceMessage?.en) ?? ""
  );

  const showOutageContentInApp = useFirestore((s) => s.config.configSet.showOutageContentInApp);

  const [firestoreConfigData] = useDocumentData(
    showOutageContentInApp
      ? doc(
          firestore,
          `status/${configParams.ENVIRONMENT === "STAGING" ? "staging" : "prod"}/outage/${locale}`
        )
      : undefined
  );

  if (showMaintenance) {
    return (
      <AppWrapper>
        <AppContent height="100vh">
          <Flex alignItems="center" flexDirection="column" flex={1}>
            <Flex justifyContent="space-between" width="100%">
              <a href="https://weareblox.com" className="login__logo">
                <Logo />
              </a>
              <Flex alignItems="center">
                <LanSelector noLabel />
              </Flex>
            </Flex>
            <Box maxWidth={600} textAlign="center" flex={1} paddingBottom={20}>
              <PaperBox
                contentPadding
                skeletonProps={{
                  variant: "paper",
                }}
              >
                <Stack space={24} alignItems="center">
                  <img src={MaintenanceImage} alt="BLOX Maintenance" />
                  <Heading level={1}>{t.text("Maintenance", "title")}</Heading>
                  {firestoreConfigData ? (
                    <Stack space={15}>
                      <Heading level={4}>{t.text("Maintenance", "latestUpdates")}</Heading>
                      <Box dangerouslySetInnerHTML={{ __html: firestoreConfigData.text }} />
                    </Stack>
                  ) : (
                    <Paragraph>{maintenanceMessage}</Paragraph>
                  )}
                </Stack>
              </PaperBox>
            </Box>
          </Flex>
        </AppContent>
      </AppWrapper>
    );
  }

  return <>{children}</>;
}

export default Maintenance;
