import type { SVGProps } from "react";
const SvgCloseSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg width={14} height={14} {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M13.7 12.3c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3s-.5-.1-.7-.3L7 8.4l-5.3 5.3c-.2.2-.4.3-.7.3s-.5-.1-.7-.3c-.4-.4-.4-1 0-1.4L5.6 7 .3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0L7 5.6 12.3.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4L8.4 7z"
    />
  </svg>
);
export default SvgCloseSmall;
