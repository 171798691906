import { Box, Flex, styled } from "@blox/design-system";

export const AppWrapper = styled(Flex, {
  name: "AppWrapper",
  flexDirection: "column",
});

export const AppContent = styled(Flex, {
  name: "AppContent",
  width: "100%",
  marginVertical: 0,
  marginHorizontal: "auto",
  paddingHorizontal: "$pagePaddingMobile",
  paddingBottom: 50,
  backgroundColor: "transparent",

  "$theme-dark": {
    backgroundColor: "$white",
  },

  $excludePhone: {
    paddingHorizontal: "$pagePadding",
    paddingBottom: 0,
    backgroundColor: "$background",
  },
});

export const AppMain = styled(Box, {
  name: "AppMain",
  flexGrow: 1,
  flexShrink: 1,
  minHeight: "100vh",

  $excludeTablet: {
    paddingLeft: "$pagePadding",
  },
});

export const AppPage = styled(Box, {
  name: "AppPage",

  maxWidth: "$space.containerWidth",
  marginHorizontal: "auto",
});
