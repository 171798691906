import { useGetUserQuery } from "@blox/api";
import { QueryClientProvider } from "@tanstack/react-query";
import * as React from "react";
import { ReactNode, useEffect } from "react";

import { queryClient } from "~queryClient";

import { useIsLoggedIn } from "../hooks/useIsLoggedIn";
import { USER_SESSION_KEY } from "../initApi";

function UserInfoErrorHandler({ children }: { children: ReactNode }) {
  const isLoggedIn = useIsLoggedIn();
  const { isError } = useGetUserQuery({
    query: {
      enabled: isLoggedIn,
    },
  });

  useEffect(() => {
    if (isError) {
      window.localStorage.removeItem(USER_SESSION_KEY);
      window.location.replace("/login");
      queryClient.clear();
    }
  }, [isError]);

  return <>{children}</>;
}

export default function ReactQueryProvider({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <UserInfoErrorHandler>{children}</UserInfoErrorHandler>
    </QueryClientProvider>
  );
}
