import { GetProps, styled } from "@tamagui/core";

import { Box } from "../Box";

export const Flex = styled(Box, {
  name: "Flex",
  display: "flex",
  flexDirection: "row",
});

export type FlexProps = GetProps<typeof Flex>;
