import "./CookieControl.scss";

import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import configParams from "../../env";
import { useCXChat } from "../../hooks/useCXChat";
import useScript from "../../hooks/useScript";
import { useLocale } from "../../hooks/useStore";
import { useTagManager } from "../../hooks/useTagManager";
import configEN from "./config/en";
import configES from "./config/es";
import configNL from "./config/nl";

export function CookieManagement({ children }: { children: ReactNode }) {
  const locale = useLocale();
  const [accepted, setAccepted] = useState(false);
  const location = useLocation();

  const onAccept = useCallback(() => setAccepted(true), []);

  const cookieConfig = useMemo(() => {
    switch (locale) {
      case "nl": {
        return configNL({ onAccept });
      }
      case "es": {
        return configES({ onAccept });
      }
      default: {
        return configEN({ onAccept });
      }
    }
  }, [locale, onAccept]);

  const cookieScriptStatus = useScript("https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js");

  useCXChat(accepted);
  useTagManager(accepted);

  const alwaysApprove = new URLSearchParams(location.search).has("approve_cookies");

  useEffect(() => {
    if (alwaysApprove) {
      onAccept();
    } else {
      if (cookieScriptStatus === "ready" && configParams.ENVIRONMENT === "PRODUCTION") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cc = (window as any).CookieControl;

        cc?.load(cookieConfig);
      }
    }
  }, [cookieConfig, cookieScriptStatus, alwaysApprove, onAccept]);

  return <>{children}</>;
}
