import { useGetUserQuery } from "@blox/api";
import { selectUserCountry } from "@blox/shared/selectors/userSelectors";

import { useIsLoggedIn } from "./useIsLoggedIn";

export default function useUserCountry() {
  const isLoggedIn = useIsLoggedIn();
  const { data: userCountry } = useGetUserQuery({
    query: {
      select: selectUserCountry,
      enabled: isLoggedIn,
    },
  });
  return userCountry;
}
