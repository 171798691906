import { Loader } from "@blox/design-system";
import Bugsnag from "@bugsnag/js";
import { UserManager } from "oidc-client-ts";
import queryString from "query-string";
import { ReactNode, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import { AppContent, AppWrapper } from "./App.style";
import configParams from "./env";
import About from "./screens/About/About";
import Login from "./screens/Login/Login";

function QuickLogin({ username, password }: { username: string; password: string }) {
  const { isAuthenticated, settings, signoutSilent, signinSilent, signoutRedirect } = useAuth();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        await signoutRedirect({
          post_logout_redirect_uri: window.location.toString(),
        });
      } else {
        const userManager = new UserManager(settings);
        await userManager.signinResourceOwnerCredentials({
          username,
          password,
        });

        window.location.assign("/");
      }
    })();
  }, [history, isAuthenticated, password, settings, signinSilent, signoutRedirect, signoutSilent, username]);

  return <Loader variant="fullScreen" />;
}

function Auth({ children }: { children: ReactNode }) {
  const history = useHistory();
  const { isLoading, isAuthenticated, removeUser, events, user } = useAuth();
  const searchParams = new URLSearchParams(history.location.search);

  useEffect(() => {
    if (user?.profile.sub) {
      Bugsnag.setUser(user.profile.sub);
    }
  }, [user?.profile.sub]);

  useEffect(() => {
    return events.addSilentRenewError(removeUser);
  }, [events, removeUser]);

  if (isLoading) {
    return <Loader variant="fullScreen" />;
  }

  if (
    configParams.ENVIRONMENT === "STAGING" &&
    searchParams.has("username") &&
    searchParams.has("password")
  ) {
    return <QuickLogin username={searchParams.get("username")!} password={searchParams.get("password")!} />;
  }

  if (!isAuthenticated) {
    return (
      <Switch>
        <Route path="/login">
          <AppWrapper>
            <Login />
          </AppWrapper>
        </Route>
        <Route path="/about">
          <AppContent>
            <About />
          </AppContent>
        </Route>
        <Route>
          <Redirect
            to={{
              pathname: "/login",
              search: queryString.stringify({ redirectUrl: window.location.toString() }),
            }}
          />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path="/login">
        <Redirect to="/" />
      </Route>
      <Route>{children}</Route>
    </Switch>
  );
}

export default Auth;
