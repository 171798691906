import "./Modal.scss";

import { Box, Button, Heading, Loader, Paragraph, ParagraphProps } from "@blox/design-system";
import { SvgCloseSmall } from "@blox/icons";
import classNames from "classnames";
import React, { ComponentPropsWithoutRef, ReactNode } from "react";

import backIcon from "../../../assets/img/back-icon.svg";
import { ModalVariant, useCurrentModal } from "../../../context/ModalProvider";
import Skeleton from "../../Skeleton/Skeleton";
import { Suspender } from "../../Suspender/Suspender";

const Modal = React.forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div"> & { variant?: ModalVariant }>(
  function Modal({ className, variant, ...rest }, ref) {
    const { isClosing, isOpening, closeModal, modalOptions, modalContent } = useCurrentModal();
    const classes = classNames(className, {
      modal: true,
      "modal--closing": isClosing && !isOpening,
      "modal--wide": modalOptions.wide,
      "modal--fullscreen-mobile": modalOptions.fullscreenOnMobile,
      [`modal--variant-${variant}`]: true,
    });

    return (
      <div className={classes} data-testid="modal" ref={ref} {...rest}>
        <div className="modal__window">
          {modalOptions.headerTitle && <ModalHeader title={modalOptions.headerTitle} />}
          <div className="modal__content-wrapper">
            <div className="modal__content">
              <Suspender
                fallback={
                  <Box padding={40}>
                    <Skeleton variant="paper" />
                  </Box>
                }
              >
                {modalContent}
              </Suspender>
            </div>
            {!modalOptions.noCancelButton && (
              <div className="modal__cancel">
                <Button variant="text" onClick={closeModal}>
                  {modalOptions.cancelButtonText}
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="modal__backdrop" onClick={closeModal}></div>
      </div>
    );
  }
);

export default Modal;

export function ModalHeader({
  title,
  backButtonEvent,
  showCloseButton = true,
}: {
  title: string;
  backButtonEvent?: () => void;
  showCloseButton?: boolean;
}) {
  const { closeModal } = useCurrentModal();
  return (
    <div className="modal__header" data-testid="modal-header">
      {backButtonEvent ? (
        <button onClick={backButtonEvent} data-testid="modal-back">
          <img src={backIcon} alt="back-icon" />
        </button>
      ) : (
        <div style={{ width: "2.5rem" }} />
      )}
      <Heading level={4}>{title}</Heading>
      {showCloseButton && (
        <Button variant="icon" onClick={closeModal} data-testid="modal-close">
          <SvgCloseSmall />
        </Button>
      )}
    </div>
  );
}

export function ModalContent({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <div className={classNames("modal__inner-content", className)} data-testid="modal-content">
      {children}
    </div>
  );
}

export function ModalSummaryAmount({
  Icon,
  mainText,
  subText,
  loading,
}: {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  mainText?: string;
  subText?: string;
  loading?: boolean;
}) {
  return (
    <div className="modal__summery-amount" data-testid="modal-summary-amount">
      <Icon />
      {loading ? (
        <div className="modal__summery-loader">
          <Loader variant="small" />
        </div>
      ) : (
        <Paragraph
          variant="viga"
          $theme-dark={{ color: "$black" }}
          fontSize={32}
          marginTop={10}
          marginBottom={5}
        >
          {mainText}
        </Paragraph>
      )}
      {subText && (
        <Paragraph viga margin={0} color="$dark">
          {subText}
        </Paragraph>
      )}
    </div>
  );
}

export function ModalSummaryItems({
  children,
  ...rest
}: { children: React.ReactNode } & ComponentPropsWithoutRef<"div">) {
  return (
    <div className="modal__summery-items" data-testid="modal-summary-items" {...rest}>
      {children}
    </div>
  );
}

export type HighLightType = "success" | "error" | "warning" | "pending";

export function ModalSummaryItem({
  leftText,
  rightText,
  loading,
  highLight,
  description,
  ...rest
}: {
  leftText?: ReactNode;
  rightText?: ReactNode;
  loading?: boolean;
  highLight?: HighLightType;
  description?: string;
} & ComponentPropsWithoutRef<"div">) {
  let props: ParagraphProps = {
    fontFamily: "$heading",
    color: "$darker",
    textAlign: "right",
  };

  const highlightMap: Record<HighLightType, ParagraphProps> = {
    success: {
      backgroundColor: "#84cf3d15",
      color: "$success",
    },
    warning: {
      backgroundColor: "$warningLighter",
      color: "$warning",
    },
    error: {
      backgroundColor: "$alertLightest",
      color: "$alert",
    },
    pending: {
      backgroundColor: "$warningLighter",
      color: "$warning",
    },
  };

  if (highLight) {
    props = {
      ...props,
      fontFamily: "$body",
      paddingVertical: "$4",
      paddingHorizontal: "$16",
      borderRadius: 32,
      fontSize: 12,
      fontWeight: 600,
      textTransform: "uppercase",

      ...highlightMap[highLight],
    };
  }

  return (
    <div className="modal__summery-item" {...rest}>
      <div className="modal__summery-item-inner">
        <Paragraph color="$darker">{leftText}</Paragraph>
        {loading ? <Loader variant="small" /> : <Paragraph {...props}>{rightText}</Paragraph>}
      </div>
      {description && <Paragraph>{description}</Paragraph>}
    </div>
  );
}
