import "./Stack.css";

import { GetProps, stackDefaultStyles } from "@tamagui/core";
import flattenChildren from "react-keyed-flatten-children";

import { Box } from "../Box";

export const Stack = Box.styleable(({ children, ...props }, ref) => {
  const flattenedChilderen = flattenChildren(children);

  if (flattenedChilderen.length === 0) {
    return null;
  }

  return (
    <Box data-stack {...stackDefaultStyles} {...props} ref={ref}>
      {flattenedChilderen}
    </Box>
  );
});

export type StackProps = GetProps<typeof Stack>;
