import { createFont } from "@tamagui/core";

export type FontMetrics = {
  familyName: string;
  capHeight: number;
  ascent: number;
  descent: number;
  lineGap: number;
  unitsPerEm: number;
  xHeight?: number;
};

// values from font file using https://seek-oss.github.io/capsize/
export const fontMetricsInter: FontMetrics = {
  familyName: "Inter",
  capHeight: 2048,
  ascent: 2728,
  descent: -680,
  lineGap: 0,
  unitsPerEm: 2816,
  xHeight: 1536,
};

// values from font file using https://seek-oss.github.io/capsize/
export const fontMetricsViga: FontMetrics = {
  familyName: "Viga",
  capHeight: 712,
  ascent: 1015,
  descent: -329,
  lineGap: 0,
  unitsPerEm: 1000,
} as const;

const baseFontStyles = {
  xs: { fontSize: 13, lineHeight: 16 },
  sm: { fontSize: 15, lineHeight: 28 },
  md: { fontSize: 17, lineHeight: 30 },
  lg: { fontSize: 18, lineHeight: 24 },
  xl: { fontSize: 20, lineHeight: 27 },
  "2xl": { fontSize: 24, lineHeight: 32 },
  "3xl": { fontSize: 32, lineHeight: 42 },
  "4xl": { fontSize: 36, lineHeight: 46 },
  "5xl": { fontSize: 60, lineHeight: 78 },
} as const;

const size = Object.fromEntries(
  Object.entries(baseFontStyles).map(([size, { fontSize }]) => [size, fontSize])
) as Record<keyof typeof baseFontStyles, number>;

const lineHeight = Object.fromEntries(
  Object.entries(baseFontStyles).map(([size, { lineHeight }]) => [size, lineHeight])
) as Record<keyof typeof baseFontStyles, number>;

export const vigaFont = createFont({
  family: "Viga",
  size,
  lineHeight,
  weight: {
    normal: "400",
  },
});

export const interFont = createFont({
  family: "Inter",
  size,
  lineHeight,
  weight: {
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
  },
  face: {
    300: { normal: "Inter-Light", italic: "Inter-Light" },
    400: { normal: "Inter-Regular", italic: "Inter-Regular" },
    500: { normal: "Inter-Medium", italic: "Inter-Medium" },
    600: { normal: "Inter-SemiBold", italic: "Inter-SemiBold" },
    700: { normal: "Inter-Bold", italic: "Inter-Bold" },
  },
});
