import { createTamagui } from "@tamagui/core";

import { animations } from "./config/animations";
import { interFont, vigaFont } from "./config/fonts";
import { media } from "./config/media";
import { bloxColors, slateDark, slateLight, tokens } from "./config/tokens";

const config = createTamagui({
  fonts: {
    heading: vigaFont,
    body: interFont,
  },
  themes: {
    /**
     * Try not add any new variants here, except if it is really
     * a new UI specification that will be used in multiple locations.
     */
    light: {
      ...bloxColors,

      black: slateLight.black_light,
      darker: slateLight.darker_light,
      dark: slateLight.dark_light,
      default: slateLight.default_light,
      light: slateLight.light_light,
      lighter: slateLight.lighter_light,
      offWhite: slateLight.offWhite_light,
      button: slateLight.button_light,
      white: slateLight.white_light,
      background: slateLight.background_light,
      border: slateLight.border_light,
    },
    dark: {
      ...bloxColors,

      black: slateDark.black_dark,
      darker: slateDark.darker_dark,
      dark: slateDark.dark_dark,
      default: slateDark.default_dark,
      light: slateDark.light_dark,
      lighter: slateDark.lighter_dark,
      offWhite: slateDark.offWhite_dark,
      button: slateDark.button_dark,
      white: slateDark.white_dark,
      background: slateDark.background_dark,
      border: slateDark.border_dark,
    },
  },
  tokens,
  animations,
  media,
  settings: {
    allowedStyleValues: {
      color: "somewhat-strict-web",
      space: "somewhat-strict-web",
      size: "somewhat-strict-web",
      radius: "somewhat-strict-web",
      zIndex: "somewhat-strict-web",
    },
    defaultFont: "body",
  },
  maxDarkLightNesting: 0,
});

export type AppConfig = typeof config;

declare module "@tamagui/core" {
  interface TamaguiCustomConfig extends AppConfig {}
}

export default config;
