import { ReactNode, SuspenseProps } from "react";
import * as React from "react";

import { ErrorBoundary } from "../../initBugsnag";
import { Error, ErrorProps } from "../Error/Error";

type SuspenderProps = {
  children: ReactNode;
  fallback: SuspenseProps["fallback"];
  ErrorComponent?: React.ComponentType<ErrorProps>;
};

export function Suspender({ children, fallback, ErrorComponent = Error }: SuspenderProps) {
  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <React.Suspense fallback={fallback}>{children}</React.Suspense>
    </ErrorBoundary>
  );
}
