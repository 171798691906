import { useEffect, useRef } from "react";

import useScript from "./useScript";
import { useLocale } from "./useStore";

export function useCXChat(enabled: boolean) {
  const locale = useLocale();
  const status = useScript("https://webchat.digitalcx.com/webchat.js", { shouldPreventLoad: !enabled });

  const isInitialized = useRef(false);

  let chatId = "f541af96-9bf5-4a42-b9cf-3fc5f37f7d2f"; // english
  if (locale === "nl") {
    chatId = "18550138-3f0f-4a95-b09a-792d6c2ff486"; // dutch
  } else if (locale === "es") {
    chatId = "9abcb4c8-fbbb-4fe4-8ec7-430b9a8bcd6c"; // spanish
  }

  useEffect(() => {
    if (status === "ready" && !isInitialized.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).cmwc?.add(chatId).install();
      isInitialized.current = true;
    }
  }, [chatId, status]);
}
