import ContentLoader from "react-content-loader";

import { useIsDarkMode } from "../../../hooks/useIsDarkMode";
import { useGetColor } from "../../../utils/getColor";

export function PaperSkeleton() {
  const isDarkMode = useIsDarkMode();
  const getColor = useGetColor();

  return (
    <ContentLoader
      backgroundColor={getColor(isDarkMode ? "light" : "lighter")}
      foregroundColor={getColor(isDarkMode ? "lighter" : "offWhite")}
      width="250"
      viewBox="0 0 250 40"
    >
      <rect x="0" y="0" rx="4" ry="4" width="250" height="15" />
      <rect x="0" y="25" rx="4" ry="4" width="200" height="15" />
    </ContentLoader>
  );
}
