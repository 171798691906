import { GetProps, styled, Text } from "@tamagui/core";

export const HeadingBase = styled(Text, {
  name: "Heading",
  fontFamily: "$heading",
  fontWeight: "$normal",
  color: "$black",
  margin: "unset",
  display: "unset",

  variants: {
    level: {
      1: {
        tag: "h1",
        fontSize: 36,

        $excludePhone: {
          fontSize: 48,
        },
      },
      2: {
        tag: "h2",
        fontSize: 24,

        $excludePhone: {
          fontSize: 40,
        },
      },
      3: {
        tag: "h3",
        fontSize: 21,

        $excludePhone: {
          fontSize: 24,
        },
      },
      4: {
        tag: "h4",
        fontSize: 20,
      },
      5: {
        tag: "h5",
        fontSize: 17,
      },
      6: {
        tag: "h6",
        fontSize: 11,
      },
    },
  } as const,
});

export const Heading = HeadingBase.styleable((props, ref) => (
  <HeadingBase {...props} tag={`h${props.level}`} ref={ref} />
));

export type HeadingProps = GetProps<typeof Heading>;
