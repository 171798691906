/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * BFF
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  Coin,
  FeedbackInput,
  GetCoinsV2200,
  GetCoinsV2Params,
  GetPlaylistVideosParams,
  GetSingleCoinV2Params,
  SendFeedback201,
  ShareCoinListingInput,
  ShareResponse,
  ShareVaultInput,
  ShareVaultResultInput,
  VideoSummary,
  YoutubeChannelResponse,
  YoutubePlaylistResponse
} from './model'
import { bffApiInstance } from '../../lib/api-instance';
import type { ErrorType } from '../../lib/api-instance';



              // @ts-ignore
              import { Updater, SetDataOptions, QueryFilters } from "@tanstack/react-query";
              // @ts-ignore
              import { queryClient } from "~queryClient";

              type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



;
            
              export const getCoinsV2Query = (
    params?: GetCoinsV2Params,
 signal?: AbortSignal
) => {
      
      
      return bffApiInstance<GetCoinsV2200>(
      {url: `/v2/coins`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetCoinsV2QueryQueryKey = (params?: GetCoinsV2Params,) => {
    return [`/v2/coins`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCoinsV2QueryQueryOptions = <TData = Awaited<ReturnType<typeof getCoinsV2Query>>, TError = ErrorType<unknown>>(params?: GetCoinsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoinsV2Query>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCoinsV2QueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCoinsV2Query>>> = ({ signal }) => getCoinsV2Query(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCoinsV2Query>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCoinsV2QueryQueryResult = NonNullable<Awaited<ReturnType<typeof getCoinsV2Query>>>
export type GetCoinsV2QueryQueryError = ErrorType<unknown>

export const useGetCoinsV2Query = <TData = Awaited<ReturnType<typeof getCoinsV2Query>>, TError = ErrorType<unknown>>(
 params?: GetCoinsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoinsV2Query>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCoinsV2QueryQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCoinsV2QuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getCoinsV2Query>>, TError = ErrorType<unknown>>(params?: GetCoinsV2Params, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCoinsV2Query>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCoinsV2QueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCoinsV2Query>>> = ({ signal }) => getCoinsV2Query(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCoinsV2Query>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCoinsV2QuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCoinsV2Query>>>
export type GetCoinsV2QuerySuspenseQueryError = ErrorType<unknown>

export const useGetCoinsV2QuerySuspense = <TData = Awaited<ReturnType<typeof getCoinsV2Query>>, TError = ErrorType<unknown>>(
 params?: GetCoinsV2Params, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCoinsV2Query>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCoinsV2QuerySuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetCoinsV2Query.queryKey = (params?: GetCoinsV2Params,) => getGetCoinsV2QueryQueryKey(params,);

    useGetCoinsV2Query.updateCache = ({params, updater, options}: {params?: GetCoinsV2Params, updater: Updater<GetCoinsV2200 | undefined, GetCoinsV2200 | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<GetCoinsV2200>(getGetCoinsV2QueryQueryKey(params,), updater, options);
    
    useGetCoinsV2Query.getQueryState = (params?: GetCoinsV2Params,) => queryClient.getQueryState<GetCoinsV2200>(getGetCoinsV2QueryQueryKey(params,));
    
    useGetCoinsV2Query.getQueryData = (params?: GetCoinsV2Params,) => queryClient.getQueryData<GetCoinsV2200>(getGetCoinsV2QueryQueryKey(params,));
  
    useGetCoinsV2Query.prefetch = (params?: GetCoinsV2Params,) => queryClient.prefetchQuery({ queryKey: getGetCoinsV2QueryQueryKey(params,) , queryFn: () => getCoinsV2Query(params,) });
  
    useGetCoinsV2Query.cancelQueries = (params?: GetCoinsV2Params,) => queryClient.cancelQueries({ queryKey: getGetCoinsV2QueryQueryKey(params,) })
  
    useGetCoinsV2Query.invalidate = (params?: GetCoinsV2Params,) => queryClient.invalidateQueries({ queryKey: getGetCoinsV2QueryQueryKey(params,) });
  
    useGetCoinsV2Query.refetchStale = (params?: GetCoinsV2Params,) => queryClient.refetchQueries({ queryKey: getGetCoinsV2QueryQueryKey(params,), stale: true });
  
            

              export const getSingleCoinV2Query = (
    shortName: string,
    params?: GetSingleCoinV2Params,
 signal?: AbortSignal
) => {
      
      
      return bffApiInstance<Coin>(
      {url: `/v2/coins/${shortName}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetSingleCoinV2QueryQueryKey = (shortName: string,
    params?: GetSingleCoinV2Params,) => {
    return [`/v2/coins/${shortName}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSingleCoinV2QueryQueryOptions = <TData = Awaited<ReturnType<typeof getSingleCoinV2Query>>, TError = ErrorType<unknown>>(shortName: string,
    params?: GetSingleCoinV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSingleCoinV2Query>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSingleCoinV2QueryQueryKey(shortName,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSingleCoinV2Query>>> = ({ signal }) => getSingleCoinV2Query(shortName,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(shortName), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSingleCoinV2Query>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSingleCoinV2QueryQueryResult = NonNullable<Awaited<ReturnType<typeof getSingleCoinV2Query>>>
export type GetSingleCoinV2QueryQueryError = ErrorType<unknown>

export const useGetSingleCoinV2Query = <TData = Awaited<ReturnType<typeof getSingleCoinV2Query>>, TError = ErrorType<unknown>>(
 shortName: string,
    params?: GetSingleCoinV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSingleCoinV2Query>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSingleCoinV2QueryQueryOptions(shortName,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSingleCoinV2QuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getSingleCoinV2Query>>, TError = ErrorType<unknown>>(shortName: string,
    params?: GetSingleCoinV2Params, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSingleCoinV2Query>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSingleCoinV2QueryQueryKey(shortName,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSingleCoinV2Query>>> = ({ signal }) => getSingleCoinV2Query(shortName,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(shortName), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSingleCoinV2Query>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSingleCoinV2QuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getSingleCoinV2Query>>>
export type GetSingleCoinV2QuerySuspenseQueryError = ErrorType<unknown>

export const useGetSingleCoinV2QuerySuspense = <TData = Awaited<ReturnType<typeof getSingleCoinV2Query>>, TError = ErrorType<unknown>>(
 shortName: string,
    params?: GetSingleCoinV2Params, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSingleCoinV2Query>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSingleCoinV2QuerySuspenseQueryOptions(shortName,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetSingleCoinV2Query.queryKey = (shortName: string,
    params?: GetSingleCoinV2Params,) => getGetSingleCoinV2QueryQueryKey(shortName,
    params,);

    useGetSingleCoinV2Query.updateCache = ({shortName,
    params, updater, options}: {shortName: string,
    params?: GetSingleCoinV2Params, updater: Updater<Coin | undefined, Coin | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<Coin>(getGetSingleCoinV2QueryQueryKey(shortName,
    params,), updater, options);
    
    useGetSingleCoinV2Query.getQueryState = (shortName: string,
    params?: GetSingleCoinV2Params,) => queryClient.getQueryState<Coin>(getGetSingleCoinV2QueryQueryKey(shortName,
    params,));
    
    useGetSingleCoinV2Query.getQueryData = (shortName: string,
    params?: GetSingleCoinV2Params,) => queryClient.getQueryData<Coin>(getGetSingleCoinV2QueryQueryKey(shortName,
    params,));
  
    useGetSingleCoinV2Query.prefetch = (shortName: string,
    params?: GetSingleCoinV2Params,) => queryClient.prefetchQuery({ queryKey: getGetSingleCoinV2QueryQueryKey(shortName,
    params,) , queryFn: () => getSingleCoinV2Query(shortName,
    params,) });
  
    useGetSingleCoinV2Query.cancelQueries = (shortName: string,
    params?: GetSingleCoinV2Params,) => queryClient.cancelQueries({ queryKey: getGetSingleCoinV2QueryQueryKey(shortName,
    params,) })
  
    useGetSingleCoinV2Query.invalidate = (shortName: string,
    params?: GetSingleCoinV2Params,) => queryClient.invalidateQueries({ queryKey: getGetSingleCoinV2QueryQueryKey(shortName,
    params,) });
  
    useGetSingleCoinV2Query.refetchStale = (shortName: string,
    params?: GetSingleCoinV2Params,) => queryClient.refetchQueries({ queryKey: getGetSingleCoinV2QueryQueryKey(shortName,
    params,), stale: true });
  
            
export const sendFeedbackMutation = (
    feedbackInput: FeedbackInput,
 ) => {
      
      
      return bffApiInstance<SendFeedback201>(
      {url: `/mail/feedback`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: feedbackInput
    },
      );
    }
  


export const getSendFeedbackMutationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendFeedbackMutation>>, TError,{data: FeedbackInput}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sendFeedbackMutation>>, TError,{data: FeedbackInput}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendFeedbackMutation>>, {data: FeedbackInput}> = (props) => {
          const {data} = props ?? {};

          return  sendFeedbackMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SendFeedbackMutationMutationResult = NonNullable<Awaited<ReturnType<typeof sendFeedbackMutation>>>
    export type SendFeedbackMutationMutationBody = FeedbackInput
    export type SendFeedbackMutationMutationError = ErrorType<unknown>

    export const useSendFeedbackMutation = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendFeedbackMutation>>, TError,{data: FeedbackInput}, TContext>, }
) => {

      const mutationOptions = getSendFeedbackMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    

              export const getChannelVideosQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return bffApiInstance<YoutubeChannelResponse>(
      {url: `/videos/channel`, method: 'GET', signal
    },
      );
    }
  

export const getGetChannelVideosQueryQueryKey = () => {
    return [`/videos/channel`] as const;
    }

    
export const getGetChannelVideosQueryQueryOptions = <TData = Awaited<ReturnType<typeof getChannelVideosQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChannelVideosQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChannelVideosQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChannelVideosQuery>>> = ({ signal }) => getChannelVideosQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChannelVideosQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChannelVideosQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getChannelVideosQuery>>>
export type GetChannelVideosQueryQueryError = ErrorType<unknown>

export const useGetChannelVideosQuery = <TData = Awaited<ReturnType<typeof getChannelVideosQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChannelVideosQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChannelVideosQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChannelVideosQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getChannelVideosQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChannelVideosQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChannelVideosQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChannelVideosQuery>>> = ({ signal }) => getChannelVideosQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChannelVideosQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChannelVideosQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getChannelVideosQuery>>>
export type GetChannelVideosQuerySuspenseQueryError = ErrorType<unknown>

export const useGetChannelVideosQuerySuspense = <TData = Awaited<ReturnType<typeof getChannelVideosQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChannelVideosQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChannelVideosQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetChannelVideosQuery.queryKey = () => getGetChannelVideosQueryQueryKey();

    useGetChannelVideosQuery.updateCache = ({updater, options}: {updater: Updater<YoutubeChannelResponse | undefined, YoutubeChannelResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<YoutubeChannelResponse>(getGetChannelVideosQueryQueryKey(), updater, options);
    
    useGetChannelVideosQuery.getQueryState = () => queryClient.getQueryState<YoutubeChannelResponse>(getGetChannelVideosQueryQueryKey());
    
    useGetChannelVideosQuery.getQueryData = () => queryClient.getQueryData<YoutubeChannelResponse>(getGetChannelVideosQueryQueryKey());
  
    useGetChannelVideosQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetChannelVideosQueryQueryKey() , queryFn: () => getChannelVideosQuery() });
  
    useGetChannelVideosQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetChannelVideosQueryQueryKey() })
  
    useGetChannelVideosQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetChannelVideosQueryQueryKey() });
  
    useGetChannelVideosQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetChannelVideosQueryQueryKey(), stale: true });
  
            

              export const getVideoSummariesQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return bffApiInstance<VideoSummary[]>(
      {url: `/videos/summary`, method: 'GET', signal
    },
      );
    }
  

export const getGetVideoSummariesQueryQueryKey = () => {
    return [`/videos/summary`] as const;
    }

    
export const getGetVideoSummariesQueryQueryOptions = <TData = Awaited<ReturnType<typeof getVideoSummariesQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVideoSummariesQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVideoSummariesQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVideoSummariesQuery>>> = ({ signal }) => getVideoSummariesQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVideoSummariesQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVideoSummariesQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getVideoSummariesQuery>>>
export type GetVideoSummariesQueryQueryError = ErrorType<unknown>

export const useGetVideoSummariesQuery = <TData = Awaited<ReturnType<typeof getVideoSummariesQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVideoSummariesQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVideoSummariesQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetVideoSummariesQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getVideoSummariesQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVideoSummariesQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVideoSummariesQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVideoSummariesQuery>>> = ({ signal }) => getVideoSummariesQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVideoSummariesQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVideoSummariesQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getVideoSummariesQuery>>>
export type GetVideoSummariesQuerySuspenseQueryError = ErrorType<unknown>

export const useGetVideoSummariesQuerySuspense = <TData = Awaited<ReturnType<typeof getVideoSummariesQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVideoSummariesQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVideoSummariesQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetVideoSummariesQuery.queryKey = () => getGetVideoSummariesQueryQueryKey();

    useGetVideoSummariesQuery.updateCache = ({updater, options}: {updater: Updater<VideoSummary[] | undefined, VideoSummary[] | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<VideoSummary[]>(getGetVideoSummariesQueryQueryKey(), updater, options);
    
    useGetVideoSummariesQuery.getQueryState = () => queryClient.getQueryState<VideoSummary[]>(getGetVideoSummariesQueryQueryKey());
    
    useGetVideoSummariesQuery.getQueryData = () => queryClient.getQueryData<VideoSummary[]>(getGetVideoSummariesQueryQueryKey());
  
    useGetVideoSummariesQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetVideoSummariesQueryQueryKey() , queryFn: () => getVideoSummariesQuery() });
  
    useGetVideoSummariesQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetVideoSummariesQueryQueryKey() })
  
    useGetVideoSummariesQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetVideoSummariesQueryQueryKey() });
  
    useGetVideoSummariesQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetVideoSummariesQueryQueryKey(), stale: true });
  
            

              export const getPlaylistVideosQuery = (
    params: GetPlaylistVideosParams,
 signal?: AbortSignal
) => {
      
      
      return bffApiInstance<YoutubePlaylistResponse>(
      {url: `/videos/playlist`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetPlaylistVideosQueryQueryKey = (params: GetPlaylistVideosParams,) => {
    return [`/videos/playlist`, ...(params ? [params]: [])] as const;
    }

    
export const getGetPlaylistVideosQueryQueryOptions = <TData = Awaited<ReturnType<typeof getPlaylistVideosQuery>>, TError = ErrorType<unknown>>(params: GetPlaylistVideosParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlaylistVideosQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPlaylistVideosQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlaylistVideosQuery>>> = ({ signal }) => getPlaylistVideosQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPlaylistVideosQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPlaylistVideosQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getPlaylistVideosQuery>>>
export type GetPlaylistVideosQueryQueryError = ErrorType<unknown>

export const useGetPlaylistVideosQuery = <TData = Awaited<ReturnType<typeof getPlaylistVideosQuery>>, TError = ErrorType<unknown>>(
 params: GetPlaylistVideosParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlaylistVideosQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPlaylistVideosQueryQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetPlaylistVideosQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getPlaylistVideosQuery>>, TError = ErrorType<unknown>>(params: GetPlaylistVideosParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPlaylistVideosQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPlaylistVideosQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlaylistVideosQuery>>> = ({ signal }) => getPlaylistVideosQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPlaylistVideosQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPlaylistVideosQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPlaylistVideosQuery>>>
export type GetPlaylistVideosQuerySuspenseQueryError = ErrorType<unknown>

export const useGetPlaylistVideosQuerySuspense = <TData = Awaited<ReturnType<typeof getPlaylistVideosQuery>>, TError = ErrorType<unknown>>(
 params: GetPlaylistVideosParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPlaylistVideosQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPlaylistVideosQuerySuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetPlaylistVideosQuery.queryKey = (params: GetPlaylistVideosParams,) => getGetPlaylistVideosQueryQueryKey(params,);

    useGetPlaylistVideosQuery.updateCache = ({params, updater, options}: {params: GetPlaylistVideosParams, updater: Updater<YoutubePlaylistResponse | undefined, YoutubePlaylistResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<YoutubePlaylistResponse>(getGetPlaylistVideosQueryQueryKey(params,), updater, options);
    
    useGetPlaylistVideosQuery.getQueryState = (params: GetPlaylistVideosParams,) => queryClient.getQueryState<YoutubePlaylistResponse>(getGetPlaylistVideosQueryQueryKey(params,));
    
    useGetPlaylistVideosQuery.getQueryData = (params: GetPlaylistVideosParams,) => queryClient.getQueryData<YoutubePlaylistResponse>(getGetPlaylistVideosQueryQueryKey(params,));
  
    useGetPlaylistVideosQuery.prefetch = (params: GetPlaylistVideosParams,) => queryClient.prefetchQuery({ queryKey: getGetPlaylistVideosQueryQueryKey(params,) , queryFn: () => getPlaylistVideosQuery(params,) });
  
    useGetPlaylistVideosQuery.cancelQueries = (params: GetPlaylistVideosParams,) => queryClient.cancelQueries({ queryKey: getGetPlaylistVideosQueryQueryKey(params,) })
  
    useGetPlaylistVideosQuery.invalidate = (params: GetPlaylistVideosParams,) => queryClient.invalidateQueries({ queryKey: getGetPlaylistVideosQueryQueryKey(params,) });
  
    useGetPlaylistVideosQuery.refetchStale = (params: GetPlaylistVideosParams,) => queryClient.refetchQueries({ queryKey: getGetPlaylistVideosQueryQueryKey(params,), stale: true });
  
            
export const shareVaultQuery = (
    shareVaultInput: ShareVaultInput,
 ) => {
      
      
      return bffApiInstance<ShareResponse>(
      {url: `/share/vault`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: shareVaultInput
    },
      );
    }
  

export const getShareVaultQueryQueryKey = (shareVaultInput: ShareVaultInput,) => {
    return [`/share/vault`, shareVaultInput] as const;
    }

    
export const getShareVaultQueryQueryOptions = <TData = Awaited<ReturnType<typeof shareVaultQuery>>, TError = ErrorType<unknown>>(shareVaultInput: ShareVaultInput, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof shareVaultQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getShareVaultQueryQueryKey(shareVaultInput);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof shareVaultQuery>>> = () => shareVaultQuery(shareVaultInput, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof shareVaultQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type ShareVaultQueryQueryResult = NonNullable<Awaited<ReturnType<typeof shareVaultQuery>>>
export type ShareVaultQueryQueryError = ErrorType<unknown>

export const useShareVaultQuery = <TData = Awaited<ReturnType<typeof shareVaultQuery>>, TError = ErrorType<unknown>>(
 shareVaultInput: ShareVaultInput, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof shareVaultQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getShareVaultQueryQueryOptions(shareVaultInput,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const shareVaultResultQuery = (
    shareVaultResultInput: ShareVaultResultInput,
 ) => {
      
      
      return bffApiInstance<ShareResponse>(
      {url: `/share/vault-result`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: shareVaultResultInput
    },
      );
    }
  

export const getShareVaultResultQueryQueryKey = (shareVaultResultInput: ShareVaultResultInput,) => {
    return [`/share/vault-result`, shareVaultResultInput] as const;
    }

    
export const getShareVaultResultQueryQueryOptions = <TData = Awaited<ReturnType<typeof shareVaultResultQuery>>, TError = ErrorType<unknown>>(shareVaultResultInput: ShareVaultResultInput, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof shareVaultResultQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getShareVaultResultQueryQueryKey(shareVaultResultInput);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof shareVaultResultQuery>>> = () => shareVaultResultQuery(shareVaultResultInput, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof shareVaultResultQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type ShareVaultResultQueryQueryResult = NonNullable<Awaited<ReturnType<typeof shareVaultResultQuery>>>
export type ShareVaultResultQueryQueryError = ErrorType<unknown>

export const useShareVaultResultQuery = <TData = Awaited<ReturnType<typeof shareVaultResultQuery>>, TError = ErrorType<unknown>>(
 shareVaultResultInput: ShareVaultResultInput, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof shareVaultResultQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getShareVaultResultQueryQueryOptions(shareVaultResultInput,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const shareCoinListingQuery = (
    shareCoinListingInput: ShareCoinListingInput,
 ) => {
      
      
      return bffApiInstance<ShareResponse>(
      {url: `/share/coinlisting`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: shareCoinListingInput
    },
      );
    }
  

export const getShareCoinListingQueryQueryKey = (shareCoinListingInput: ShareCoinListingInput,) => {
    return [`/share/coinlisting`, shareCoinListingInput] as const;
    }

    
export const getShareCoinListingQueryQueryOptions = <TData = Awaited<ReturnType<typeof shareCoinListingQuery>>, TError = ErrorType<unknown>>(shareCoinListingInput: ShareCoinListingInput, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof shareCoinListingQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getShareCoinListingQueryQueryKey(shareCoinListingInput);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof shareCoinListingQuery>>> = () => shareCoinListingQuery(shareCoinListingInput, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof shareCoinListingQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type ShareCoinListingQueryQueryResult = NonNullable<Awaited<ReturnType<typeof shareCoinListingQuery>>>
export type ShareCoinListingQueryQueryError = ErrorType<unknown>

export const useShareCoinListingQuery = <TData = Awaited<ReturnType<typeof shareCoinListingQuery>>, TError = ErrorType<unknown>>(
 shareCoinListingInput: ShareCoinListingInput, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof shareCoinListingQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getShareCoinListingQueryQueryOptions(shareCoinListingInput,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




