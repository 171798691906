import axios from "axios";
import qs from "query-string";

export const api = axios.create({
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "comma" });
  },
});

export const userApi = axios.create();

export const youtubeApi = axios.create();

export const coinDataApi = axios.create();

export const bffApi = axios.create();

export const iPApi = axios.create();
