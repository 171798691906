import "./Skeleton.scss";

import classNames from "classnames";
import { ComponentPropsWithoutRef, useEffect, useState } from "react";

import { DataTableSkeleton, DataTableSkeletonProps } from "./variants/DataTableSkeleton";
import { PageSkeleton } from "./variants/PageSkeleton";
import { PaperSkeleton } from "./variants/PaperSkeleton";

type SkeletonBaseProps = {
  delay?: number;
} & Pick<ComponentPropsWithoutRef<"div">, "style" | "className">;

type SkeletonVariantProps =
  | {
      variant: "page" | "paper";
    }
  | ({ variant: "datatable" } & DataTableSkeletonProps);

export type SkeletonProps = SkeletonBaseProps & SkeletonVariantProps;

function Skeleton({ delay = 150, style, className, ...props }: SkeletonProps) {
  const [isVisible, setIsVisible] = useState(delay !== 0);

  // Only show the LoadingState after a certain delay. This improves the users notion of the speed of the UI.
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  if (!isVisible) {
    return null;
  }

  return (
    <div style={style} className={classNames("skeleton", className)}>
      <SkeletonVariant {...props} />
    </div>
  );
}

function SkeletonVariant({ variant, ...rest }: SkeletonVariantProps) {
  switch (variant) {
    case "page": {
      return <PageSkeleton />;
    }
    case "datatable": {
      return <DataTableSkeleton {...(rest as DataTableSkeletonProps)} />;
    }
    case "paper": {
      return <PaperSkeleton />;
    }
  }
}

export default Skeleton;
