import type { TranslationDictionary } from "@shopify/react-i18n";
import type {
  ComplexReplacementDictionary,
  PrimitiveReplacementDictionary,
} from "@shopify/react-i18n/build/ts/types";
import type React from "react";

import { I18nFormatters } from "./I18nFormatters";

export type ResolveKeys<T extends TranslationDictionary, Namespace extends keyof T> = T[Namespace];

export class TypeSafeI18n<T extends TranslationDictionary> extends I18nFormatters {
  text<Namespace extends keyof T>(
    namespace: Namespace,
    key: keyof ResolveKeys<T, Namespace>,
    replacements?: PrimitiveReplacementDictionary
  ): string;
  text<Namespace extends keyof T>(
    namespace: Namespace,
    key: keyof ResolveKeys<T, Namespace>,
    replacements?: ComplexReplacementDictionary
  ): React.ReactElement;
  text<Namespace extends keyof T>(
    namespace: Namespace,
    key: keyof ResolveKeys<T, Namespace>,
    replacements?: PrimitiveReplacementDictionary | ComplexReplacementDictionary
  ): string | React.ReactElement {
    if (this.context.e2eMode) {
      return `${String(namespace)}.${String(key)}`;
    }
    return super.translate(`${String(namespace)}.${String(key)}`, replacements);
  }
}
