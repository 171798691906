import {
  GetFinalProps,
  GetProps,
  GetThemeValueForKey,
  RNTamaguiTextNonStyleProps,
  styled,
  Text,
  TextProps,
  TextStylePropsBase,
} from "@tamagui/core";
import React, { ComponentPropsWithoutRef, SVGProps } from "react";

import { Loader } from "../Loader";

const floatingButton = (backgroundColor: GetThemeValueForKey<"backgroundColor">) =>
  ({
    backgroundColor,

    disabledStyle: {
      backgroundColor: "$default",
    },

    "$theme-dark": {
      color: "$white",

      disabledStyle: {
        backgroundColor: "$background",
      },
    },
  }) satisfies Partial<GetFinalProps<RNTamaguiTextNonStyleProps, TextStylePropsBase, object>>;

const floatingButtonInner = (backgroundColor: GetThemeValueForKey<"backgroundColor">) =>
  ({
    backgroundColor,
    transform: [{ translateY: -5 }],

    hoverStyle: {
      transform: [{ translateY: 0 }],
    },

    focusStyle: {
      transform: [{ translateY: 0 }],
    },

    pressStyle: {
      transform: [{ translateY: 0 }],
    },

    disabledStyle: {
      backgroundColor: "$light",
      borderColor: "$default",
      transform: [{ translateY: -5 }],
      pointerEvents: "none",
    },

    "$theme-dark": {
      disabledStyle: {
        borderColor: "$background",
      },
    },
  }) satisfies GetFinalProps<RNTamaguiTextNonStyleProps, TextStylePropsBase, object>;

const ButtonInnerBase = styled(Text, {
  name: "ButtonInner",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: 56,
  flexGrow: 1,
  height: "100%",
  paddingVertical: 0,
  paddingHorizontal: "$24",
  borderRadius: "$regular",
  gap: "$10",
  fontFamily: "$heading",
  fontSize: 16,
  textDecorationLine: "none",
  animation: "fast",
  animateOnly: ["transform"],
  whiteSpace: "nowrap",

  $excludePhone: {
    fontSize: 18,
  },

  variants: {
    variant: {
      primary: floatingButtonInner("$bloxYellow"),
      secondary: floatingButtonInner("$bloxBlue"),
      red: floatingButtonInner("$bloxRed"),
      error: floatingButtonInner("$alert"),
      flat: {},
      "flat-blue": {},
      "flat-grey": {},
      icon: {
        padding: 0,
      },
      text: {
        padding: 0,
      },
      "text-blue": {
        padding: 0,
      },
      nav: {
        display: "block",
        paddingHorizontal: 12,
      },
      list: {
        justifyContent: "space-between",
      },
      white: {
        ...floatingButtonInner("$white"),
        borderWidth: 1,
        borderColor: "$default",

        "$theme-dark": {
          borderColor: "$light",
        },
      },
      default: {
        ...floatingButtonInner("$light"),
        borderWidth: 1,
        borderColor: "$default",

        "$theme-dark": {
          backgroundColor: "$white",
          borderColor: "$light",
        },
      },
    },
  } as const,
});

const ButtonInner = ButtonInnerBase.styleable<Pick<BaseButtonProps, "Icon" | "iconPosition" | "loading">>(
  ({ children, Icon, iconPosition, variant, loading, ...props }, ref) => {
    return (
      <ButtonInnerBase variant={variant} {...props} ref={ref}>
        {Icon && iconPosition === "before" && <Icon />}
        {loading ? <Loader variant="small" color={variant === "text" ? "blue" : "white"} /> : children}
        {Icon && iconPosition === "after" && <Icon />}
      </ButtonInnerBase>
    );
  }
);

const ButtonBase = styled(Text, {
  name: "Button",
  display: "inline-flex",
  width: "100%",
  height: "$56",
  padding: 0,
  borderWidth: 0,
  borderRadius: "$regular",
  color: "$black",
  whiteSpace: "nowrap",
  cursor: "pointer",
  fontWeight: "$normal",
  textDecorationLine: "none",

  disabledStyle: {
    color: "$default",
    cursor: "default",
  },

  variants: {
    variant: {
      primary: floatingButton("$bloxYellowDark"),
      secondary: floatingButton("$bloxBlueDark"),
      red: {
        ...floatingButton("$bloxRedDark"),
        color: "white",

        "$theme-dark": {
          color: "white",
        },
      },
      error: {
        ...floatingButton("$alertDark"),
        color: "white",

        "$theme-dark": {
          color: "white",
        },
      },
      flat: {
        borderWidth: 1,
        borderColor: "$border",
        backgroundColor: "$white",
        color: "$darker",

        hoverStyle: {
          color: "$darker",
          borderColor: "$button",
        },

        focusStyle: {
          color: "$darker",
          borderColor: "$button",
        },

        pressStyle: {
          color: "$darker",
          borderColor: "$button",
          backgroundColor: "$offWhite",
        },

        "$theme-dark": {
          hoverStyle: {
            color: "$black",
            borderColor: "$border",
          },

          focusStyle: {
            color: "$black",
            borderColor: "$border",
          },

          pressStyle: {
            color: "$black",
            borderColor: "$border",
            backgroundColor: "$offWhite",
          },
        },
      },
      "flat-blue": {
        borderWidth: 1,
        borderColor: "$bloxBlueDark",
        backgroundColor: "$bloxBlueDark",
        color: "$darker",

        hoverStyle: {
          color: "$darker",
          borderColor: "$bloxBlueDarker",
        },

        focusStyle: {
          color: "$darker",
          borderColor: "$bloxBlueDarker",
        },

        pressStyle: {
          color: "$darker",
          borderColor: "$bloxBlueDarker",
          backgroundColor: "$bloxBlueDarker",
        },

        "$theme-dark": {
          borderColor: "$border",
          backgroundColor: "$offWhite",

          hoverStyle: {
            color: "$black",
            borderColor: "$border",
          },

          focusStyle: {
            color: "$black",
            borderColor: "$border",
          },

          pressStyle: {
            color: "$black",
            borderColor: "$dark",
          },
        },
      },
      "flat-grey": {
        borderWidth: 1,
        borderColor: "$border",
        backgroundColor: "$white",
        color: "$dark",

        hoverStyle: {
          color: "$darker",
          borderColor: "$button",
        },

        focusStyle: {
          color: "$darker",
          borderColor: "$button",
        },

        pressStyle: {
          color: "$dark",
          backgroundColor: "$offWhite",
        },

        "$theme-dark": {
          color: "$black",
          hoverStyle: {
            color: "$dark",
            borderColor: "$border",
          },

          focusStyle: {
            color: "$dark",
            borderColor: "$border",
          },

          pressStyle: {
            color: "$dark",
          },
        },
      },
      icon: {
        width: "auto",
        color: "$default",
        background: "none",

        hoverStyle: {
          color: "$dark",
        },

        focusStyle: {
          color: "$dark",
        },

        pressStyle: {
          color: "$darker",
        },
      },
      text: {
        width: "auto",
        height: "auto",
        color: "inherit",
        background: "none",
      },
      "text-blue": {
        width: "auto",
        height: "auto",
        color: "$bloxBlueDarker",
        background: "none",

        hoverStyle: {
          color: "$bloxBlueDarkest",
        },

        focusStyle: {
          color: "$bloxBlueDarkest",
        },

        pressStyle: {
          color: "$bloxBlueDarkest",
        },

        "$theme-dark": {
          hoverStyle: {
            color: "$bloxBlueDark",
          },

          focusStyle: {
            color: "$bloxBlueDark",
          },

          pressStyle: {
            color: "$bloxBlueDark",
          },
        },
      },
      nav: {
        color: "$darker",
        marginHorizontal: -12,
        display: "block",
        width: "calc(100% + 24px)",
        background: "none",

        hoverStyle: {
          backgroundColor: "$lighter",
        },

        pressStyle: {
          backgroundColor: "$lighter",
          color: "$bloxBlueDarker",
        },

        focusStyle: {
          backgroundColor: "$bloxBlueLightest",
          color: "$bloxBlueDarkest",
        },

        "$theme-dark": {
          hoverStyle: {
            backgroundColor: "$light",
          },

          pressStyle: {
            backgroundColor: "$light",
            color: "$bloxBlueDark",
          },

          focusStyle: {
            backgroundColor: "$bloxBlueLightest",
            color: "$bloxBlueDarkest",
          },
        },
      },
      list: {
        borderWidth: 1,
        borderColor: "transparent",
        backgroundColor: "$lighter",
        color: "$darker",

        hoverStyle: {
          backgroundColor: "$lighter",
          color: "$darker",
          borderColor: "$button",
        },

        focusStyle: {
          backgroundColor: "$lighter",
          color: "$darker",
          borderColor: "$button",
        },

        pressStyle: {
          backgroundColor: "$light",
          color: "$darker",
          borderColor: "$default",
        },

        "$theme-dark": {
          backgroundColor: "$light",

          hoverStyle: {
            backgroundColor: "$light",
            borderColor: "$default",
          },

          focusStyle: {
            backgroundColor: "$light",
            borderColor: "$default",
          },

          pressStyle: {
            borderColor: "$darker",
          },
        },
      },
      white: {
        ...floatingButton("$light"), // @todo light mode colors.grey400
        color: "$black",

        "$theme-dark": {
          backgroundColor: "$light",
        },
      },
      default: {
        ...floatingButton("$default"),
        color: "$black",

        "$theme-dark": {
          backgroundColor: "$light",
        },
      },
    },
    size: {
      thin: {
        height: 36,
        fontSize: 16,
      },
      flex: {
        width: "auto",
      },
    },
    loading: {
      true: {
        pointerEvents: "none",
      },
    },
  } as const,
});

type CustomButtonProps = (
  | {
      anchor: true;
      rel?: ComponentPropsWithoutRef<"button">["rel"];
    }
  | {
      anchor?: false;
      type?: ComponentPropsWithoutRef<"button">["type"];
    }
) & {
  onClick?: TextProps["onPress"];
  Icon?: React.FC<SVGProps<SVGSVGElement>>;
  iconPosition?: "before" | "after";
  innerProps?: TextProps;
};

export const Button = ButtonBase.styleable<CustomButtonProps>(
  (
    {
      onClick,
      anchor,
      children,
      Icon,
      iconPosition = "before",
      loading,
      variant = "primary",
      innerProps,
      ...props
    },
    ref
  ) => {
    const externalAttributes =
      anchor && props.href && !props.href?.startsWith("/")
        ? {
            target: "_blank",
            rel: "noopener noreferrer",
          }
        : undefined;

    return (
      <ButtonBase
        onPress={onClick}
        {...externalAttributes}
        {...props}
        variant={variant}
        tag={anchor ? "a" : "button"}
        ref={ref}
      >
        <ButtonInner
          className="btn__inner"
          variant={variant}
          disabled={props.disabled}
          Icon={Icon}
          iconPosition={iconPosition}
          loading={loading}
          {...innerProps}
        >
          {children}
        </ButtonInner>
      </ButtonBase>
    );
  }
);

export type BaseButtonProps = GetProps<typeof Button>;
