import { Locale } from "@blox/api";
import { Box, Paragraph, styled } from "@blox/design-system";

import { useLocalisation } from "../../../context/LocalizeProvider";
import useAssumedUserCountry from "../../../hooks/useAssumedUserCountry";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { useLocale } from "../../../hooks/useStore";

const Base = styled(Box, {
  paddingHorizontal: "$pagePaddingMobile",
  backgroundColor: "$border",

  $excludePhone: {
    paddingHorizontal: "$pagePadding",
  },
});

const getRiskWarningUrl = (locale: Locale) => {
  switch (locale) {
    case "nl":
      return "https://weareblox.com/nl-be/risicowaarschuwing";
    case "fr":
      return "https://weareblox.com/fr-be/disclaimer";
    default:
      return "https://weareblox.com/en-gb/risk-warning";
  }
};

const getCryptoInfoLink = (locale: Locale) => {
  switch (locale) {
    case "fr":
    case "es":
      return "https://www.wikifin.be/fr/epargner-et-investir/produits-dinvestissement/autres-produits-dinvestissement/quest-ce-quune";
    default:
      return "https://www.wikifin.be/nl/sparen-en-beleggen/beleggingsproducten/andere-beleggingsproducten/wat-een-cryptomunt";
  }
};

function RiskBanner() {
  const locale = useLocale();
  const t = useLocalisation();
  const userCountry = useAssumedUserCountry();

  const isMobileOnly = useBreakpoint("phoneOnly");

  if (userCountry === "BE") {
    return (
      <Base>
        <Box maxWidth={1260} marginHorizontal="auto">
          <Paragraph variant={["fadedBlue", "small"]} fontSize={14} lineHeight={21}>
            {isMobileOnly ? (
              <>
                {t.text("RiskBanner", "mobileContent")}{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={getRiskWarningUrl(locale)}
                  style={{ fontSize: 14, lineHeight: 1.5 }}
                >
                  {t.text("RiskBanner", "mobileReadMore")}
                </a>
              </>
            ) : (
              <>
                <strong>{t.text("RiskBanner", "desktopTitle")}</strong>
                <br />
                {t.text("RiskBanner", "desktopContent", {
                  cryptoInfoLink: (
                    <a
                      href={getCryptoInfoLink(locale)}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      style={{ fontSize: 14, lineHeight: 1.5 }}
                    >
                      {t.text("RiskBanner", "cryptoInfoLinkText")}
                    </a>
                  ),
                })}
              </>
            )}
          </Paragraph>
        </Box>
      </Base>
    );
  }

  return null;
}

export default RiskBanner;
