import { useIsFeatureEnabledQuery } from "@blox/api";
import { api, bffApi, coinDataApi, iPApi, userApi } from "@blox/api/api";
import { AxiosError } from "axios";
import { User } from "oidc-client-ts";

import { queryClient } from "~queryClient";

import configParams from "./env";

api.defaults.baseURL = configParams.API_BASE;
userApi.defaults.baseURL = configParams.AUTH_BASE;
coinDataApi.defaults.baseURL = configParams.COINDATA_URL;
iPApi.defaults.baseURL = configParams.IP_URL;
bffApi.defaults.baseURL = configParams.BFF_URL;

export const USER_SESSION_KEY = `oidc.user:${configParams.AUTH_BASE}/realms/blox:${configParams.CLIENT_ID}`;

const responseInterceptors: Parameters<typeof api.interceptors.response.use> = [
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async (error: AxiosError) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.status === 401) {
      console.error("401");
    }
    if (error.response?.status === 503) {
      // If we get a 503 set maintenance mode as enabled
      queryClient.setQueryData(useIsFeatureEnabledQuery.queryKey("BLOX"), {
        enabled: false,
      });
    }
    return Promise.reject(error);
  },
];

const requestInterceptors: Parameters<typeof api.interceptors.request.use> = [
  (requestConfig) => {
    const sessionString = window.localStorage.getItem(USER_SESSION_KEY);

    if (sessionString) {
      const user = User.fromStorageString(sessionString);

      if (user.access_token) {
        requestConfig!.headers!.Authorization = `Bearer ${user.access_token}`;
      }
    }
    return requestConfig;
  },
  (error) => {
    return Promise.reject(error);
  },
];

api.interceptors.response.use(...responseInterceptors);
api.interceptors.request.use(...requestInterceptors);

userApi.interceptors.response.use(...responseInterceptors);
userApi.interceptors.request.use(...requestInterceptors);
