export function getCoinAmountInEuroDecimals(amount: number) {
  if (amount === 0) {
    return 2;
  }
  if (amount < 0.00001) {
    return 10;
  }
  if (amount < 0.0001) {
    return 8;
  }
  if (amount < 0.01) {
    return 6;
  }
  if (amount < 1) {
    return 4;
  }
  return 2;
}
