/* eslint-disable */
const configEN = ({ onAccept }: { onAccept: () => void }) => ({
  apiKey: "46918ccb2e4b3778b9be4cb38d1e54dec553b370",
  product: "PRO",
  mode: "gdpr",
  consentCookieExpiry: 365,
  initialState: "notify",
  notifyOnce: false,
  layout: "slideout",
  position: "left",
  notifyDismissButton: true,
  setInnerHTML: true,
  branding: {
    buttonIcon: null,
    removeIcon: true,
    removeAbout: true,
  },
  text: {
    accept: "Accept",
    reject: "",
    settings: "Learn More",
    title: "More info on cookies",
    intro:
      "<p>BLOX uses cookies to make the website more relevant to users and to increase ease of use. We use three types of cookies to do this:</p>\n\n<ul>\n\t<li>Essential cookies</li>\n\t<li>Analysis cookies</li>\n\t<li>Marketing cookies</li>\n</ul>\n\n<p>We explain what we use these cookies for.</p>\n",
    acceptSettings: "Accept",
    rejectSettings: "Decline",
    acceptRecommended: "Accept",
    necessaryTitle: "Essential cookies",
    necessaryDescription:
      "<p>Essential cookies are necessary for the site to work properly. They ensure that the site can be seen properly on any device and ensure that you can log in securely. These cookies can therefore not be refused.</p>\n",
    thirdPartyTitle: "",
    thirdPartyDescription: "",
    on: "On",
    off: "Off",
    notifyTitle: "Cookies BLOX",
    notifyDescription:
      "<p>These cookies are necessary to make BLOX more personal. They do not store any personal data. Are you ok with this?</p>\n",
  },
  statement: {
    description: "Want to know more? Read our",
    name: "Privacy Policy",
    url: "https://weareblox.com/en-gb/privacy-policy",
    updated: "10/06/2021",
  },
  necessaryCookies: ["locale", "BTCDEV", "sec_session_id", "bannerRemovedCookie"],
  optionalCookies: [
    {
      name: "analytics",
      label: "Analysis cookies ",
      description:
        "<p>We use these cookies to analyze how often the website is visited, with which browsers, which pages of the website were visited. This helps us to improve the website.</p>\n",
      cookies: ["_gat", "_ga", "_gads", "_gid", "_hjid"],
      onAccept,
    },
    {
      name: "marketing",
      label: "Marketing cookies",
      description:
        '<p>With these cookies we collect information about users and visitors of our website, so we can show ads that are interesting for you. For example, on Social media and websites that work with <a href="https://www.nextroll.com/privacy">Adroll</a>.</p>\n',
      cookies: ["_fbp", "_ad_cikneeto_"],
      onAccept,
    },
  ],
});

export default configEN;
