/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * BLOX Reactive Client API
 * API spec for BLOX clients and services
 * OpenAPI spec version: 0.1.0
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseInfiniteQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  ContactInformationRequest,
  ContactInformationResponse,
  CreatePriceNotificationTriggerRequest,
  DcaCodeResponse,
  DepositAssetAddressResponse,
  DepositsAndWithdrawalsParams,
  EnableAllProductsBody,
  GetDcaCodeParams,
  ListPositionsParams,
  ListProductsParams,
  ListRewardsParams,
  OrdersParams,
  PriceNotificationTriggerResponse,
  PriceNotificationTriggersResponse,
  SupportedDcaAsset,
  SupportedDepositAsset,
  UpdatePriceNotificationTriggerRequest,
  UserAllowedToRedeemVoucher,
  VerificationStatusResponse,
  WithdrawalFeeResponse,
  WithdrawalRequest,
  WithdrawalResponse
} from './model'
import type {
  EurTransactionsResponseObject,
  OrdersResponseObject,
  PaidRewardsResponse,
  RewardPosition,
  RewardPositionsResponse,
  RewardProductId,
  RewardProductsResponse
} from './model/shared'
import { apiInstance } from '../../lib/api-instance';
import type { ErrorType } from '../../lib/api-instance';



              // @ts-ignore
              import { Updater, SetDataOptions, QueryFilters } from "@tanstack/react-query";
              // @ts-ignore
              import { queryClient } from "~queryClient";

              type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



;
            
              export const listPriceNotificationsQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<PriceNotificationTriggersResponse>(
      {url: `/notifications/prices`, method: 'GET', signal
    },
      );
    }
  

export const getListPriceNotificationsQueryQueryKey = () => {
    return [`/notifications/prices`] as const;
    }

    
export const getListPriceNotificationsQueryQueryOptions = <TData = Awaited<ReturnType<typeof listPriceNotificationsQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listPriceNotificationsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListPriceNotificationsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listPriceNotificationsQuery>>> = ({ signal }) => listPriceNotificationsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listPriceNotificationsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type ListPriceNotificationsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof listPriceNotificationsQuery>>>
export type ListPriceNotificationsQueryQueryError = ErrorType<unknown>

export const useListPriceNotificationsQuery = <TData = Awaited<ReturnType<typeof listPriceNotificationsQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listPriceNotificationsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListPriceNotificationsQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListPriceNotificationsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listPriceNotificationsQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listPriceNotificationsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListPriceNotificationsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listPriceNotificationsQuery>>> = ({ signal }) => listPriceNotificationsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listPriceNotificationsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type ListPriceNotificationsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listPriceNotificationsQuery>>>
export type ListPriceNotificationsQuerySuspenseQueryError = ErrorType<unknown>

export const useListPriceNotificationsQuerySuspense = <TData = Awaited<ReturnType<typeof listPriceNotificationsQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listPriceNotificationsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListPriceNotificationsQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useListPriceNotificationsQuery.queryKey = () => getListPriceNotificationsQueryQueryKey();

    useListPriceNotificationsQuery.updateCache = ({updater, options}: {updater: Updater<PriceNotificationTriggersResponse | undefined, PriceNotificationTriggersResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<PriceNotificationTriggersResponse>(getListPriceNotificationsQueryQueryKey(), updater, options);
    
    useListPriceNotificationsQuery.getQueryState = () => queryClient.getQueryState<PriceNotificationTriggersResponse>(getListPriceNotificationsQueryQueryKey());
    
    useListPriceNotificationsQuery.getQueryData = () => queryClient.getQueryData<PriceNotificationTriggersResponse>(getListPriceNotificationsQueryQueryKey());
  
    useListPriceNotificationsQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getListPriceNotificationsQueryQueryKey() , queryFn: () => listPriceNotificationsQuery() });
  
    useListPriceNotificationsQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getListPriceNotificationsQueryQueryKey() })
  
    useListPriceNotificationsQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getListPriceNotificationsQueryQueryKey() });
  
    useListPriceNotificationsQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getListPriceNotificationsQueryQueryKey(), stale: true });
  
            
export const createPriceNotificationMutation = (
    createPriceNotificationTriggerRequest: CreatePriceNotificationTriggerRequest,
 ) => {
      
      
      return apiInstance<PriceNotificationTriggerResponse>(
      {url: `/notifications/prices`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createPriceNotificationTriggerRequest
    },
      );
    }
  


export const getCreatePriceNotificationMutationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPriceNotificationMutation>>, TError,{data: CreatePriceNotificationTriggerRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createPriceNotificationMutation>>, TError,{data: CreatePriceNotificationTriggerRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPriceNotificationMutation>>, {data: CreatePriceNotificationTriggerRequest}> = (props) => {
          const {data} = props ?? {};

          return  createPriceNotificationMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreatePriceNotificationMutationMutationResult = NonNullable<Awaited<ReturnType<typeof createPriceNotificationMutation>>>
    export type CreatePriceNotificationMutationMutationBody = CreatePriceNotificationTriggerRequest
    export type CreatePriceNotificationMutationMutationError = ErrorType<unknown>

    export const useCreatePriceNotificationMutation = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPriceNotificationMutation>>, TError,{data: CreatePriceNotificationTriggerRequest}, TContext>, }
) => {

      const mutationOptions = getCreatePriceNotificationMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const updatePriceNotificationMutation = (
    triggerId: string,
    updatePriceNotificationTriggerRequest: UpdatePriceNotificationTriggerRequest,
 ) => {
      
      
      return apiInstance<PriceNotificationTriggerResponse>(
      {url: `/notifications/prices/${triggerId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updatePriceNotificationTriggerRequest
    },
      );
    }
  


export const getUpdatePriceNotificationMutationMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePriceNotificationMutation>>, TError,{triggerId: string;data: UpdatePriceNotificationTriggerRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updatePriceNotificationMutation>>, TError,{triggerId: string;data: UpdatePriceNotificationTriggerRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePriceNotificationMutation>>, {triggerId: string;data: UpdatePriceNotificationTriggerRequest}> = (props) => {
          const {triggerId,data} = props ?? {};

          return  updatePriceNotificationMutation(triggerId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdatePriceNotificationMutationMutationResult = NonNullable<Awaited<ReturnType<typeof updatePriceNotificationMutation>>>
    export type UpdatePriceNotificationMutationMutationBody = UpdatePriceNotificationTriggerRequest
    export type UpdatePriceNotificationMutationMutationError = ErrorType<void>

    export const useUpdatePriceNotificationMutation = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePriceNotificationMutation>>, TError,{triggerId: string;data: UpdatePriceNotificationTriggerRequest}, TContext>, }
) => {

      const mutationOptions = getUpdatePriceNotificationMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deletePriceNotificationMutation = (
    triggerId: string,
 ) => {
      
      
      return apiInstance<void>(
      {url: `/notifications/prices/${triggerId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeletePriceNotificationMutationMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePriceNotificationMutation>>, TError,{triggerId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deletePriceNotificationMutation>>, TError,{triggerId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePriceNotificationMutation>>, {triggerId: string}> = (props) => {
          const {triggerId} = props ?? {};

          return  deletePriceNotificationMutation(triggerId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeletePriceNotificationMutationMutationResult = NonNullable<Awaited<ReturnType<typeof deletePriceNotificationMutation>>>
    
    export type DeletePriceNotificationMutationMutationError = ErrorType<void>

    export const useDeletePriceNotificationMutation = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePriceNotificationMutation>>, TError,{triggerId: string}, TContext>, }
) => {

      const mutationOptions = getDeletePriceNotificationMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    

              export const retrieveQuestionnaireQuery = (
    version: number,
 signal?: AbortSignal
) => {
      
      
      return apiInstance<string>(
      {url: `/questionnaire/${version}`, method: 'GET', signal
    },
      );
    }
  

export const getRetrieveQuestionnaireQueryQueryKey = (version: number,) => {
    return [`/questionnaire/${version}`] as const;
    }

    
export const getRetrieveQuestionnaireQueryQueryOptions = <TData = Awaited<ReturnType<typeof retrieveQuestionnaireQuery>>, TError = ErrorType<void>>(version: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof retrieveQuestionnaireQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRetrieveQuestionnaireQueryQueryKey(version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveQuestionnaireQuery>>> = ({ signal }) => retrieveQuestionnaireQuery(version, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(version), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof retrieveQuestionnaireQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type RetrieveQuestionnaireQueryQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveQuestionnaireQuery>>>
export type RetrieveQuestionnaireQueryQueryError = ErrorType<void>

export const useRetrieveQuestionnaireQuery = <TData = Awaited<ReturnType<typeof retrieveQuestionnaireQuery>>, TError = ErrorType<void>>(
 version: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof retrieveQuestionnaireQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRetrieveQuestionnaireQueryQueryOptions(version,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getRetrieveQuestionnaireQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof retrieveQuestionnaireQuery>>, TError = ErrorType<void>>(version: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof retrieveQuestionnaireQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRetrieveQuestionnaireQueryQueryKey(version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveQuestionnaireQuery>>> = ({ signal }) => retrieveQuestionnaireQuery(version, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(version), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof retrieveQuestionnaireQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type RetrieveQuestionnaireQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveQuestionnaireQuery>>>
export type RetrieveQuestionnaireQuerySuspenseQueryError = ErrorType<void>

export const useRetrieveQuestionnaireQuerySuspense = <TData = Awaited<ReturnType<typeof retrieveQuestionnaireQuery>>, TError = ErrorType<void>>(
 version: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof retrieveQuestionnaireQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRetrieveQuestionnaireQuerySuspenseQueryOptions(version,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useRetrieveQuestionnaireQuery.queryKey = (version: number,) => getRetrieveQuestionnaireQueryQueryKey(version,);

    useRetrieveQuestionnaireQuery.updateCache = ({version, updater, options}: {version: number, updater: Updater<string | undefined, string | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<string>(getRetrieveQuestionnaireQueryQueryKey(version,), updater, options);
    
    useRetrieveQuestionnaireQuery.getQueryState = (version: number,) => queryClient.getQueryState<string>(getRetrieveQuestionnaireQueryQueryKey(version,));
    
    useRetrieveQuestionnaireQuery.getQueryData = (version: number,) => queryClient.getQueryData<string>(getRetrieveQuestionnaireQueryQueryKey(version,));
  
    useRetrieveQuestionnaireQuery.prefetch = (version: number,) => queryClient.prefetchQuery({ queryKey: getRetrieveQuestionnaireQueryQueryKey(version,) , queryFn: () => retrieveQuestionnaireQuery(version,) });
  
    useRetrieveQuestionnaireQuery.cancelQueries = (version: number,) => queryClient.cancelQueries({ queryKey: getRetrieveQuestionnaireQueryQueryKey(version,) })
  
    useRetrieveQuestionnaireQuery.invalidate = (version: number,) => queryClient.invalidateQueries({ queryKey: getRetrieveQuestionnaireQueryQueryKey(version,) });
  
    useRetrieveQuestionnaireQuery.refetchStale = (version: number,) => queryClient.refetchQueries({ queryKey: getRetrieveQuestionnaireQueryQueryKey(version,), stale: true });
  
            
export const storeQuestionnaireAnswersMutation = (
    storeQuestionnaireAnswersBody: string,
 ) => {
      
      
      return apiInstance<void>(
      {url: `/questionnaire`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: storeQuestionnaireAnswersBody
    },
      );
    }
  


export const getStoreQuestionnaireAnswersMutationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof storeQuestionnaireAnswersMutation>>, TError,{data: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof storeQuestionnaireAnswersMutation>>, TError,{data: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof storeQuestionnaireAnswersMutation>>, {data: string}> = (props) => {
          const {data} = props ?? {};

          return  storeQuestionnaireAnswersMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type StoreQuestionnaireAnswersMutationMutationResult = NonNullable<Awaited<ReturnType<typeof storeQuestionnaireAnswersMutation>>>
    export type StoreQuestionnaireAnswersMutationMutationBody = string
    export type StoreQuestionnaireAnswersMutationMutationError = ErrorType<unknown>

    export const useStoreQuestionnaireAnswersMutation = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof storeQuestionnaireAnswersMutation>>, TError,{data: string}, TContext>, }
) => {

      const mutationOptions = getStoreQuestionnaireAnswersMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    

              export const retrieveQuestionnaireAnswersQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<string>(
      {url: `/questionnaireAnswers`, method: 'GET', signal
    },
      );
    }
  

export const getRetrieveQuestionnaireAnswersQueryQueryKey = () => {
    return [`/questionnaireAnswers`] as const;
    }

    
export const getRetrieveQuestionnaireAnswersQueryQueryOptions = <TData = Awaited<ReturnType<typeof retrieveQuestionnaireAnswersQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof retrieveQuestionnaireAnswersQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRetrieveQuestionnaireAnswersQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveQuestionnaireAnswersQuery>>> = ({ signal }) => retrieveQuestionnaireAnswersQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof retrieveQuestionnaireAnswersQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type RetrieveQuestionnaireAnswersQueryQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveQuestionnaireAnswersQuery>>>
export type RetrieveQuestionnaireAnswersQueryQueryError = ErrorType<void>

export const useRetrieveQuestionnaireAnswersQuery = <TData = Awaited<ReturnType<typeof retrieveQuestionnaireAnswersQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof retrieveQuestionnaireAnswersQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRetrieveQuestionnaireAnswersQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getRetrieveQuestionnaireAnswersQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof retrieveQuestionnaireAnswersQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof retrieveQuestionnaireAnswersQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRetrieveQuestionnaireAnswersQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveQuestionnaireAnswersQuery>>> = ({ signal }) => retrieveQuestionnaireAnswersQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof retrieveQuestionnaireAnswersQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type RetrieveQuestionnaireAnswersQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveQuestionnaireAnswersQuery>>>
export type RetrieveQuestionnaireAnswersQuerySuspenseQueryError = ErrorType<void>

export const useRetrieveQuestionnaireAnswersQuerySuspense = <TData = Awaited<ReturnType<typeof retrieveQuestionnaireAnswersQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof retrieveQuestionnaireAnswersQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRetrieveQuestionnaireAnswersQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useRetrieveQuestionnaireAnswersQuery.queryKey = () => getRetrieveQuestionnaireAnswersQueryQueryKey();

    useRetrieveQuestionnaireAnswersQuery.updateCache = ({updater, options}: {updater: Updater<string | undefined, string | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<string>(getRetrieveQuestionnaireAnswersQueryQueryKey(), updater, options);
    
    useRetrieveQuestionnaireAnswersQuery.getQueryState = () => queryClient.getQueryState<string>(getRetrieveQuestionnaireAnswersQueryQueryKey());
    
    useRetrieveQuestionnaireAnswersQuery.getQueryData = () => queryClient.getQueryData<string>(getRetrieveQuestionnaireAnswersQueryQueryKey());
  
    useRetrieveQuestionnaireAnswersQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getRetrieveQuestionnaireAnswersQueryQueryKey() , queryFn: () => retrieveQuestionnaireAnswersQuery() });
  
    useRetrieveQuestionnaireAnswersQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getRetrieveQuestionnaireAnswersQueryQueryKey() })
  
    useRetrieveQuestionnaireAnswersQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getRetrieveQuestionnaireAnswersQueryQueryKey() });
  
    useRetrieveQuestionnaireAnswersQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getRetrieveQuestionnaireAnswersQueryQueryKey(), stale: true });
  
            

              export const listProductsQuery = (
    params: ListProductsParams,
 signal?: AbortSignal
) => {
      
      
      return apiInstance<RewardProductsResponse>(
      {url: `/earn/products`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListProductsQueryQueryKey = (params: ListProductsParams,) => {
    return [`/earn/products`, ...(params ? [params]: [])] as const;
    }

    
export const getListProductsQueryQueryOptions = <TData = Awaited<ReturnType<typeof listProductsQuery>>, TError = ErrorType<unknown>>(params: ListProductsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProductsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListProductsQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProductsQuery>>> = ({ signal }) => listProductsQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listProductsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type ListProductsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof listProductsQuery>>>
export type ListProductsQueryQueryError = ErrorType<unknown>

export const useListProductsQuery = <TData = Awaited<ReturnType<typeof listProductsQuery>>, TError = ErrorType<unknown>>(
 params: ListProductsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProductsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListProductsQueryQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListProductsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listProductsQuery>>, TError = ErrorType<unknown>>(params: ListProductsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listProductsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListProductsQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProductsQuery>>> = ({ signal }) => listProductsQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listProductsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type ListProductsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listProductsQuery>>>
export type ListProductsQuerySuspenseQueryError = ErrorType<unknown>

export const useListProductsQuerySuspense = <TData = Awaited<ReturnType<typeof listProductsQuery>>, TError = ErrorType<unknown>>(
 params: ListProductsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listProductsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListProductsQuerySuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useListProductsQuery.queryKey = (params: ListProductsParams,) => getListProductsQueryQueryKey(params,);

    useListProductsQuery.updateCache = ({params, updater, options}: {params: ListProductsParams, updater: Updater<RewardProductsResponse | undefined, RewardProductsResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<RewardProductsResponse>(getListProductsQueryQueryKey(params,), updater, options);
    
    useListProductsQuery.getQueryState = (params: ListProductsParams,) => queryClient.getQueryState<RewardProductsResponse>(getListProductsQueryQueryKey(params,));
    
    useListProductsQuery.getQueryData = (params: ListProductsParams,) => queryClient.getQueryData<RewardProductsResponse>(getListProductsQueryQueryKey(params,));
  
    useListProductsQuery.prefetch = (params: ListProductsParams,) => queryClient.prefetchQuery({ queryKey: getListProductsQueryQueryKey(params,) , queryFn: () => listProductsQuery(params,) });
  
    useListProductsQuery.cancelQueries = (params: ListProductsParams,) => queryClient.cancelQueries({ queryKey: getListProductsQueryQueryKey(params,) })
  
    useListProductsQuery.invalidate = (params: ListProductsParams,) => queryClient.invalidateQueries({ queryKey: getListProductsQueryQueryKey(params,) });
  
    useListProductsQuery.refetchStale = (params: ListProductsParams,) => queryClient.refetchQueries({ queryKey: getListProductsQueryQueryKey(params,), stale: true });
  
            

              export const listRewardsQuery = (
    params: ListRewardsParams,
 signal?: AbortSignal
) => {
      
      
      return apiInstance<PaidRewardsResponse>(
      {url: `/earn/rewards`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListRewardsQueryQueryKey = (params: ListRewardsParams,) => {
    return [`/earn/rewards`, ...(params ? [params]: [])] as const;
    }

    
export const getListRewardsQueryQueryOptions = <TData = Awaited<ReturnType<typeof listRewardsQuery>>, TError = ErrorType<unknown>>(params: ListRewardsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listRewardsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListRewardsQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listRewardsQuery>>> = ({ signal }) => listRewardsQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listRewardsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type ListRewardsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof listRewardsQuery>>>
export type ListRewardsQueryQueryError = ErrorType<unknown>

export const useListRewardsQuery = <TData = Awaited<ReturnType<typeof listRewardsQuery>>, TError = ErrorType<unknown>>(
 params: ListRewardsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listRewardsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListRewardsQueryQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListRewardsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listRewardsQuery>>, TError = ErrorType<unknown>>(params: ListRewardsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listRewardsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListRewardsQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listRewardsQuery>>> = ({ signal }) => listRewardsQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listRewardsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type ListRewardsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listRewardsQuery>>>
export type ListRewardsQuerySuspenseQueryError = ErrorType<unknown>

export const useListRewardsQuerySuspense = <TData = Awaited<ReturnType<typeof listRewardsQuery>>, TError = ErrorType<unknown>>(
 params: ListRewardsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listRewardsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListRewardsQuerySuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useListRewardsQuery.queryKey = (params: ListRewardsParams,) => getListRewardsQueryQueryKey(params,);

    useListRewardsQuery.updateCache = ({params, updater, options}: {params: ListRewardsParams, updater: Updater<PaidRewardsResponse | undefined, PaidRewardsResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<PaidRewardsResponse>(getListRewardsQueryQueryKey(params,), updater, options);
    
    useListRewardsQuery.getQueryState = (params: ListRewardsParams,) => queryClient.getQueryState<PaidRewardsResponse>(getListRewardsQueryQueryKey(params,));
    
    useListRewardsQuery.getQueryData = (params: ListRewardsParams,) => queryClient.getQueryData<PaidRewardsResponse>(getListRewardsQueryQueryKey(params,));
  
    useListRewardsQuery.prefetch = (params: ListRewardsParams,) => queryClient.prefetchQuery({ queryKey: getListRewardsQueryQueryKey(params,) , queryFn: () => listRewardsQuery(params,) });
  
    useListRewardsQuery.cancelQueries = (params: ListRewardsParams,) => queryClient.cancelQueries({ queryKey: getListRewardsQueryQueryKey(params,) })
  
    useListRewardsQuery.invalidate = (params: ListRewardsParams,) => queryClient.invalidateQueries({ queryKey: getListRewardsQueryQueryKey(params,) });
  
    useListRewardsQuery.refetchStale = (params: ListRewardsParams,) => queryClient.refetchQueries({ queryKey: getListRewardsQueryQueryKey(params,), stale: true });
  
            
export const enableProductMutation = (
    rewardProductId: RewardProductId,
 ) => {
      
      
      return apiInstance<RewardPosition>(
      {url: `/earn/products/enable`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: rewardProductId
    },
      );
    }
  


export const getEnableProductMutationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof enableProductMutation>>, TError,{data: RewardProductId}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof enableProductMutation>>, TError,{data: RewardProductId}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof enableProductMutation>>, {data: RewardProductId}> = (props) => {
          const {data} = props ?? {};

          return  enableProductMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnableProductMutationMutationResult = NonNullable<Awaited<ReturnType<typeof enableProductMutation>>>
    export type EnableProductMutationMutationBody = RewardProductId
    export type EnableProductMutationMutationError = ErrorType<unknown>

    export const useEnableProductMutation = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof enableProductMutation>>, TError,{data: RewardProductId}, TContext>, }
) => {

      const mutationOptions = getEnableProductMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const enableAllProductsMutation = (
    enableAllProductsBody: EnableAllProductsBody,
 ) => {
      
      
      return apiInstance<RewardPositionsResponse>(
      {url: `/earn/products/enable/all`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: enableAllProductsBody
    },
      );
    }
  


export const getEnableAllProductsMutationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof enableAllProductsMutation>>, TError,{data: EnableAllProductsBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof enableAllProductsMutation>>, TError,{data: EnableAllProductsBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof enableAllProductsMutation>>, {data: EnableAllProductsBody}> = (props) => {
          const {data} = props ?? {};

          return  enableAllProductsMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnableAllProductsMutationMutationResult = NonNullable<Awaited<ReturnType<typeof enableAllProductsMutation>>>
    export type EnableAllProductsMutationMutationBody = EnableAllProductsBody
    export type EnableAllProductsMutationMutationError = ErrorType<unknown>

    export const useEnableAllProductsMutation = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof enableAllProductsMutation>>, TError,{data: EnableAllProductsBody}, TContext>, }
) => {

      const mutationOptions = getEnableAllProductsMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const disableProductMutation = (
    rewardProductId: RewardProductId,
 ) => {
      
      
      return apiInstance<RewardPosition>(
      {url: `/earn/products/disable`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: rewardProductId
    },
      );
    }
  


export const getDisableProductMutationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof disableProductMutation>>, TError,{data: RewardProductId}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof disableProductMutation>>, TError,{data: RewardProductId}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof disableProductMutation>>, {data: RewardProductId}> = (props) => {
          const {data} = props ?? {};

          return  disableProductMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DisableProductMutationMutationResult = NonNullable<Awaited<ReturnType<typeof disableProductMutation>>>
    export type DisableProductMutationMutationBody = RewardProductId
    export type DisableProductMutationMutationError = ErrorType<unknown>

    export const useDisableProductMutation = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof disableProductMutation>>, TError,{data: RewardProductId}, TContext>, }
) => {

      const mutationOptions = getDisableProductMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    

              export const listPositionsQuery = (
    params: ListPositionsParams,
 signal?: AbortSignal
) => {
      
      
      return apiInstance<RewardPositionsResponse>(
      {url: `/earn/positions`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListPositionsQueryQueryKey = (params: ListPositionsParams,) => {
    return [`/earn/positions`, ...(params ? [params]: [])] as const;
    }

    
export const getListPositionsQueryQueryOptions = <TData = Awaited<ReturnType<typeof listPositionsQuery>>, TError = ErrorType<unknown>>(params: ListPositionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listPositionsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListPositionsQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listPositionsQuery>>> = ({ signal }) => listPositionsQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listPositionsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type ListPositionsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof listPositionsQuery>>>
export type ListPositionsQueryQueryError = ErrorType<unknown>

export const useListPositionsQuery = <TData = Awaited<ReturnType<typeof listPositionsQuery>>, TError = ErrorType<unknown>>(
 params: ListPositionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listPositionsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListPositionsQueryQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListPositionsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listPositionsQuery>>, TError = ErrorType<unknown>>(params: ListPositionsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listPositionsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListPositionsQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listPositionsQuery>>> = ({ signal }) => listPositionsQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listPositionsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type ListPositionsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listPositionsQuery>>>
export type ListPositionsQuerySuspenseQueryError = ErrorType<unknown>

export const useListPositionsQuerySuspense = <TData = Awaited<ReturnType<typeof listPositionsQuery>>, TError = ErrorType<unknown>>(
 params: ListPositionsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listPositionsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListPositionsQuerySuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useListPositionsQuery.queryKey = (params: ListPositionsParams,) => getListPositionsQueryQueryKey(params,);

    useListPositionsQuery.updateCache = ({params, updater, options}: {params: ListPositionsParams, updater: Updater<RewardPositionsResponse | undefined, RewardPositionsResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<RewardPositionsResponse>(getListPositionsQueryQueryKey(params,), updater, options);
    
    useListPositionsQuery.getQueryState = (params: ListPositionsParams,) => queryClient.getQueryState<RewardPositionsResponse>(getListPositionsQueryQueryKey(params,));
    
    useListPositionsQuery.getQueryData = (params: ListPositionsParams,) => queryClient.getQueryData<RewardPositionsResponse>(getListPositionsQueryQueryKey(params,));
  
    useListPositionsQuery.prefetch = (params: ListPositionsParams,) => queryClient.prefetchQuery({ queryKey: getListPositionsQueryQueryKey(params,) , queryFn: () => listPositionsQuery(params,) });
  
    useListPositionsQuery.cancelQueries = (params: ListPositionsParams,) => queryClient.cancelQueries({ queryKey: getListPositionsQueryQueryKey(params,) })
  
    useListPositionsQuery.invalidate = (params: ListPositionsParams,) => queryClient.invalidateQueries({ queryKey: getListPositionsQueryQueryKey(params,) });
  
    useListPositionsQuery.refetchStale = (params: ListPositionsParams,) => queryClient.refetchQueries({ queryKey: getListPositionsQueryQueryKey(params,), stale: true });
  
            

              export const ordersQuery = (
    params?: OrdersParams,
 signal?: AbortSignal
) => {
      
      
      return apiInstance<OrdersResponseObject>(
      {url: `/orders/all`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getOrdersQueryQueryKey = (params?: OrdersParams,) => {
    return [`/orders/all`, ...(params ? [params]: [])] as const;
    }

    
export const getOrdersQueryInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof ordersQuery>>, OrdersParams['offset']>, TError = ErrorType<unknown>>(params?: OrdersParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersQuery>>, TError, TData, Awaited<ReturnType<typeof ordersQuery>>, QueryKey, OrdersParams['offset']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrdersQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersQuery>>, QueryKey, OrdersParams['offset']> = ({ signal, pageParam }) => ordersQuery({...params, offset: pageParam || params?.['offset']}, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersQuery>>, TError, TData, Awaited<ReturnType<typeof ordersQuery>>, QueryKey, OrdersParams['offset']> & { queryKey: QueryKey }
}

export type OrdersQueryInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof ordersQuery>>>
export type OrdersQueryInfiniteQueryError = ErrorType<unknown>

export const useOrdersQueryInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof ordersQuery>>, OrdersParams['offset']>, TError = ErrorType<unknown>>(
 params?: OrdersParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersQuery>>, TError, TData, Awaited<ReturnType<typeof ordersQuery>>, QueryKey, OrdersParams['offset']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrdersQueryInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getOrdersQueryQueryOptions = <TData = Awaited<ReturnType<typeof ordersQuery>>, TError = ErrorType<unknown>>(params?: OrdersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ordersQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrdersQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersQuery>>> = ({ signal }) => ordersQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ordersQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type OrdersQueryQueryResult = NonNullable<Awaited<ReturnType<typeof ordersQuery>>>
export type OrdersQueryQueryError = ErrorType<unknown>

export const useOrdersQuery = <TData = Awaited<ReturnType<typeof ordersQuery>>, TError = ErrorType<unknown>>(
 params?: OrdersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ordersQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrdersQueryQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getOrdersQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof ordersQuery>>, TError = ErrorType<unknown>>(params?: OrdersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof ordersQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrdersQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersQuery>>> = ({ signal }) => ordersQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof ordersQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type OrdersQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof ordersQuery>>>
export type OrdersQuerySuspenseQueryError = ErrorType<unknown>

export const useOrdersQuerySuspense = <TData = Awaited<ReturnType<typeof ordersQuery>>, TError = ErrorType<unknown>>(
 params?: OrdersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof ordersQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrdersQuerySuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getOrdersQuerySuspenseInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof ordersQuery>>, OrdersParams['offset']>, TError = ErrorType<unknown>>(params?: OrdersParams, options?: { query?:Partial<UseSuspenseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersQuery>>, TError, TData, Awaited<ReturnType<typeof ordersQuery>>, QueryKey, OrdersParams['offset']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrdersQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersQuery>>, QueryKey, OrdersParams['offset']> = ({ signal, pageParam }) => ordersQuery({...params, offset: pageParam || params?.['offset']}, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersQuery>>, TError, TData, Awaited<ReturnType<typeof ordersQuery>>, QueryKey, OrdersParams['offset']> & { queryKey: QueryKey }
}

export type OrdersQuerySuspenseInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof ordersQuery>>>
export type OrdersQuerySuspenseInfiniteQueryError = ErrorType<unknown>

export const useOrdersQuerySuspenseInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof ordersQuery>>, OrdersParams['offset']>, TError = ErrorType<unknown>>(
 params?: OrdersParams, options?: { query?:Partial<UseSuspenseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersQuery>>, TError, TData, Awaited<ReturnType<typeof ordersQuery>>, QueryKey, OrdersParams['offset']>>, }

  ):  UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrdersQuerySuspenseInfiniteQueryOptions(params,options)

  const query = useSuspenseInfiniteQuery(queryOptions) as  UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useOrdersQuery.queryKey = (params?: OrdersParams,) => getOrdersQueryQueryKey(params,);

    useOrdersQuery.updateCache = ({params, updater, options}: {params?: OrdersParams, updater: Updater<OrdersResponseObject | undefined, OrdersResponseObject | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<OrdersResponseObject>(getOrdersQueryQueryKey(params,), updater, options);
    
    useOrdersQuery.getQueryState = (params?: OrdersParams,) => queryClient.getQueryState<OrdersResponseObject>(getOrdersQueryQueryKey(params,));
    
    useOrdersQuery.getQueryData = (params?: OrdersParams,) => queryClient.getQueryData<OrdersResponseObject>(getOrdersQueryQueryKey(params,));
  
    useOrdersQuery.prefetch = (params?: OrdersParams,) => queryClient.prefetchQuery({ queryKey: getOrdersQueryQueryKey(params,) , queryFn: () => ordersQuery(params,) });
  
    useOrdersQuery.cancelQueries = (params?: OrdersParams,) => queryClient.cancelQueries({ queryKey: getOrdersQueryQueryKey(params,) })
  
    useOrdersQuery.invalidate = (params?: OrdersParams,) => queryClient.invalidateQueries({ queryKey: getOrdersQueryQueryKey(params,) });
  
    useOrdersQuery.refetchStale = (params?: OrdersParams,) => queryClient.refetchQueries({ queryKey: getOrdersQueryQueryKey(params,), stale: true });
  
    useOrdersQueryInfinite.queryKey = (params?: OrdersParams,) => getOrdersQueryQueryKey(params,);

    useOrdersQueryInfinite.updateCache = ({params, updater, options}: {params?: OrdersParams, updater: Updater<InfiniteData<OrdersResponseObject> | undefined, InfiniteData<OrdersResponseObject> | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<InfiniteData<OrdersResponseObject>>(getOrdersQueryQueryKey(params,), updater, options);
    
    useOrdersQueryInfinite.getQueryState = (params?: OrdersParams,) => queryClient.getQueryState<InfiniteData<OrdersResponseObject>>(getOrdersQueryQueryKey(params,));
    
    useOrdersQueryInfinite.getQueryData = (params?: OrdersParams,) => queryClient.getQueryData<InfiniteData<OrdersResponseObject>>(getOrdersQueryQueryKey(params,));
  
            

              export const depositsAndWithdrawalsQuery = (
    params?: DepositsAndWithdrawalsParams,
 signal?: AbortSignal
) => {
      
      
      return apiInstance<EurTransactionsResponseObject>(
      {url: `/eurTransactions/depositsAndWithdrawals`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getDepositsAndWithdrawalsQueryQueryKey = (params?: DepositsAndWithdrawalsParams,) => {
    return [`/eurTransactions/depositsAndWithdrawals`, ...(params ? [params]: [])] as const;
    }

    
export const getDepositsAndWithdrawalsQueryInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, DepositsAndWithdrawalsParams['offset']>, TError = ErrorType<unknown>>(params?: DepositsAndWithdrawalsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError, TData, Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, QueryKey, DepositsAndWithdrawalsParams['offset']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDepositsAndWithdrawalsQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, QueryKey, DepositsAndWithdrawalsParams['offset']> = ({ signal, pageParam }) => depositsAndWithdrawalsQuery({...params, offset: pageParam || params?.['offset']}, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError, TData, Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, QueryKey, DepositsAndWithdrawalsParams['offset']> & { queryKey: QueryKey }
}

export type DepositsAndWithdrawalsQueryInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>>
export type DepositsAndWithdrawalsQueryInfiniteQueryError = ErrorType<unknown>

export const useDepositsAndWithdrawalsQueryInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, DepositsAndWithdrawalsParams['offset']>, TError = ErrorType<unknown>>(
 params?: DepositsAndWithdrawalsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError, TData, Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, QueryKey, DepositsAndWithdrawalsParams['offset']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDepositsAndWithdrawalsQueryInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDepositsAndWithdrawalsQueryQueryOptions = <TData = Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError = ErrorType<unknown>>(params?: DepositsAndWithdrawalsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDepositsAndWithdrawalsQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>> = ({ signal }) => depositsAndWithdrawalsQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type DepositsAndWithdrawalsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>>
export type DepositsAndWithdrawalsQueryQueryError = ErrorType<unknown>

export const useDepositsAndWithdrawalsQuery = <TData = Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError = ErrorType<unknown>>(
 params?: DepositsAndWithdrawalsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDepositsAndWithdrawalsQueryQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDepositsAndWithdrawalsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError = ErrorType<unknown>>(params?: DepositsAndWithdrawalsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDepositsAndWithdrawalsQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>> = ({ signal }) => depositsAndWithdrawalsQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type DepositsAndWithdrawalsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>>
export type DepositsAndWithdrawalsQuerySuspenseQueryError = ErrorType<unknown>

export const useDepositsAndWithdrawalsQuerySuspense = <TData = Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError = ErrorType<unknown>>(
 params?: DepositsAndWithdrawalsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDepositsAndWithdrawalsQuerySuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDepositsAndWithdrawalsQuerySuspenseInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, DepositsAndWithdrawalsParams['offset']>, TError = ErrorType<unknown>>(params?: DepositsAndWithdrawalsParams, options?: { query?:Partial<UseSuspenseInfiniteQueryOptions<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError, TData, Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, QueryKey, DepositsAndWithdrawalsParams['offset']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDepositsAndWithdrawalsQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, QueryKey, DepositsAndWithdrawalsParams['offset']> = ({ signal, pageParam }) => depositsAndWithdrawalsQuery({...params, offset: pageParam || params?.['offset']}, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseInfiniteQueryOptions<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError, TData, Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, QueryKey, DepositsAndWithdrawalsParams['offset']> & { queryKey: QueryKey }
}

export type DepositsAndWithdrawalsQuerySuspenseInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>>
export type DepositsAndWithdrawalsQuerySuspenseInfiniteQueryError = ErrorType<unknown>

export const useDepositsAndWithdrawalsQuerySuspenseInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, DepositsAndWithdrawalsParams['offset']>, TError = ErrorType<unknown>>(
 params?: DepositsAndWithdrawalsParams, options?: { query?:Partial<UseSuspenseInfiniteQueryOptions<Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, TError, TData, Awaited<ReturnType<typeof depositsAndWithdrawalsQuery>>, QueryKey, DepositsAndWithdrawalsParams['offset']>>, }

  ):  UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDepositsAndWithdrawalsQuerySuspenseInfiniteQueryOptions(params,options)

  const query = useSuspenseInfiniteQuery(queryOptions) as  UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useDepositsAndWithdrawalsQuery.queryKey = (params?: DepositsAndWithdrawalsParams,) => getDepositsAndWithdrawalsQueryQueryKey(params,);

    useDepositsAndWithdrawalsQuery.updateCache = ({params, updater, options}: {params?: DepositsAndWithdrawalsParams, updater: Updater<EurTransactionsResponseObject | undefined, EurTransactionsResponseObject | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<EurTransactionsResponseObject>(getDepositsAndWithdrawalsQueryQueryKey(params,), updater, options);
    
    useDepositsAndWithdrawalsQuery.getQueryState = (params?: DepositsAndWithdrawalsParams,) => queryClient.getQueryState<EurTransactionsResponseObject>(getDepositsAndWithdrawalsQueryQueryKey(params,));
    
    useDepositsAndWithdrawalsQuery.getQueryData = (params?: DepositsAndWithdrawalsParams,) => queryClient.getQueryData<EurTransactionsResponseObject>(getDepositsAndWithdrawalsQueryQueryKey(params,));
  
    useDepositsAndWithdrawalsQuery.prefetch = (params?: DepositsAndWithdrawalsParams,) => queryClient.prefetchQuery({ queryKey: getDepositsAndWithdrawalsQueryQueryKey(params,) , queryFn: () => depositsAndWithdrawalsQuery(params,) });
  
    useDepositsAndWithdrawalsQuery.cancelQueries = (params?: DepositsAndWithdrawalsParams,) => queryClient.cancelQueries({ queryKey: getDepositsAndWithdrawalsQueryQueryKey(params,) })
  
    useDepositsAndWithdrawalsQuery.invalidate = (params?: DepositsAndWithdrawalsParams,) => queryClient.invalidateQueries({ queryKey: getDepositsAndWithdrawalsQueryQueryKey(params,) });
  
    useDepositsAndWithdrawalsQuery.refetchStale = (params?: DepositsAndWithdrawalsParams,) => queryClient.refetchQueries({ queryKey: getDepositsAndWithdrawalsQueryQueryKey(params,), stale: true });
  
    useDepositsAndWithdrawalsQueryInfinite.queryKey = (params?: DepositsAndWithdrawalsParams,) => getDepositsAndWithdrawalsQueryQueryKey(params,);

    useDepositsAndWithdrawalsQueryInfinite.updateCache = ({params, updater, options}: {params?: DepositsAndWithdrawalsParams, updater: Updater<InfiniteData<EurTransactionsResponseObject> | undefined, InfiniteData<EurTransactionsResponseObject> | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<InfiniteData<EurTransactionsResponseObject>>(getDepositsAndWithdrawalsQueryQueryKey(params,), updater, options);
    
    useDepositsAndWithdrawalsQueryInfinite.getQueryState = (params?: DepositsAndWithdrawalsParams,) => queryClient.getQueryState<InfiniteData<EurTransactionsResponseObject>>(getDepositsAndWithdrawalsQueryQueryKey(params,));
    
    useDepositsAndWithdrawalsQueryInfinite.getQueryData = (params?: DepositsAndWithdrawalsParams,) => queryClient.getQueryData<InfiniteData<EurTransactionsResponseObject>>(getDepositsAndWithdrawalsQueryQueryKey(params,));
  
            

              export const getContactInformationQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<ContactInformationResponse>(
      {url: `/contactInformation`, method: 'GET', signal
    },
      );
    }
  

export const getGetContactInformationQueryQueryKey = () => {
    return [`/contactInformation`] as const;
    }

    
export const getGetContactInformationQueryQueryOptions = <TData = Awaited<ReturnType<typeof getContactInformationQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContactInformationQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetContactInformationQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContactInformationQuery>>> = ({ signal }) => getContactInformationQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getContactInformationQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetContactInformationQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getContactInformationQuery>>>
export type GetContactInformationQueryQueryError = ErrorType<void>

export const useGetContactInformationQuery = <TData = Awaited<ReturnType<typeof getContactInformationQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContactInformationQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetContactInformationQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetContactInformationQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getContactInformationQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getContactInformationQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetContactInformationQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContactInformationQuery>>> = ({ signal }) => getContactInformationQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getContactInformationQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetContactInformationQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getContactInformationQuery>>>
export type GetContactInformationQuerySuspenseQueryError = ErrorType<void>

export const useGetContactInformationQuerySuspense = <TData = Awaited<ReturnType<typeof getContactInformationQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getContactInformationQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetContactInformationQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetContactInformationQuery.queryKey = () => getGetContactInformationQueryQueryKey();

    useGetContactInformationQuery.updateCache = ({updater, options}: {updater: Updater<ContactInformationResponse | undefined, ContactInformationResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<ContactInformationResponse>(getGetContactInformationQueryQueryKey(), updater, options);
    
    useGetContactInformationQuery.getQueryState = () => queryClient.getQueryState<ContactInformationResponse>(getGetContactInformationQueryQueryKey());
    
    useGetContactInformationQuery.getQueryData = () => queryClient.getQueryData<ContactInformationResponse>(getGetContactInformationQueryQueryKey());
  
    useGetContactInformationQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetContactInformationQueryQueryKey() , queryFn: () => getContactInformationQuery() });
  
    useGetContactInformationQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetContactInformationQueryQueryKey() })
  
    useGetContactInformationQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetContactInformationQueryQueryKey() });
  
    useGetContactInformationQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetContactInformationQueryQueryKey(), stale: true });
  
            
export const createContactInformationMutation = (
    contactInformationRequest: ContactInformationRequest,
 ) => {
      
      
      return apiInstance<void>(
      {url: `/contactInformation`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: contactInformationRequest
    },
      );
    }
  


export const getCreateContactInformationMutationMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContactInformationMutation>>, TError,{data: ContactInformationRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createContactInformationMutation>>, TError,{data: ContactInformationRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createContactInformationMutation>>, {data: ContactInformationRequest}> = (props) => {
          const {data} = props ?? {};

          return  createContactInformationMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateContactInformationMutationMutationResult = NonNullable<Awaited<ReturnType<typeof createContactInformationMutation>>>
    export type CreateContactInformationMutationMutationBody = ContactInformationRequest
    export type CreateContactInformationMutationMutationError = ErrorType<void>

    export const useCreateContactInformationMutation = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContactInformationMutation>>, TError,{data: ContactInformationRequest}, TContext>, }
) => {

      const mutationOptions = getCreateContactInformationMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Saves the Terms & Conditions accepted date and removes the corresponding keycloak role
 */
export const acceptTermsMutation = (
    
 ) => {
      
      
      return apiInstance<void>(
      {url: `/account/terms`, method: 'PUT'
    },
      );
    }
  


export const getAcceptTermsMutationMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof acceptTermsMutation>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof acceptTermsMutation>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof acceptTermsMutation>>, void> = () => {
          

          return  acceptTermsMutation()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AcceptTermsMutationMutationResult = NonNullable<Awaited<ReturnType<typeof acceptTermsMutation>>>
    
    export type AcceptTermsMutationMutationError = ErrorType<void>

    export const useAcceptTermsMutation = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof acceptTermsMutation>>, TError,void, TContext>, }
) => {

      const mutationOptions = getAcceptTermsMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * get actual withdrawalFee information
 */

              export const withdrawalFeeQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<WithdrawalFeeResponse>(
      {url: `/withdrawalfee`, method: 'GET', signal
    },
      );
    }
  

export const getWithdrawalFeeQueryQueryKey = () => {
    return [`/withdrawalfee`] as const;
    }

    
export const getWithdrawalFeeQueryQueryOptions = <TData = Awaited<ReturnType<typeof withdrawalFeeQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof withdrawalFeeQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWithdrawalFeeQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof withdrawalFeeQuery>>> = ({ signal }) => withdrawalFeeQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof withdrawalFeeQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type WithdrawalFeeQueryQueryResult = NonNullable<Awaited<ReturnType<typeof withdrawalFeeQuery>>>
export type WithdrawalFeeQueryQueryError = ErrorType<void>

export const useWithdrawalFeeQuery = <TData = Awaited<ReturnType<typeof withdrawalFeeQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof withdrawalFeeQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWithdrawalFeeQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWithdrawalFeeQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof withdrawalFeeQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof withdrawalFeeQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWithdrawalFeeQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof withdrawalFeeQuery>>> = ({ signal }) => withdrawalFeeQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof withdrawalFeeQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type WithdrawalFeeQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof withdrawalFeeQuery>>>
export type WithdrawalFeeQuerySuspenseQueryError = ErrorType<void>

export const useWithdrawalFeeQuerySuspense = <TData = Awaited<ReturnType<typeof withdrawalFeeQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof withdrawalFeeQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWithdrawalFeeQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useWithdrawalFeeQuery.queryKey = () => getWithdrawalFeeQueryQueryKey();

    useWithdrawalFeeQuery.updateCache = ({updater, options}: {updater: Updater<WithdrawalFeeResponse | undefined, WithdrawalFeeResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<WithdrawalFeeResponse>(getWithdrawalFeeQueryQueryKey(), updater, options);
    
    useWithdrawalFeeQuery.getQueryState = () => queryClient.getQueryState<WithdrawalFeeResponse>(getWithdrawalFeeQueryQueryKey());
    
    useWithdrawalFeeQuery.getQueryData = () => queryClient.getQueryData<WithdrawalFeeResponse>(getWithdrawalFeeQueryQueryKey());
  
    useWithdrawalFeeQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getWithdrawalFeeQueryQueryKey() , queryFn: () => withdrawalFeeQuery() });
  
    useWithdrawalFeeQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getWithdrawalFeeQueryQueryKey() })
  
    useWithdrawalFeeQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getWithdrawalFeeQueryQueryKey() });
  
    useWithdrawalFeeQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getWithdrawalFeeQueryQueryKey(), stale: true });
  
            
/**
 * Withdraw fiat from current account.
 */
export const startWithdrawalMutation = (
    withdrawalRequest: WithdrawalRequest,
 ) => {
      
      
      return apiInstance<WithdrawalResponse>(
      {url: `/withdrawals`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: withdrawalRequest
    },
      );
    }
  


export const getStartWithdrawalMutationMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startWithdrawalMutation>>, TError,{data: WithdrawalRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof startWithdrawalMutation>>, TError,{data: WithdrawalRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof startWithdrawalMutation>>, {data: WithdrawalRequest}> = (props) => {
          const {data} = props ?? {};

          return  startWithdrawalMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type StartWithdrawalMutationMutationResult = NonNullable<Awaited<ReturnType<typeof startWithdrawalMutation>>>
    export type StartWithdrawalMutationMutationBody = WithdrawalRequest
    export type StartWithdrawalMutationMutationError = ErrorType<void>

    export const useStartWithdrawalMutation = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startWithdrawalMutation>>, TError,{data: WithdrawalRequest}, TContext>, }
) => {

      const mutationOptions = getStartWithdrawalMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * List the user withdrawals
 */

              export const getWithdrawalsQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<WithdrawalResponse[]>(
      {url: `/withdrawals`, method: 'GET', signal
    },
      );
    }
  

export const getGetWithdrawalsQueryQueryKey = () => {
    return [`/withdrawals`] as const;
    }

    
export const getGetWithdrawalsQueryQueryOptions = <TData = Awaited<ReturnType<typeof getWithdrawalsQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWithdrawalsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWithdrawalsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWithdrawalsQuery>>> = ({ signal }) => getWithdrawalsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWithdrawalsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWithdrawalsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getWithdrawalsQuery>>>
export type GetWithdrawalsQueryQueryError = ErrorType<void>

export const useGetWithdrawalsQuery = <TData = Awaited<ReturnType<typeof getWithdrawalsQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWithdrawalsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWithdrawalsQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetWithdrawalsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getWithdrawalsQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getWithdrawalsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWithdrawalsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWithdrawalsQuery>>> = ({ signal }) => getWithdrawalsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getWithdrawalsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWithdrawalsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getWithdrawalsQuery>>>
export type GetWithdrawalsQuerySuspenseQueryError = ErrorType<void>

export const useGetWithdrawalsQuerySuspense = <TData = Awaited<ReturnType<typeof getWithdrawalsQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getWithdrawalsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWithdrawalsQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetWithdrawalsQuery.queryKey = () => getGetWithdrawalsQueryQueryKey();

    useGetWithdrawalsQuery.updateCache = ({updater, options}: {updater: Updater<WithdrawalResponse[] | undefined, WithdrawalResponse[] | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<WithdrawalResponse[]>(getGetWithdrawalsQueryQueryKey(), updater, options);
    
    useGetWithdrawalsQuery.getQueryState = () => queryClient.getQueryState<WithdrawalResponse[]>(getGetWithdrawalsQueryQueryKey());
    
    useGetWithdrawalsQuery.getQueryData = () => queryClient.getQueryData<WithdrawalResponse[]>(getGetWithdrawalsQueryQueryKey());
  
    useGetWithdrawalsQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetWithdrawalsQueryQueryKey() , queryFn: () => getWithdrawalsQuery() });
  
    useGetWithdrawalsQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetWithdrawalsQueryQueryKey() })
  
    useGetWithdrawalsQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetWithdrawalsQueryQueryKey() });
  
    useGetWithdrawalsQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetWithdrawalsQueryQueryKey(), stale: true });
  
            
/**
 * Return deposit address for the given asset
 */

              export const getDepositAssetAddressQuery = (
    asset: string,
 signal?: AbortSignal
) => {
      
      
      return apiInstance<DepositAssetAddressResponse>(
      {url: `/deposit/${asset}/address`, method: 'GET', signal
    },
      );
    }
  

export const getGetDepositAssetAddressQueryQueryKey = (asset: string,) => {
    return [`/deposit/${asset}/address`] as const;
    }

    
export const getGetDepositAssetAddressQueryQueryOptions = <TData = Awaited<ReturnType<typeof getDepositAssetAddressQuery>>, TError = ErrorType<void>>(asset: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDepositAssetAddressQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDepositAssetAddressQueryQueryKey(asset);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDepositAssetAddressQuery>>> = ({ signal }) => getDepositAssetAddressQuery(asset, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(asset), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDepositAssetAddressQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDepositAssetAddressQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getDepositAssetAddressQuery>>>
export type GetDepositAssetAddressQueryQueryError = ErrorType<void>

export const useGetDepositAssetAddressQuery = <TData = Awaited<ReturnType<typeof getDepositAssetAddressQuery>>, TError = ErrorType<void>>(
 asset: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDepositAssetAddressQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDepositAssetAddressQueryQueryOptions(asset,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDepositAssetAddressQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getDepositAssetAddressQuery>>, TError = ErrorType<void>>(asset: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDepositAssetAddressQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDepositAssetAddressQueryQueryKey(asset);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDepositAssetAddressQuery>>> = ({ signal }) => getDepositAssetAddressQuery(asset, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(asset), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDepositAssetAddressQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDepositAssetAddressQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getDepositAssetAddressQuery>>>
export type GetDepositAssetAddressQuerySuspenseQueryError = ErrorType<void>

export const useGetDepositAssetAddressQuerySuspense = <TData = Awaited<ReturnType<typeof getDepositAssetAddressQuery>>, TError = ErrorType<void>>(
 asset: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDepositAssetAddressQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDepositAssetAddressQuerySuspenseQueryOptions(asset,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetDepositAssetAddressQuery.queryKey = (asset: string,) => getGetDepositAssetAddressQueryQueryKey(asset,);

    useGetDepositAssetAddressQuery.updateCache = ({asset, updater, options}: {asset: string, updater: Updater<DepositAssetAddressResponse | undefined, DepositAssetAddressResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<DepositAssetAddressResponse>(getGetDepositAssetAddressQueryQueryKey(asset,), updater, options);
    
    useGetDepositAssetAddressQuery.getQueryState = (asset: string,) => queryClient.getQueryState<DepositAssetAddressResponse>(getGetDepositAssetAddressQueryQueryKey(asset,));
    
    useGetDepositAssetAddressQuery.getQueryData = (asset: string,) => queryClient.getQueryData<DepositAssetAddressResponse>(getGetDepositAssetAddressQueryQueryKey(asset,));
  
    useGetDepositAssetAddressQuery.prefetch = (asset: string,) => queryClient.prefetchQuery({ queryKey: getGetDepositAssetAddressQueryQueryKey(asset,) , queryFn: () => getDepositAssetAddressQuery(asset,) });
  
    useGetDepositAssetAddressQuery.cancelQueries = (asset: string,) => queryClient.cancelQueries({ queryKey: getGetDepositAssetAddressQueryQueryKey(asset,) })
  
    useGetDepositAssetAddressQuery.invalidate = (asset: string,) => queryClient.invalidateQueries({ queryKey: getGetDepositAssetAddressQueryQueryKey(asset,) });
  
    useGetDepositAssetAddressQuery.refetchStale = (asset: string,) => queryClient.refetchQueries({ queryKey: getGetDepositAssetAddressQueryQueryKey(asset,), stale: true });
  
            
/**
 * Return list of assets that are available for requesting an address and making deposits
 */

              export const listSupportedDepositAssetsQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<SupportedDepositAsset[]>(
      {url: `/deposit/supportedAssets`, method: 'GET', signal
    },
      );
    }
  

export const getListSupportedDepositAssetsQueryQueryKey = () => {
    return [`/deposit/supportedAssets`] as const;
    }

    
export const getListSupportedDepositAssetsQueryQueryOptions = <TData = Awaited<ReturnType<typeof listSupportedDepositAssetsQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listSupportedDepositAssetsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListSupportedDepositAssetsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listSupportedDepositAssetsQuery>>> = ({ signal }) => listSupportedDepositAssetsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listSupportedDepositAssetsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type ListSupportedDepositAssetsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof listSupportedDepositAssetsQuery>>>
export type ListSupportedDepositAssetsQueryQueryError = ErrorType<unknown>

export const useListSupportedDepositAssetsQuery = <TData = Awaited<ReturnType<typeof listSupportedDepositAssetsQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listSupportedDepositAssetsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListSupportedDepositAssetsQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListSupportedDepositAssetsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listSupportedDepositAssetsQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listSupportedDepositAssetsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListSupportedDepositAssetsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listSupportedDepositAssetsQuery>>> = ({ signal }) => listSupportedDepositAssetsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listSupportedDepositAssetsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type ListSupportedDepositAssetsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listSupportedDepositAssetsQuery>>>
export type ListSupportedDepositAssetsQuerySuspenseQueryError = ErrorType<unknown>

export const useListSupportedDepositAssetsQuerySuspense = <TData = Awaited<ReturnType<typeof listSupportedDepositAssetsQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listSupportedDepositAssetsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListSupportedDepositAssetsQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useListSupportedDepositAssetsQuery.queryKey = () => getListSupportedDepositAssetsQueryQueryKey();

    useListSupportedDepositAssetsQuery.updateCache = ({updater, options}: {updater: Updater<SupportedDepositAsset[] | undefined, SupportedDepositAsset[] | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<SupportedDepositAsset[]>(getListSupportedDepositAssetsQueryQueryKey(), updater, options);
    
    useListSupportedDepositAssetsQuery.getQueryState = () => queryClient.getQueryState<SupportedDepositAsset[]>(getListSupportedDepositAssetsQueryQueryKey());
    
    useListSupportedDepositAssetsQuery.getQueryData = () => queryClient.getQueryData<SupportedDepositAsset[]>(getListSupportedDepositAssetsQueryQueryKey());
  
    useListSupportedDepositAssetsQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getListSupportedDepositAssetsQueryQueryKey() , queryFn: () => listSupportedDepositAssetsQuery() });
  
    useListSupportedDepositAssetsQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getListSupportedDepositAssetsQueryQueryKey() })
  
    useListSupportedDepositAssetsQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getListSupportedDepositAssetsQueryQueryKey() });
  
    useListSupportedDepositAssetsQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getListSupportedDepositAssetsQueryQueryKey(), stale: true });
  
            

              export const voucherUserStatusQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<UserAllowedToRedeemVoucher>(
      {url: `/vouchers/user/allowed-to-redeem`, method: 'GET', signal
    },
      );
    }
  

export const getVoucherUserStatusQueryQueryKey = () => {
    return [`/vouchers/user/allowed-to-redeem`] as const;
    }

    
export const getVoucherUserStatusQueryQueryOptions = <TData = Awaited<ReturnType<typeof voucherUserStatusQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof voucherUserStatusQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVoucherUserStatusQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof voucherUserStatusQuery>>> = ({ signal }) => voucherUserStatusQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof voucherUserStatusQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type VoucherUserStatusQueryQueryResult = NonNullable<Awaited<ReturnType<typeof voucherUserStatusQuery>>>
export type VoucherUserStatusQueryQueryError = ErrorType<void>

export const useVoucherUserStatusQuery = <TData = Awaited<ReturnType<typeof voucherUserStatusQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof voucherUserStatusQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getVoucherUserStatusQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getVoucherUserStatusQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof voucherUserStatusQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof voucherUserStatusQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVoucherUserStatusQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof voucherUserStatusQuery>>> = ({ signal }) => voucherUserStatusQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof voucherUserStatusQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type VoucherUserStatusQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof voucherUserStatusQuery>>>
export type VoucherUserStatusQuerySuspenseQueryError = ErrorType<void>

export const useVoucherUserStatusQuerySuspense = <TData = Awaited<ReturnType<typeof voucherUserStatusQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof voucherUserStatusQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getVoucherUserStatusQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useVoucherUserStatusQuery.queryKey = () => getVoucherUserStatusQueryQueryKey();

    useVoucherUserStatusQuery.updateCache = ({updater, options}: {updater: Updater<UserAllowedToRedeemVoucher | undefined, UserAllowedToRedeemVoucher | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<UserAllowedToRedeemVoucher>(getVoucherUserStatusQueryQueryKey(), updater, options);
    
    useVoucherUserStatusQuery.getQueryState = () => queryClient.getQueryState<UserAllowedToRedeemVoucher>(getVoucherUserStatusQueryQueryKey());
    
    useVoucherUserStatusQuery.getQueryData = () => queryClient.getQueryData<UserAllowedToRedeemVoucher>(getVoucherUserStatusQueryQueryKey());
  
    useVoucherUserStatusQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getVoucherUserStatusQueryQueryKey() , queryFn: () => voucherUserStatusQuery() });
  
    useVoucherUserStatusQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getVoucherUserStatusQueryQueryKey() })
  
    useVoucherUserStatusQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getVoucherUserStatusQueryQueryKey() });
  
    useVoucherUserStatusQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getVoucherUserStatusQueryQueryKey(), stale: true });
  
            

              export const getVerificationStatusQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<VerificationStatusResponse>(
      {url: `/user/verification/status`, method: 'GET', signal
    },
      );
    }
  

export const getGetVerificationStatusQueryQueryKey = () => {
    return [`/user/verification/status`] as const;
    }

    
export const getGetVerificationStatusQueryQueryOptions = <TData = Awaited<ReturnType<typeof getVerificationStatusQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVerificationStatusQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVerificationStatusQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVerificationStatusQuery>>> = ({ signal }) => getVerificationStatusQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVerificationStatusQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVerificationStatusQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getVerificationStatusQuery>>>
export type GetVerificationStatusQueryQueryError = ErrorType<void>

export const useGetVerificationStatusQuery = <TData = Awaited<ReturnType<typeof getVerificationStatusQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVerificationStatusQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVerificationStatusQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetVerificationStatusQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getVerificationStatusQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVerificationStatusQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVerificationStatusQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVerificationStatusQuery>>> = ({ signal }) => getVerificationStatusQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVerificationStatusQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVerificationStatusQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getVerificationStatusQuery>>>
export type GetVerificationStatusQuerySuspenseQueryError = ErrorType<void>

export const useGetVerificationStatusQuerySuspense = <TData = Awaited<ReturnType<typeof getVerificationStatusQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVerificationStatusQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVerificationStatusQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetVerificationStatusQuery.queryKey = () => getGetVerificationStatusQueryQueryKey();

    useGetVerificationStatusQuery.updateCache = ({updater, options}: {updater: Updater<VerificationStatusResponse | undefined, VerificationStatusResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<VerificationStatusResponse>(getGetVerificationStatusQueryQueryKey(), updater, options);
    
    useGetVerificationStatusQuery.getQueryState = () => queryClient.getQueryState<VerificationStatusResponse>(getGetVerificationStatusQueryQueryKey());
    
    useGetVerificationStatusQuery.getQueryData = () => queryClient.getQueryData<VerificationStatusResponse>(getGetVerificationStatusQueryQueryKey());
  
    useGetVerificationStatusQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetVerificationStatusQueryQueryKey() , queryFn: () => getVerificationStatusQuery() });
  
    useGetVerificationStatusQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetVerificationStatusQueryQueryKey() })
  
    useGetVerificationStatusQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetVerificationStatusQueryQueryKey() });
  
    useGetVerificationStatusQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetVerificationStatusQueryQueryKey(), stale: true });
  
            
/**
 * Returns the DCA code as a string
 * @summary Get DCA Code
 */

              export const getDcaCodeQuery = (
    params: GetDcaCodeParams,
 signal?: AbortSignal
) => {
      
      
      return apiInstance<DcaCodeResponse>(
      {url: `/dca/code`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetDcaCodeQueryQueryKey = (params: GetDcaCodeParams,) => {
    return [`/dca/code`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDcaCodeQueryQueryOptions = <TData = Awaited<ReturnType<typeof getDcaCodeQuery>>, TError = ErrorType<void>>(params: GetDcaCodeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDcaCodeQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDcaCodeQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDcaCodeQuery>>> = ({ signal }) => getDcaCodeQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDcaCodeQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDcaCodeQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getDcaCodeQuery>>>
export type GetDcaCodeQueryQueryError = ErrorType<void>

/**
 * @summary Get DCA Code
 */
export const useGetDcaCodeQuery = <TData = Awaited<ReturnType<typeof getDcaCodeQuery>>, TError = ErrorType<void>>(
 params: GetDcaCodeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDcaCodeQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDcaCodeQueryQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDcaCodeQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getDcaCodeQuery>>, TError = ErrorType<void>>(params: GetDcaCodeParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDcaCodeQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDcaCodeQueryQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDcaCodeQuery>>> = ({ signal }) => getDcaCodeQuery(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDcaCodeQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDcaCodeQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getDcaCodeQuery>>>
export type GetDcaCodeQuerySuspenseQueryError = ErrorType<void>

/**
 * @summary Get DCA Code
 */
export const useGetDcaCodeQuerySuspense = <TData = Awaited<ReturnType<typeof getDcaCodeQuery>>, TError = ErrorType<void>>(
 params: GetDcaCodeParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDcaCodeQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDcaCodeQuerySuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetDcaCodeQuery.queryKey = (params: GetDcaCodeParams,) => getGetDcaCodeQueryQueryKey(params,);

    useGetDcaCodeQuery.updateCache = ({params, updater, options}: {params: GetDcaCodeParams, updater: Updater<DcaCodeResponse | undefined, DcaCodeResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<DcaCodeResponse>(getGetDcaCodeQueryQueryKey(params,), updater, options);
    
    useGetDcaCodeQuery.getQueryState = (params: GetDcaCodeParams,) => queryClient.getQueryState<DcaCodeResponse>(getGetDcaCodeQueryQueryKey(params,));
    
    useGetDcaCodeQuery.getQueryData = (params: GetDcaCodeParams,) => queryClient.getQueryData<DcaCodeResponse>(getGetDcaCodeQueryQueryKey(params,));
  
    useGetDcaCodeQuery.prefetch = (params: GetDcaCodeParams,) => queryClient.prefetchQuery({ queryKey: getGetDcaCodeQueryQueryKey(params,) , queryFn: () => getDcaCodeQuery(params,) });
  
    useGetDcaCodeQuery.cancelQueries = (params: GetDcaCodeParams,) => queryClient.cancelQueries({ queryKey: getGetDcaCodeQueryQueryKey(params,) })
  
    useGetDcaCodeQuery.invalidate = (params: GetDcaCodeParams,) => queryClient.invalidateQueries({ queryKey: getGetDcaCodeQueryQueryKey(params,) });
  
    useGetDcaCodeQuery.refetchStale = (params: GetDcaCodeParams,) => queryClient.refetchQueries({ queryKey: getGetDcaCodeQueryQueryKey(params,), stale: true });
  
            
/**
 * Returns a list of asset IDs allowed for DCA
 * @summary Get Allowed DCA Assets
 */

              export const getDcaAllowedAssetsQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return apiInstance<SupportedDcaAsset[]>(
      {url: `/dca/allowedAssets`, method: 'GET', signal
    },
      );
    }
  

export const getGetDcaAllowedAssetsQueryQueryKey = () => {
    return [`/dca/allowedAssets`] as const;
    }

    
export const getGetDcaAllowedAssetsQueryQueryOptions = <TData = Awaited<ReturnType<typeof getDcaAllowedAssetsQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDcaAllowedAssetsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDcaAllowedAssetsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDcaAllowedAssetsQuery>>> = ({ signal }) => getDcaAllowedAssetsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDcaAllowedAssetsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDcaAllowedAssetsQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getDcaAllowedAssetsQuery>>>
export type GetDcaAllowedAssetsQueryQueryError = ErrorType<void>

/**
 * @summary Get Allowed DCA Assets
 */
export const useGetDcaAllowedAssetsQuery = <TData = Awaited<ReturnType<typeof getDcaAllowedAssetsQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDcaAllowedAssetsQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDcaAllowedAssetsQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDcaAllowedAssetsQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getDcaAllowedAssetsQuery>>, TError = ErrorType<void>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDcaAllowedAssetsQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDcaAllowedAssetsQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDcaAllowedAssetsQuery>>> = ({ signal }) => getDcaAllowedAssetsQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDcaAllowedAssetsQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDcaAllowedAssetsQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getDcaAllowedAssetsQuery>>>
export type GetDcaAllowedAssetsQuerySuspenseQueryError = ErrorType<void>

/**
 * @summary Get Allowed DCA Assets
 */
export const useGetDcaAllowedAssetsQuerySuspense = <TData = Awaited<ReturnType<typeof getDcaAllowedAssetsQuery>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDcaAllowedAssetsQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDcaAllowedAssetsQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetDcaAllowedAssetsQuery.queryKey = () => getGetDcaAllowedAssetsQueryQueryKey();

    useGetDcaAllowedAssetsQuery.updateCache = ({updater, options}: {updater: Updater<SupportedDcaAsset[] | undefined, SupportedDcaAsset[] | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<SupportedDcaAsset[]>(getGetDcaAllowedAssetsQueryQueryKey(), updater, options);
    
    useGetDcaAllowedAssetsQuery.getQueryState = () => queryClient.getQueryState<SupportedDcaAsset[]>(getGetDcaAllowedAssetsQueryQueryKey());
    
    useGetDcaAllowedAssetsQuery.getQueryData = () => queryClient.getQueryData<SupportedDcaAsset[]>(getGetDcaAllowedAssetsQueryQueryKey());
  
    useGetDcaAllowedAssetsQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetDcaAllowedAssetsQueryQueryKey() , queryFn: () => getDcaAllowedAssetsQuery() });
  
    useGetDcaAllowedAssetsQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetDcaAllowedAssetsQueryQueryKey() })
  
    useGetDcaAllowedAssetsQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetDcaAllowedAssetsQueryQueryKey() });
  
    useGetDcaAllowedAssetsQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetDcaAllowedAssetsQueryQueryKey(), stale: true });
  
            
