import type { Locale } from "@blox/api";
import type { CurrencyFormatOptions, NumberFormatOptions } from "@shopify/react-i18n";
import { I18n } from "@shopify/react-i18n";

import { getCoinAmountInEuroDecimals } from "../utils/getCoinAmountInEuroDecimals";

const amountTranslations: Record<Locale, { million: string; billion: string }> = {
  en: {
    million: "million",
    billion: "billion",
  },
  nl: {
    million: "miljoen",
    billion: "miljard",
  },
  es: {
    million: "millón",
    billion: "mil millones",
  },
  fr: {
    million: "million",
    billion: "milliard",
  },
};

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/floor
 */
function floor10(value: number, exp: number) {
  if (exp % 1 !== 0 || Number.isNaN(value)) {
    return NaN;
  } else if (exp === 0) {
    return Math.floor(value);
  }
  const [magnitude, exponent = "0"] = `${value}`.split("e");
  const adjustedValue = Math.floor(Number(`${magnitude}e${Number(exponent) - exp}`));
  // Shift back
  const [newMagnitude, newExponent = 0] = adjustedValue.toString().split("e");
  return Number(`${newMagnitude}e${+newExponent + exp}`);
}

type GetConstructorArgs<T> = T extends new (...args: infer U) => I18n ? U : never;
export type I18nConstructorArgs = GetConstructorArgs<typeof I18n>;

export class I18nFormatters extends I18n {
  constructor(
    translations: I18nConstructorArgs[0],
    options: I18nConstructorArgs[1],
    readonly context: { e2eMode?: boolean; hideVaultValues?: boolean } = {}
  ) {
    super(translations, options);
  }

  renderPrivateValue(euroValue: boolean) {
    return `${euroValue ? "€\xa0" : ""}***`;
  }

  formatPrivateValue(value: string, euroValue = false) {
    if (this.context.hideVaultValues) {
      return this.renderPrivateValue(euroValue);
    }
    return value;
  }

  formatPublicPercentage(amount: number, options?: Intl.NumberFormatOptions): string {
    return super.formatPercentage(amount, options);
  }

  formatPrivatePercentage(amount: number, options?: Intl.NumberFormatOptions): string {
    return this.formatPrivateValue(super.formatPercentage(amount, options));
  }

  formatPublicEuroValue(amount: number, decimals: 0 | 2 = 2, options?: CurrencyFormatOptions): string {
    return super.formatCurrency(floor10(amount, decimals * -1), {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
      ...options,
    });
  }

  formatPrivateEuroValue(amount: number, decimals: 0 | 2 = 2, options?: CurrencyFormatOptions): string {
    return this.formatPrivateValue(this.formatPublicEuroValue(amount, decimals, options), true);
  }

  formatEuroValueCompact(amount: number): string {
    if (amount < 1000000) {
      return super.formatCurrency(amount, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
    if (amount < 1000000000) {
      return `${super.formatCurrency(amount / 1000000, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      })} ${amountTranslations[this.language as Locale].million}`;
    }
    return `${super.formatCurrency(amount / 1000000000, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    })} ${amountTranslations[this.language as Locale].billion}`;
  }

  formatPublicCoinAmountInEuro(amount: number, decimals = getCoinAmountInEuroDecimals(amount)): string {
    return super.formatCurrency(amount, {
      minimumFractionDigits: Math.min(2, decimals),
      maximumFractionDigits: decimals,
    });
  }

  formatPrivateCoinAmountInEuro(amount: number, decimals = getCoinAmountInEuroDecimals(amount)): string {
    return this.formatPrivateValue(this.formatPublicCoinAmountInEuro(amount, decimals), true);
  }

  formatPublicCoinAmount(amount: number, maxDecimals = 10, options?: NumberFormatOptions): string {
    return super.formatNumber(amount, {
      minimumFractionDigits: Math.min(2, maxDecimals),
      maximumFractionDigits: maxDecimals,
      ...options,
    });
  }
  formatPrivateCoinAmount(amount: number, maxDecimals = 10, options?: NumberFormatOptions): string {
    return this.formatPrivateValue(this.formatPublicCoinAmount(amount, maxDecimals, options));
  }

  formatCoinAmountCompact(amount: number): string {
    if (amount < 1000000) {
      return super.formatNumber(amount, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
    if (amount < 1000000000) {
      return `${super.formatNumber(amount / 1000000, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      })} ${amountTranslations[this.language as Locale].million}`;
    }
    return `${super.formatNumber(amount / 1000000000, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    })} ${amountTranslations[this.language as Locale].billion}`;
  }

  formatPublicOverviewCoinAmount(amount: number): string {
    return super.formatNumber(amount, {
      minimumFractionDigits: 2,
      maximumFractionDigits: Math.abs(amount) > 10 ? 2 : 8,
    });
  }
  formatPrivateOverviewCoinAmount(amount: number): string {
    return this.formatPrivateValue(this.formatPublicOverviewCoinAmount(amount));
  }
}
