import type { Locale } from "@blox/api/index";

export function getUserAgreementUrl(locale: Locale, assumedUserCountry: string) {
  switch (locale) {
    case "nl":
      return `https://weareblox.com/nl-${assumedUserCountry === "BE" ? "be" : "nl"}/algemene-voorwaarden`;
    case "es":
      return "https://weareblox.com/es-es/user-agreement";
    default:
      return "https://weareblox.com/en-gb/user-agreement";
  }
}

export function getDisclaimerUrl(locale: Locale, assumedUserCountry: string) {
  switch (locale) {
    case "nl":
      return `https://weareblox.com/nl-${assumedUserCountry === "BE" ? "be" : "nl"}/disclaimer`;
    case "es":
      return "https://weareblox.com/es-es/aviso-legal";
    case "fr":
      return "https://weareblox.com/fr-be/disclaimer";
    default:
      return "https://weareblox.com/en-gb/disclaimer";
  }
}

export function getPrivacyUrl(locale: Locale, assumedUserCountry: string) {
  switch (locale) {
    case "nl":
      return `https://weareblox.com/nl-${assumedUserCountry === "BE" ? "be" : "nl"}/privacyverklaring`;
    case "es":
      return "https://weareblox.com/es-es/aviso-de-privacidad";
    default:
      return "https://weareblox.com/en-gb/privacy-policy";
  }
}

export function getFAQUrl(locale: Locale, assumedUserCountry: string) {
  switch (locale) {
    case "nl":
      return `https://support.weareblox.com/hc/${assumedUserCountry === "BE" ? "nl-be" : "nl"}`;
    case "es":
      return "https://support.weareblox.com/hc/es";
    default:
      return "https://support.weareblox.com/hc/en-gb";
  }
}

export function getContactUsUrl(locale: Locale) {
  switch (locale) {
    case "nl":
      return "https://support.weareblox.com/hc/nl/requests/new";
    case "es":
      return "https://support.weareblox.com/hc/es/requests/new";
    default:
      return "https://support.weareblox.com/hc/en-gb/requests/new";
  }
}
