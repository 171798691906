import { Paper, PaperProps } from "@blox/design-system";

import Skeleton, { SkeletonProps } from "../../Skeleton/Skeleton";
import { Suspender } from "../../Suspender/Suspender";

type PaperBoxProps = PaperProps & {
  skeletonProps?: SkeletonProps;
};

const PaperBox = ({ children, skeletonProps = { variant: "paper" }, ...paperProps }: PaperBoxProps) => {
  return (
    <Paper {...paperProps}>
      <Suspender fallback={<Skeleton {...skeletonProps} />}>{children}</Suspender>
    </Paper>
  );
};

export default PaperBox;
