import type { CountryCode, DepositWithdrawalTypes, Locale } from "@blox/api";

export const countryCodes = [
  "BE",
  "BG",
  "CH",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GB",
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
] as const;

export const timezoneCountryCodeMap: Record<string, CountryCode> = {
  "Europe/Amsterdam": "NL",
  "Europe/Athens": "GR",
  "Europe/Berlin": "DE",
  "Europe/Bratislava": "SK",
  "Europe/Brussels": "BE",
  "Europe/Bucharest": "RO",
  "Europe/Budapest": "HU",
  "Europe/Copenhagen": "DK",
  "Europe/Dublin": "IE",
  "Europe/Helsinki": "FI",
  "Europe/Lisbon": "PT",
  "Europe/Ljubljana": "SI",
  "Europe/London": "GB",
  "Europe/Luxembourg": "LU",
  "Europe/Madrid": "ES",
  "Europe/Malta": "MT",
  "Europe/Paris": "FR",
  "Europe/Prague": "CZ",
  "Europe/Riga": "LV",
  "Europe/Rome": "IT",
  "Europe/Sofia": "BG",
  "Europe/Stockholm": "SE",
  "Europe/Tallinn": "EE",
  "Europe/Vilnius": "LT",
  "Europe/Warsaw": "PL",
  "Europe/Zagreb": "HR",
  "Europe/Zurich": "CH",
};

export const locales: [Locale, Locale, Locale, Locale] = ["nl", "en", "es", "fr"];

export const bankPaymentMethods = ["mc", "ideal", "visa", "bcmc"];

export const depositTypes: DepositWithdrawalTypes[] = [
  "NORMAL_DEPOSIT",
  "ADMIN_DEPOSIT",
  "VOUCHER_BONUS",
  "VOUCHER_REDEEMED",
];

export const withdrawalTypes: DepositWithdrawalTypes[] = ["NORMAL_WITHDRAWAL", "ADMIN_WITHDRAWAL"];
