import { styled, Text, View } from "@tamagui/core";

import { buttonResetStyles } from "../../config/mixins";

export const ToggleButtonBase = styled(
  View,
  {
    name: "ToggleButtonBase",

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "$4",

    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "$border",

    variants: {
      stretch: {
        true: {
          width: "100%",
        },
      },

      border: {
        true: {
          paddingVertical: "$6",
          paddingHorizontal: "$8",
          borderWidth: 1,

          borderRadius: "$regular",
          backgroundColor: "$white",
        },
      },
    } as const,
  },
  {
    accept: {
      toggleButtonOptionStyle: "style",
    },
  }
);

export const ToggleButtonOption = styled(Text, {
  name: "ToggleButtonOption",
  tag: "button",

  ...buttonResetStyles,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingVertical: "$10",
  paddingHorizontal: "$12",
  animation: "fast",
  borderRadius: "$medium",
  color: "$dark",
  fontSize: 16,
  fontWeight: "$semibold",
  whiteSpace: "nowrap",
  gap: 5,

  variants: {
    active: {
      true: {
        backgroundColor: "$bloxBlueLightest",
        color: "$bloxBlueDarker",

        "$theme-dark": {
          backgroundColor: "$background",
          color: "$black",
        },
      },

      false: {
        cursor: "pointer",

        hoverStyle: {
          backgroundColor: "$lighter",

          "$theme-dark": {
            color: "$darker",
          },
        },

        pressStyle: {
          color: "$darker",
        },
      },
    },
  } as const,
});
