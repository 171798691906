import { GenericTamaguiConfig } from "@tamagui/core";

export const media = {
  phoneOnly: { maxWidth: 699 },
  excludePhone: { minWidth: 700 },
  excludeTabletPortrait: { minWidth: 768 },
  excludeTablet: { minWidth: 992 },
  excludeDesktop: { minWidth: 1100 },
  excludeDesktopLarge: { minWidth: 1200 },
} satisfies GenericTamaguiConfig["media"];
