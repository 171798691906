/* eslint-disable */
const configES = ({ onAccept }: { onAccept: () => void }) => ({
  apiKey: "46918ccb2e4b3778b9be4cb38d1e54dec553b370",
  product: "PRO",
  mode: "gdpr",
  consentCookieExpiry: 365,
  initialState: "notify",
  notifyOnce: false,
  layout: "slideout",
  position: "left",
  notifyDismissButton: true,
  setInnerHTML: true,
  branding: {
    buttonIcon: null,
    removeIcon: true,
    removeAbout: true,
  },
  text: {
    accept: "Aceptar",
    reject: "",
    settings: "Leer más",
    title: "Mas informaciones sobre cookies",
    intro:
      "<p>BLOX utiliza cookies para que el sitio web sea m\u00e1s relevante para los usuarios y para aumentar la facilidad de uso. Para ello utilizamos tres tipos de cookies:</p>\n\n<ul>\n\t<li>Cookies esenciales</li>\n\t<li>Cookies de an\u00e1lisis</li>\n\t<li>Cookies de marketing</li>\n</ul>\n\n<p>Le explicamos para cuales fines utilizamos estas cookies</p>\n",
    acceptSettings: "Aceptar",
    rejectSettings: "Rechazar",
    acceptRecommended: "Aceptar",
    necessaryTitle: "Cookies esenciales",
    necessaryDescription:
      "<p>Las cookies esenciales son necesarias para que el sitio funcione correctamente. Garantizan que el sitio se pueda ver correctamente en cualquier dispositivo y que pueda iniciar sesi\u00f3n de forma segura. Por lo tanto, estas cookies no pueden ser rechazadas.</p>\n",
    thirdPartyTitle: "",
    thirdPartyDescription: "",
    on: "Encendido",
    off: "Apagado",
    notifyTitle: "Cookies de BLOX",
    notifyDescription:
      "<p>Estas cookies son necesarias para que BLOX sea m\u00e1s personal. No almacenan ning\u00fan dato personal. \u00bfEst\u00e1s de acuerdo con esto?</p>\n",
  },
  statement: {
    description: "¿Desea saber más? Lea nuestra",
    name: "Política de privacidad",
    url: "https://weareblox.com/en-gb/privacy-policy",
    updated: "10/06/2021",
  },
  necessaryCookies: ["locale", "BTCDEV", "sec_session_id", "bannerRemovedCookie"],
  optionalCookies: [
    {
      name: "analytics",
      label: "Cookies de análisis",
      description:
        "<p>Utilizamos estas cookies para analizar con qu\u00e9 frecuencia se visita el sitio web, con qu\u00e9 navegadores y qu\u00e9 p\u00e1ginas del sitio se visitan. Esto nos ayuda a mejorar el sitio web.</p>\n",
      cookies: ["_gat", "_ga", "_gads", "_gid", "_hjid"],
      onAccept,
    },
    {
      name: "marketing",
      label: "Cookies de marketing",
      description:
        '<p>Con estas cookies recogemos informaciones sobre los usuarios y visitantes de nuestro sitio web, para poder mostrar anuncios que sean interesantes para usted. Por ejemplo, en las redes sociales y en los sitios web que trabajan con <a href="https://www.nextroll.com/es-ES/privacy">Adroll</a>.</p>\n',
      cookies: ["_fbp", "_ad_cikneeto_"],
      onAccept,
    },
  ],
});

export default configES;
