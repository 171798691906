import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

type AdyenContextType = {
  adyenResponse?: string;
};

const AdyenContext = React.createContext<AdyenContextType>({});

const AdyenProvider = ({ children }: { children: React.ReactNode }) => {
  const [adyenResponse, setAdyenResponse] = useState<string>();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const resultCode = new URLSearchParams(location.search).get("resultCode");
    if (resultCode) {
      setAdyenResponse(resultCode);
      history.push(`${location.pathname}?depositResult=${resultCode}`);
    }
  }, [history, location.pathname, location.search]);

  return <AdyenContext.Provider value={{ adyenResponse }}>{children}</AdyenContext.Provider>;
};

export { AdyenContext, AdyenProvider };
