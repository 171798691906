import "./Divider.scss";

import classNames from "classnames";
import { useMedia } from "react-use";

export function Divider({
  type,
  height = 20,
  fullWidth,
  hideOnMobile,
  mobileOnly,
}: {
  type?: "spacer" | "line";
  height?: number;
  fullWidth?: boolean;
  hideOnMobile?: boolean;
  mobileOnly?: boolean;
}) {
  const classes = classNames({
    divider: true,
    "divider--line": type === "line",
    "divider--fullwidth": fullWidth,
    "hide-on-mobile": hideOnMobile,
    "mobile-only": mobileOnly,
  });

  const phoneOnly = useMedia("(max-width: 699px)");

  // Prevent the Divider from becoming too high on mobile screens
  const dividerHeight = phoneOnly ? Math.min(height, 20) : height;

  return (
    <div className={classes} style={{ marginTop: dividerHeight / 2, paddingBottom: dividerHeight / 2 }} />
  );
}
