import { TamaguiProvider, TamaguiProviderProps } from "@tamagui/core";

import config from "../../tamagui.config";

export function DesignSystemProvider({ children, ...rest }: Omit<TamaguiProviderProps, "config">) {
  return (
    <TamaguiProvider config={config} {...rest}>
      {children}
    </TamaguiProvider>
  );
}
