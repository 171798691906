import { GetProps, styled, View } from "@tamagui/core";

import { Flex } from "../Flex";

export type LoaderColorType = GetProps<typeof SpinnerDot>["color"];
export type LoaderVariantType = GetProps<typeof SpinnerBase>["variant"];

export const FullscreenLoader = styled(Flex, {
  name: "FullscreenLoader",

  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
});

export const DefaultLoader = styled(View, {
  name: "DefaultLoader",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  zIndex: 10,
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgb(255 255 255 / 51.4%)",
});

export const SpinnerBase = styled(View, {
  name: "SpinnerBase",

  width: 70,
  flexDirection: "row",
  justifyContent: "center",
  gap: 2,

  variants: {
    variant: {
      regular: {
        marginTop: 100,
        marginHorizontal: "auto",
        marginBottom: 0,
      },
      fullScreen: {},
      small: {
        width: "100%",
      },
    },
  } as const,
});

export const SpinnerDot = styled(View, {
  name: "SpinnerDot",

  display: "block",
  width: 20,
  height: 20,
  borderRadius: "$full",
  backgroundColor: "$bloxBlueDark",

  variants: {
    color: {
      white: {
        backgroundColor: "$white",
      },
      blue: {},
    },
  } as const,

  defaultVariants: {
    color: "blue",
  },
});
