import { getTokens, useTheme } from "@blox/design-system";
import { useCallback } from "react";

export default function getColor(color: keyof ReturnType<typeof getTokens>["color"]) {
  return getTokens().color[color];
}

export function useGetColor() {
  const theme = useTheme();

  return useCallback(
    (color: keyof typeof theme) => {
      return theme[color]?.get();
    },
    [theme]
  );
}
