import { useGetIPInfo } from "@blox/api";
import getCountryCodeByTimeZone from "@blox/shared/utils/getCountryCodeByTimeZone";

import useUserCountry from "./useUserCountry";

export default function useAssumedUserCountry() {
  const userCountry = useUserCountry();
  const { data: iPinfo } = useGetIPInfo({ query: { enabled: !userCountry } });
  return userCountry ?? iPinfo?.country_iso ?? getCountryCodeByTimeZone();
}
