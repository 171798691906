import "./Error.scss";

import { Button, Heading, Paragraph } from "@blox/design-system";
import * as React from "react";

import errorIcon from "../../assets/img/error.png";
import { useLocalisation } from "../../context/LocalizeProvider";
import { Divider } from "../UI/Divider/Divider";

export type ErrorProps = {
  variant?: "small" | "large";
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
};

export function Error({ variant = "small" }: ErrorProps) {
  const t = useLocalisation();

  return (
    <div className="error centered-text">
      {variant === "large" ? (
        <>
          <img src={errorIcon} alt="" />
          <Heading level={1}>{t.text("Global", "errorTitle")}</Heading>
        </>
      ) : (
        <Heading level={2}>{t.text("Global", "errorTitle")}</Heading>
      )}
      <Paragraph>{t.text("Global", "errorDescription")}</Paragraph>
      <Divider height={20} />
      <Button variant="text" onClick={() => window.location.reload()}>
        {t.text("Global", "errorRefresh")}
      </Button>
      <Divider height={10} />
      <Paragraph variant={["faded", "small"]}>{t.text("Global", "errorSupport")}</Paragraph>
    </div>
  );
}

export const ErrorLarge = (props: Omit<ErrorProps, "variant">) => <Error variant="large" {...props} />;
