import { GetProps, styled, View } from "@tamagui/core";
import React, { ReactNode } from "react";

import { InputWrapper, InputWrapperInput, LabelElement, SelectElement } from "../Input/Input.style";

const Caret = styled(View, {
  content: '""',
  position: "absolute",
  top: "calc(50% - 0.1875rem)",
  right: 10,
  width: 11,
  height: 6,
  backgroundImage:
    'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTEnIGhlaWdodD0nNicgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTAuMzUxIDEuNTNhLjQ2My40NjMgMCAwMDAtLjY4TDkuNjM2LjE2OGEuNTIuNTIgMCAwMC0uNzEyIDBMNS41MjkgMy40NDcgMi4xMDYuMTQyYS41Mi41MiAwIDAwLS43MTMgMGwtLjcxNS42OGEuNDY2LjQ2NiAwIDAwMCAuNjhsNC40OTQgNC4zMjdhLjUyNi41MjYgMCAwMC43MTQgMGw0LjQ2NS00LjN6JyBmaWxsPScjQTFCMkM0JyBmaWxsLXJ1bGU9J2V2ZW5vZGQnLz48L3N2Zz4=")',
  backgroundSize: "contain",
});

export type SelectProps = GetProps<typeof SelectElement> & {
  name: string;
  label?: ReactNode;
  options?: { label: string; value: string | number }[];
};

export function Select({ name, label, options, ...rest }: SelectProps) {
  return (
    <InputWrapper>
      {label && <LabelElement htmlFor={name}>{label}</LabelElement>}
      <InputWrapperInput>
        <SelectElement
          id={name}
          name={name}
          {...rest}
          style={{
            appearance: "none", // does not exist on tamagui styles
            ...rest.style,
          }}
        >
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </SelectElement>
        <Caret />
      </InputWrapperInput>
    </InputWrapper>
  );
}
