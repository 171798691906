import ConfigProd from "./env-prod.json";
import ConfigStaging from "./env-staging.json";
import ConfigTest from "./env-test.json";

let configParams = ConfigStaging;
if (window.location.hostname === "app.weareblox.com" || window.location.hostname === "app.weareblox.blox") {
  configParams = ConfigProd;
} else if (import.meta.env.MODE === "test") {
  configParams = ConfigTest;
}

export default configParams;
