import { initialConfig } from "@blox/shared/firestore/initialConfig";
import { FireStoreConfigType } from "@blox/shared/firestore/types";
import { useQueryClient } from "@tanstack/react-query";
import { doc } from "firebase/firestore";
import { useEffect } from "react";
import * as React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { toast } from "react-toastify";

import configParams from "../env";
import { firestore } from "../firebase";
import { useFirestore } from "../hooks/useFirestore";

// this can be mocked in Playwright via `session.mockFirestore()`
const firestoreMock = window?.sessionStorage.getItem("firestoreMock")
  ? (JSON.parse(window.sessionStorage.getItem("firestoreMock")!) as Partial<FireStoreConfigType>)
  : undefined;

export default function FireStoreProvider({ children }: { children: React.ReactNode }) {
  const queryClient = useQueryClient();
  const mutateStore = useFirestore((store) => store.mutateStore);
  const queryCacheTimeSeconds = useFirestore((s) => s.config.configSet.queryCacheTimeSeconds);

  useEffect(() => {
    const options = queryClient.getDefaultOptions();
    const queryCacheTime = queryCacheTimeSeconds * 1000;

    if (queryCacheTime !== options.queries?.staleTime) {
      queryClient.setDefaultOptions({
        ...options,
        queries: { ...options.queries, staleTime: queryCacheTime },
      });
    }
  }, [queryCacheTimeSeconds, queryClient]);

  const [firestoreConfigData] = useDocumentData(
    doc(firestore, `config/configSet${configParams.ENVIRONMENT === "STAGING" ? "Staging" : ""}`)
  );

  useEffect(() => {
    if (firestoreConfigData) {
      const configSet = firestoreConfigData as FireStoreConfigType;
      if (import.meta.env.DEV) {
        Object.keys(initialConfig.configSet).forEach((item) => {
          if (!(item in configSet)) {
            toast.warn(`Remote firestore is missing configSet key: ${item}`);
          }
        });

        Object.keys(initialConfig.features).forEach((item) => {
          if (configSet.features && !(item in configSet.features)) {
            toast.warn(`Remote firestore is missing feature key: ${item}`);
          }
        });
      }

      mutateStore((s) => {
        s.config.configSet = {
          ...s.config.configSet,
          ...configSet,
          ...firestoreMock?.configSet,
        };
        s.config.features = { ...s.config.features, ...configSet.features, ...firestoreMock?.features };
      });
    }
  }, [mutateStore, firestoreConfigData]);

  return <>{children}</>;
}
