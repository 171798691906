import { Locale } from "@blox/api";
import { TranslationDictionary } from "@shopify/react-i18n";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

import { queryClient } from "~queryClient";

const DISTRIBUTION_HASH = "7d1ae1fdf5ea4a5a54c2258k2qe";

const enableCDN = !window.sessionStorage.getItem("LANGUAGE_CI_MODE") && import.meta.env.MODE === "production";

export const getCrowdinQueryKey = (locale: Locale) => ["crowdin", { locale }];

export const fetchCrowdin = async (locale: Locale, timestamp?: number) => {
  // lazy load local fallback module only when needed, to reduce (initial) bundle size
  const fallback: TranslationDictionary = await import(`../translations/${locale}.json`).then(
    (module) => module.default
  );

  if (!enableCDN) {
    return fallback;
  }

  const translations = await axios
    .get<TranslationDictionary>(
      `https://distributions.crowdin.net/${DISTRIBUTION_HASH}/content/develop/apps/web/src/translations/${locale}.json?timestamp=${timestamp}`
    )
    .then((res) => res.data)
    .catch(() => fallback);

  // merge cdn translations with our local fallback
  const mergedStrings = cloneDeep(fallback);
  return merge(mergedStrings, translations);
};

export function useCrowdin(locale: Locale) {
  const { data } = useQuery({
    queryKey: ["crowdin-manifest"],
    queryFn: () =>
      axios
        .get<{ timestamp: number }>(`https://distributions.crowdin.net/${DISTRIBUTION_HASH}/manifest.json`)
        .then((res) => res.data),

    staleTime: Infinity,
    enabled: enableCDN,
  });

  return useQuery({
    queryKey: getCrowdinQueryKey(locale),
    queryFn: () => fetchCrowdin(locale, data?.timestamp),
    placeholderData: keepPreviousData,
    staleTime: Infinity,
    enabled: !enableCDN || !!data?.timestamp,
  });
}

useCrowdin.getQueryData = (locale: Locale) => queryClient.getQueryData(getCrowdinQueryKey(locale));
