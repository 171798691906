import type { SVGProps } from "react";
const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={18} viewBox="0 0 24 18" {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M23.925 8.6C23.725 8.2 19.515 0 12 0S.276 8.2.075 8.6q-.15.45 0 .9C.275 9.8 4.485 18 12 18s11.724-8.2 11.925-8.6c.1-.2.1-.6 0-.8M12 16c-5.411 0-8.919-5.4-9.92-7 .9-1.6 4.508-7 9.92-7s8.919 5.4 9.92 7c-1.001 1.6-4.509 7-9.92 7m0-11C9.795 5 7.992 6.8 7.992 9S9.795 13 12 13s4.008-1.8 4.008-4S14.205 5 12 5m0 6c-1.102 0-2.004-.9-2.004-2S10.898 7 12 7s2.004.9 2.004 2-.902 2-2.004 2"
    />
  </svg>
);
export default SvgEye;
