import { GetProps, styled, Text } from "@tamagui/core";

export const Box = styled(Text, {
  name: "Box",

  tag: "div",

  display: "block",
  wordWrap: "unset",
  whiteSpace: "unset",
  margin: "unset",
});

export type BoxProps = GetProps<typeof Box>;
