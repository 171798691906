import { createTokens } from "@tamagui/core";

const space = {
  "-24": "1.5rem",
  "-20": "1.25rem",
  "-16": "1rem",
  "-12": "0.75rem",
  "-10": "0.625rem",
  "-8": "0.5rem",
  "-6": "0.375rem",
  "-4": "0.25rem",
  "-2": "0.125rem",
  0: 0,
  2: "0.125rem",
  4: "0.25rem",
  6: "0.375rem",
  8: "0.5rem",
  10: "0.625rem",
  12: "0.75rem",
  16: "1rem",
  20: "1.25rem",
  24: "1.5rem",
  32: "2rem",
  40: "2.5rem",
  48: "3rem",
  56: "3.5rem",
  pagePadding: "2rem",
  pagePaddingMobile: "1.25rem",
  containerWidth: "78.75rem",
} as const;

export const bloxColors = {
  bloxBlueDarkest: "#008bad",
  bloxBlueDarker: "#25b1d3",
  bloxBlueDark: "#59cbe7",
  bloxBlue: "#78d7f0",
  bloxBlueLight: "#95e6fb",
  bloxBlueLighter: "#b7efff",
  bloxBlueLightest: "#d8f4fa",

  bloxYellowDarkest: "#c9a900",
  bloxYellowDarker: "#edc700",
  bloxYellowDark: "#ffd600",
  bloxYellow: "#FFF000",
  bloxYellowLight: "#fff376",
  bloxYellowLighter: "#fff9ba",

  bloxRedDarker: "#af262b",
  bloxRedDark: "#c3373c",
  bloxRed: "#e15055",
  bloxRedLight: "#f87479",
  bloxRedLighter: "#ffb1b4",
  bloxRedLightest: "#f5dcdc",

  successDark: "#00966D",
  success: "#00BA88",
  successLight: "#B4FDBB",
  successLighter: "#E2F7F1",

  warningDark: "#de9300",
  warning: "#ffa800",
  warningLight: "#ffe7b8",
  warningLighter: "#fff1d7",

  alertDark: "#DA341D",
  alert: "#FF3C25",
  alertLight: "#FE7160",
  alertLighter: "#FDA09A",
  alertLightest: "#FFE9E8",

  yellowDark: "#d9b500",
  yellow: "#ffd70f",
  yellowLight: "#fff0a3",

  greenDark: "#169999",
  green: "#3cc8c8",
  greenLight: "#82f4f4",

  purpleDark: "#922785",
  purple: "#eb70dc",
  purpleLight: "#eeade6",

  pinkDark: "#cf3a54",
  pink: "#fb5e7a",
  pinkLight: "#ffbcc8",
} as const;

export const slateLight = {
  black_light: "#090a0c",
  darker_light: "#284964",
  dark_light: "#88939d",
  default_light: "#a2b5ca",
  light_light: "#f1f5fa",
  lighter_light: "#F8FBFF",
  offWhite_light: "#f8fbff",
  button_light: "#d9e0e7",
  white_light: "white",
  background_light: "#F8FBFF",
  border_light: "#e8edf3",
} as const;

export const slateDark = {
  black_dark: "#FCFCFC",
  darker_dark: "#D9DEE3",
  dark_dark: slateLight.dark_light,
  default_dark: "#7F8386",
  light_dark: "#363B47",
  lighter_dark: "#242832",
  offWhite_dark: "#171A24",
  button_dark: slateLight.button_light,
  white_dark: "#242832",
  background_dark: "#171A24",
  border_dark: "#363B47",
} as const;

export const tokens = createTokens({
  radius: {
    small: "0.25rem",
    medium: "0.5rem",
    regular: "0.75rem",
    full: 9999,
  },
  color: {
    ...bloxColors,
    ...slateLight,
    ...slateDark,
  },
  space,
  size: {
    0: 0,
    2: "0.125rem",
    4: "0.25rem",
    6: "0.375rem",
    8: "0.5rem",
    10: "0.625rem",
    12: "0.75rem",
    16: "1rem",
    20: "1.25rem",
    24: "1.5rem",
    32: "2rem",
    40: "2.5rem",
    48: "3rem",
    56: "3.5rem",
  },
  zIndex: {
    0: 0,
    1: 100,
    2: 200,
    3: 300,
    4: 400,
    5: 500,
  },
});
