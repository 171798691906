import type { SVGProps } from "react";
const SvgEyeSlash = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={25} viewBox="0 0 24 25" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M23.925 12.6C23.725 12.2 19.515 4 12 4S.276 12.2.075 12.6q-.15.45 0 .9c.2.3 4.41 8.5 11.925 8.5s11.724-8.2 11.925-8.6c.1-.2.1-.6 0-.8M12 20c-5.411 0-8.919-5.4-9.92-7 .9-1.6 4.508-7 9.92-7s8.919 5.4 9.92 7c-1.001 1.6-4.509 7-9.92 7m0-11c-2.205 0-4.008 1.8-4.008 4S9.795 17 12 17s4.008-1.8 4.008-4S14.205 9 12 9m0 6c-1.102 0-2.004-.9-2.004-2s.902-2 2.004-2 2.004.9 2.004 2-.902 2-2.004 2"
      />
      <path stroke="currentColor" strokeLinecap="square" strokeWidth={2} d="M3 23 22 2" />
    </g>
  </svg>
);
export default SvgEyeSlash;
