import { Fragment } from "react";

export function newlineToBR(content: string) {
  return (
    content
      // replace firestore newlines
      .replace(/\\n/g, "\n")
      .split(/\r\n|\r|\n/)
      .map((chunk, index) => (
        <Fragment key={index}>
          {index > 0 && <br />}
          {chunk}
        </Fragment>
      ))
  );
}
