import "./Columns.scss";

import classNames from "classnames";
import React, { ComponentPropsWithoutRef, HTMLAttributes } from "react";

export type ColSpan = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export type RowCols = 2 | 3 | 5 | 12;

export function Row({
  children,
  cols,
  className,
  ...rest
}: ComponentPropsWithoutRef<"div"> & { cols: RowCols }) {
  const classes = classNames(
    {
      row: true,
      [`row__cols--${cols}`]: true,
    },
    className
  );

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
}

interface TParamsCol extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  sticky?: boolean;
  span: ColSpan;
}

export function Col({ children, sticky, span, ...props }: TParamsCol) {
  const { className = "" } = props;
  const classes = classNames(
    {
      col: true,
      "col--sticky": sticky,
      [`col__span--${span}`]: true,
    },
    className
  );

  return (
    <div {...props} className={classes}>
      {children}
    </div>
  );
}
