import { WebStorageStateStore } from "oidc-client-ts";
import { ReactNode, useCallback } from "react";
import { AuthProvider as AuthProviderOIDC, AuthProviderProps } from "react-oidc-context";
import { useHistory } from "react-router-dom";

import configParams from "../env";

export function AuthProvider({ children }: { children: ReactNode }) {
  const history = useHistory();

  const oidcConfig: AuthProviderProps = {
    authority: `${configParams.AUTH_BASE}/realms/blox`,
    client_id: configParams.CLIENT_ID,
    redirect_uri: window.location.origin,
    onSigninCallback: useCallback(() => {
      const replaceUrl = new URL(window.location.toString());
      replaceUrl.searchParams.delete("code");
      replaceUrl.searchParams.delete("error");
      replaceUrl.searchParams.delete("session_state");
      replaceUrl.searchParams.delete("state");
      replaceUrl.searchParams.delete("iss");

      history.replace({
        ...history.location,
        search: replaceUrl.searchParams.toString(),
      });
    }, [history]),
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };

  return <AuthProviderOIDC {...oidcConfig}>{children}</AuthProviderOIDC>;
}
