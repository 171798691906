/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { useContext, useState } from "react";

import { BreadCrumbProps } from "../components/BreadCrumbs/BreadCrumbs";

export const PageTitleContextUpdater = React.createContext((_: BreadCrumbProps[]) => {});
export const PageTitleContext = React.createContext<BreadCrumbProps[]>([]);

export default function PageTitleProvider({ children }: { children: React.ReactNode }) {
  const [pageTitle, setPageTitle] = useState<BreadCrumbProps[]>([]);

  return (
    <PageTitleContext.Provider value={pageTitle}>
      <PageTitleContextUpdater.Provider value={setPageTitle}>{children}</PageTitleContextUpdater.Provider>
    </PageTitleContext.Provider>
  );
}

export function usePageTitle() {
  const pageTitle = useContext(PageTitleContext);
  const setPageTitle = useContext(PageTitleContextUpdater);
  return { pageTitle, setPageTitle };
}
