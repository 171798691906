import { initialConfig } from "@blox/shared/firestore/initialConfig";
import { FireStoreState } from "@blox/shared/firestore/types";
import { produce } from "immer";
import { create } from "zustand";

export const useFirestore = create<FireStoreState>((set) => ({
  // Use immer to mutate store https://immerjs.github.io/immer/update-patterns
  mutateStore: (fn) => set(produce(fn)),
  // Use traditional 'setState' like immutable updates to the store
  setStore: set,
  config: initialConfig,
}));
