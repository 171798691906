import { Locale, useGetUserQuery, useUpdateUserDetailsMutation } from "@blox/api";
import { Select, SelectProps } from "@blox/design-system";
import { toast } from "react-toastify";

import { languageLabels, useLocalisation } from "../../context/LocalizeProvider";
import { useCrowdin } from "../../hooks/useCrowdin";
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn";
import { useLocale, useMutateStore } from "../../hooks/useStore";

type LanSelectorProps = { noLabel?: boolean } & Omit<SelectProps, "name">;

function LanSelector({ noLabel, ...rest }: LanSelectorProps) {
  const t = useLocalisation();
  const locale = useLocale();
  const mutateStore = useMutateStore();
  const isLoggedIn = useIsLoggedIn();

  const { mutateAsync: updateUser, isPending: isUpdating } = useUpdateUserDetailsMutation();
  const { isLoading: isLoadingTranslations } = useCrowdin(locale);

  const { data: user } = useGetUserQuery({
    query: {
      enabled: isLoggedIn,
    },
  });

  const updateLocale = async (newLocale: Locale) => {
    mutateStore((s) => {
      s.userLocale = newLocale;
    });

    if (isLoggedIn && user) {
      try {
        await updateUser({ data: { ...user, attributes: { ...user.attributes, locale: [newLocale] } } });
      } catch (e) {
        toast.error(t.text("Profile", "resultError"));
      }
    }
  };

  return (
    <Select
      name="preferences__language"
      value={locale}
      onChange={(e) => {
        updateLocale(e.target.value as Locale);
      }}
      label={!noLabel ? t.text("Preferences", "language") : undefined}
      options={Object.entries(languageLabels).map(([lan, label]) => ({
        value: lan,
        label,
      }))}
      disabled={isUpdating || isLoadingTranslations}
      {...rest}
    />
  );
}

export default LanSelector;
