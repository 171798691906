export const firebaseConfig = {
  apiKey: "AIzaSyAcFiSIydtWdSPU4JAIKoojvyEEjIb1R1Y",
  authDomain: "blox-7e8b0.firebaseapp.com",
  databaseURL: "https://blox-7e8b0.firebaseio.com",
  projectId: "blox-7e8b0",
  storageBucket: "blox-7e8b0.appspot.com",
  messagingSenderId: "25311772506",
  appId: "1:25311772506:web:bff41398a2ad2007effd63",
  measurementId: "G-KGL9FJ4HNP",
};
