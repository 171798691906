import { GetProps, styled, Text } from "@tamagui/core";

export type ParagraphVariant =
  | "faded"
  | "fadedBlue"
  | "uppercase"
  | "small"
  | "warning"
  | "error"
  | "inter"
  | "viga"
  | "strong";

const ParagraphBase = styled(
  Text,
  {
    name: "Paragraph",
    tag: "p",
    display: "unset",
    margin: "unset",

    fontSize: 17,
    // @ts-expect-error we want 150% of the font size, but string is not supported
    lineHeight: "1.5",

    variants: {
      faded: {
        true: {
          color: "$dark",
        },
      },
      fadedBlue: {
        true: {
          color: "$darker",
        },
      },
      uppercase: {
        true: {
          textTransform: "uppercase",
        },
      },
      small: {
        true: {
          fontSize: 16,
        },
      },
      warning: {
        true: {
          color: "$warning",
        },
      },
      error: {
        true: {
          color: "$alert",
        },
      },
      inter: {
        true: {
          fontWeight: "normal",
          fontFamily: "$body",
        },
      },
      viga: {
        true: {
          fontWeight: "normal",
          fontFamily: "$heading",
        },
      },
      strong: {
        true: {
          fontWeight: 600,
        },
      },
    } as const,
  },
  {
    acceptsClassName: true,
  }
);

export const Paragraph = ParagraphBase.styleable<{
  variant?: ParagraphVariant | ParagraphVariant[];
}>(({ variant, ...props }, ref) => {
  // keep the api of the paragraph component consistent
  const variantProps = variant
    ? (Object.fromEntries(
        (Array.isArray(variant) ? variant : [variant]).map((prop) => [prop, true])
      ) as Record<ParagraphVariant, true>)
    : undefined;

  return <ParagraphBase ref={ref} {...variantProps} {...props} />;
});

export type ParagraphProps = GetProps<typeof Paragraph>;
