/* eslint-disable */
const configNL = ({ onAccept }: { onAccept: () => void }) => ({
  apiKey: "46918ccb2e4b3778b9be4cb38d1e54dec553b370",
  product: "PRO",
  mode: "gdpr",
  consentCookieExpiry: 365,
  initialState: "notify",
  notifyOnce: false,
  layout: "slideout",
  position: "left",
  notifyDismissButton: true,
  setInnerHTML: true,
  branding: {
    buttonIcon: null,
    removeIcon: true,
    removeAbout: true,
  },
  text: {
    accept: "Prima!",
    reject: "Weigeren",
    settings: "Meer info",
    title: "Meer info over cookies",
    intro:
      "<p>BLOX gebruikt cookies om de website relevanter te maken voor gebruikers en om het gebruiksgemak te vergroten. We gebruiken drie soorten cookies om dit te doen:</p>\n\n<ul>\n\t<li>Essenti\u0113le cookies</li>\n\t<li>Analyse cookies</li>\n\t<li>Marketing cookies</li>\n</ul>\n\n<div>We leggen uit waar we deze cookies voor gebruiken.</div>\n",
    acceptSettings: "Accepteer",
    rejectSettings: "Weigeren",
    acceptRecommended: "Accepteer",
    necessaryTitle: "Essentiēle cookies",
    necessaryDescription:
      "<p>Essenti\u0113le cookies zijn nodig om de site goed te laten werken. Ze zorgen ervoor dat de site goed te zien is op elk apparaat en zorgen ervoor dat je veilig kunt inloggen. Deze cookies zijn daarom niet te weigeren.</p>\n",
    thirdPartyTitle: "",
    thirdPartyDescription: "",
    on: "Aan",
    off: "Uit",
    notifyTitle: "Cookies op BLOX",
    notifyDescription:
      "<p>Deze cookies zijn nodig om BLOX persoonlijker te maken. Ze slaan geen persoonsgegevens op. Vind je dit ok\u00e9?</p>\n",
  },
  statement: {
    description: "Meer weten? Lees onze",
    name: "Privacyregelement",
    url: "https://weareblox.com/nl-nl/privacyverklaring",
    updated: "10/06/2021",
  },
  necessaryCookies: ["locale", "BTCDEV", "sec_session_id", "bannerRemovedCookie"],
  optionalCookies: [
    {
      name: "analytics",
      label: "Analyse cookies",
      description:
        "<p>We gebruiken deze cookies om te analyseren hoe vaak de website bezocht wordt, met welke browsers, welke pagina\u2019s van de website bezocht. Dit helpt ons om de website te verbeteren.</p>\n",
      cookies: ["_gat", "_ga", "_gads", "_gid", "_hjid"],
      onAccept,
    },
    {
      name: "marketing",
      label: "Marketing cookies",
      description:
        '<p>Met deze cookies verzamelen we informatie over gebruikers en bezoekers van onze website, zodat we voor u interessante advertenties kunnen laten zien. Bijvoorbeeld op Social media en websites die werken met <a href="https://www.nextroll.com/nl-NL/privacy">Adroll</a>.</p>\n',
      cookies: ["_fbp", "_ad_cikneeto_"],
      onAccept,
    },
  ],
});

export default configNL;
