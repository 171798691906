import { useMedia } from "@blox/design-system";

/**
 * @deprecated use useMedia instead
 */
export function useBreakpoint(breakpoint: keyof ReturnType<typeof useMedia>) {
  const media = useMedia();

  return media[breakpoint];
}
