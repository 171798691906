import { TextProps } from "@tamagui/core";

export const buttonResetStyles: TextProps = {
  width: "100%",
  margin: 0,
  padding: 0,
  borderWidth: 0,
  backgroundColor: "transparent",
  fontFamily: "unset",
  textAlign: "left",
  style: {
    appearance: "none",
  },
} as const;

export const textLinkStyles: TextProps = {
  ...buttonResetStyles,

  display: "inline",
  width: "auto",
  color: "$bloxBlueDarker",
  cursor: "pointer",
  fontWeight: 500,

  hoverStyle: {
    color: "$bloxBlueDarker",
    textDecorationLine: "underline",
  },

  focusStyle: {
    color: "$bloxBlueDarker",
    textDecorationLine: "underline",
  },

  pressStyle: {
    color: "$bloxBlueDarkest",
  },
};
