import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";

import { api, bffApi, coinDataApi, userApi } from "../api";

const getInstance = (instance: AxiosInstance, config: AxiosRequestConfig) => {
  const source = Axios.CancelToken.source();
  const promise = instance({ ...config, cancelToken: source.token }).then((res) => res.data);

  // @ts-expect-error cancel is not part of the promise
  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };

  return promise;
};

export const apiInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  return getInstance(api, config);
};

export const userApiInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  return getInstance(userApi, config);
};

export const coinDataApiInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  return getInstance(coinDataApi, config);
};

export const bffApiInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  return getInstance(bffApi, config);
};

export interface ErrorType<Error> extends AxiosError<Error> {}
