import {
  UseMutationOptions,
  UseQueryOptions,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { AxiosError } from "axios";
import { useCallback } from "react";

import {
  useGetUserPreferencesQuery,
  useGetUserPreferencesQuerySuspense,
  useUpdateUserPreferencesMutation,
} from "../__generated__/api-blox";

export enum NotificationPreference {
  Account = "push_notification_account",
  Offers = "push_notification_offers",
  CryptoNews = "push_notification_crypto_news",
  BloxNews = "push_notification_blox_news",
}

export type PersonalSettingsResponse = {
  notification_preferences?: Partial<Record<NotificationPreference, boolean>>;
  favorites?: { favorites: string[]; userFavoritesLastChange: number };
  stakingContinued?: boolean;
  cryptoTransfer?: {
    seenExplanation?: boolean;
    dontShowSafetyWarning?: boolean;
  };
  dca?: {
    seenExplanation?: boolean;
  };
  appVersions?: {
    [deviceId: string]: {
      appVersion: string;
      updateVersion: string;
      deviceDescription: string;
      os: string;
      updatedAt: string;
    };
  };
  lastActiveWeb?: string;
  lastActiveApp?: string;
};
export type PersonalSettingsRequest = Partial<PersonalSettingsResponse>;

export function useGetPersonalSettings<T = PersonalSettingsResponse>(props?: {
  query?: Partial<UseQueryOptions<PersonalSettingsResponse, AxiosError, T, any>>;
}): UseQueryResult<T> {
  return useGetUserPreferencesQuery({
    ...props,
    query: {
      refetchOnWindowFocus: true,
      ...props?.query,
    },
  });
}

export function useGetPersonalSettingsSuspense<T = PersonalSettingsResponse>(props?: {
  query?: Partial<UseSuspenseQueryOptions<PersonalSettingsResponse, AxiosError, T, any>>;
}): UseSuspenseQueryResult<T> {
  return useGetUserPreferencesQuerySuspense({
    ...props,
    query: {
      refetchOnWindowFocus: true,
      ...props?.query,
    },
  });
}

useGetPersonalSettings.prefetch = useGetUserPreferencesQuery.prefetch as () => Promise<void>;

useGetPersonalSettings.getQueryData = () =>
  useGetUserPreferencesQuery.getQueryData() as PersonalSettingsResponse;

export function useUpdatePersonalSettings(props?: {
  mutation?: UseMutationOptions<
    PersonalSettingsResponse,
    AxiosError,
    { data: PersonalSettingsRequest },
    PersonalSettingsRequest
  >;
}) {
  const { mutateAsync: originalMutateAsync, ...rest } = useUpdateUserPreferencesMutation({
    mutation: {
      ...props?.mutation,
      onMutate: ({ data }) => {
        return useGetUserPreferencesQuery.updateCache({
          updater: () => ({ ...useGetPersonalSettings.getQueryData(), ...data }),
        });
      },
      onSuccess: (data) =>
        useGetUserPreferencesQuery.updateCache({
          updater: () => data,
        }),
    },
  });

  const mutateAsync = useCallback(
    async (
      { data }: { data: PersonalSettingsRequest },
      options?: Parameters<typeof originalMutateAsync>[1]
    ) => {
      await originalMutateAsync({ data }, options);
    },
    [originalMutateAsync]
  );

  // watch out for infinite loops here, always destruct the return value of this hook!
  return { mutateAsync, ...rest };
}
