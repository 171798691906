/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * BLOX Keycloak Client API
 * API spec for BLOX clients and services
 * OpenAPI spec version: 0.1.0
 */
import {
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  UserInfoResponse,
  UserResponse
} from './model'
import { userApiInstance } from '../../lib/api-instance';
import type { ErrorType } from '../../lib/api-instance';



              // @ts-ignore
              import { Updater, SetDataOptions, QueryFilters } from "@tanstack/react-query";
              // @ts-ignore
              import { queryClient } from "~queryClient";

              type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



;
            
              export const getUserInfoQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return userApiInstance<UserInfoResponse>(
      {url: `/realms/blox/protocol/openid-connect/userinfo`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserInfoQueryQueryKey = () => {
    return [`/realms/blox/protocol/openid-connect/userinfo`] as const;
    }

    
export const getGetUserInfoQueryQueryOptions = <TData = Awaited<ReturnType<typeof getUserInfoQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserInfoQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserInfoQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserInfoQuery>>> = ({ signal }) => getUserInfoQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserInfoQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserInfoQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getUserInfoQuery>>>
export type GetUserInfoQueryQueryError = ErrorType<unknown>

export const useGetUserInfoQuery = <TData = Awaited<ReturnType<typeof getUserInfoQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserInfoQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserInfoQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserInfoQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUserInfoQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserInfoQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserInfoQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserInfoQuery>>> = ({ signal }) => getUserInfoQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserInfoQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserInfoQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUserInfoQuery>>>
export type GetUserInfoQuerySuspenseQueryError = ErrorType<unknown>

export const useGetUserInfoQuerySuspense = <TData = Awaited<ReturnType<typeof getUserInfoQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserInfoQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserInfoQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetUserInfoQuery.queryKey = () => getGetUserInfoQueryQueryKey();

    useGetUserInfoQuery.updateCache = ({updater, options}: {updater: Updater<UserInfoResponse | undefined, UserInfoResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<UserInfoResponse>(getGetUserInfoQueryQueryKey(), updater, options);
    
    useGetUserInfoQuery.getQueryState = () => queryClient.getQueryState<UserInfoResponse>(getGetUserInfoQueryQueryKey());
    
    useGetUserInfoQuery.getQueryData = () => queryClient.getQueryData<UserInfoResponse>(getGetUserInfoQueryQueryKey());
  
    useGetUserInfoQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetUserInfoQueryQueryKey() , queryFn: () => getUserInfoQuery() });
  
    useGetUserInfoQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetUserInfoQueryQueryKey() })
  
    useGetUserInfoQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetUserInfoQueryQueryKey() });
  
    useGetUserInfoQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetUserInfoQueryQueryKey(), stale: true });
  
            

              export const getUserQuery = (
    
 signal?: AbortSignal
) => {
      
      
      return userApiInstance<UserResponse>(
      {url: `/realms/blox/account`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserQueryQueryKey = () => {
    return [`/realms/blox/account`] as const;
    }

    
export const getGetUserQueryQueryOptions = <TData = Awaited<ReturnType<typeof getUserQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserQuery>>> = ({ signal }) => getUserQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserQueryQueryResult = NonNullable<Awaited<ReturnType<typeof getUserQuery>>>
export type GetUserQueryQueryError = ErrorType<unknown>

export const useGetUserQuery = <TData = Awaited<ReturnType<typeof getUserQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserQuery>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserQueryQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserQuerySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUserQuery>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserQuery>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserQueryQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserQuery>>> = ({ signal }) => getUserQuery(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserQuery>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserQuerySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUserQuery>>>
export type GetUserQuerySuspenseQueryError = ErrorType<unknown>

export const useGetUserQuerySuspense = <TData = Awaited<ReturnType<typeof getUserQuery>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserQuery>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserQuerySuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




              
              
    useGetUserQuery.queryKey = () => getGetUserQueryQueryKey();

    useGetUserQuery.updateCache = ({updater, options}: {updater: Updater<UserResponse | undefined, UserResponse | undefined>, options?: SetDataOptions | undefined}) => queryClient.setQueryData<UserResponse>(getGetUserQueryQueryKey(), updater, options);
    
    useGetUserQuery.getQueryState = () => queryClient.getQueryState<UserResponse>(getGetUserQueryQueryKey());
    
    useGetUserQuery.getQueryData = () => queryClient.getQueryData<UserResponse>(getGetUserQueryQueryKey());
  
    useGetUserQuery.prefetch = () => queryClient.prefetchQuery({ queryKey: getGetUserQueryQueryKey() , queryFn: () => getUserQuery() });
  
    useGetUserQuery.cancelQueries = () => queryClient.cancelQueries({ queryKey: getGetUserQueryQueryKey() })
  
    useGetUserQuery.invalidate = () => queryClient.invalidateQueries({ queryKey: getGetUserQueryQueryKey() });
  
    useGetUserQuery.refetchStale = () => queryClient.refetchQueries({ queryKey: getGetUserQueryQueryKey(), stale: true });
  
            
export const updateUserMutation = (
    userResponse: UserResponse,
 ) => {
      
      
      return userApiInstance<void>(
      {url: `/realms/blox/account`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userResponse
    },
      );
    }
  


export const getUpdateUserMutationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserMutation>>, TError,{data: UserResponse}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateUserMutation>>, TError,{data: UserResponse}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserMutation>>, {data: UserResponse}> = (props) => {
          const {data} = props ?? {};

          return  updateUserMutation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateUserMutationMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserMutation>>>
    export type UpdateUserMutationMutationBody = UserResponse
    export type UpdateUserMutationMutationError = ErrorType<unknown>

    export const useUpdateUserMutation = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserMutation>>, TError,{data: UserResponse}, TContext>, }
) => {

      const mutationOptions = getUpdateUserMutationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
