import { GetProps, useStyle } from "@tamagui/core";
import { ReactNode, useState } from "react";

import { ToggleButtonBase, ToggleButtonOption } from "./ToggleButton.style";

export const ToggleButton = ToggleButtonBase.styleable<{
  value: string;
  onChange: (value: string) => void;
  options: {
    label: ReactNode;
    value: string;
  }[];
  matchFn?: (optionValue: string, currentValue: string) => boolean;
}>(
  (
    {
      options,
      matchFn = (optionValue, currentValue) => optionValue === currentValue,
      onChange,
      value,
      toggleButtonOptionStyle = {},
      ...rest
    },
    ref
  ) => {
    const [focusWithin, setFocusWithin] = useState(false);

    const style = useStyle(toggleButtonOptionStyle, {
      forComponent: ToggleButtonOption,
    });

    return (
      <ToggleButtonBase
        ref={ref}
        borderColor={focusWithin ? "$button" : "$border"}
        $theme-dark={{ borderColor: focusWithin ? "$default" : "$border" }}
        {...rest}
      >
        {options.map((option) => (
          <ToggleButtonOption
            role="button"
            key={option.value}
            active={matchFn(option.value, value)}
            tabIndex={0}
            onPress={() => onChange(option.value)}
            onFocus={() => setFocusWithin(true)}
            onBlur={() => setFocusWithin(false)}
            style={style}
          >
            {option.label}
          </ToggleButtonOption>
        ))}
      </ToggleButtonBase>
    );
  }
);

export type ToggleButtonProps = GetProps<typeof ToggleButton>;
