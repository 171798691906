import { useIsFeatureEnabledQuery } from "@blox/api";
import { FireStoreConfigSetType } from "@blox/shared/firestore/types";
import { DefaultOptions, MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

import { useFirestore } from "./hooks/useFirestore";
import { useStore } from "./hooks/useStore";

type ErrorDetailsResponse = {
  errorDetails: {
    message: string;
    code: keyof FireStoreConfigSetType["errorCodes"] | string;
  };
};

export const hasErrorDetails = (error: AxiosError): error is AxiosError<ErrorDetailsResponse> => {
  return !!(error as AxiosError<ErrorDetailsResponse>)?.response?.data?.errorDetails;
};

export const errorReporting = (error: AxiosError) => {
  if (error.response) {
    if (error.response.status === 503) {
      // If we get a 503 set to maintenance mode
      queryClient.setQueryData(useIsFeatureEnabledQuery.queryKey("BLOX"), { enabled: false });
    }
    // report all 4XX errors and 500 errors
    if (error?.response?.status >= 400 && error?.response?.status <= 500) {
      console.error("API error", error.response);
    }

    if (hasErrorDetails(error)) {
      const locale = useStore.getState().userLocale;

      const { errorCodes } = useFirestore.getState().config.configSet;

      if (error.response.data.errorDetails.code in errorCodes) {
        toast.error(
          errorCodes[error.response.data.errorDetails.code][locale] ||
            errorCodes[error.response.data.errorDetails.code].en
        );
      }
    }
  }
};

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => errorReporting(error as AxiosError),
  }),
  mutationCache: new MutationCache({
    onError: (error) => errorReporting(error as AxiosError),
  }),
  defaultOptions: {
    queries: {
      gcTime: 60000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      // suspense: true,
      retry: (failureCount: number, error: AxiosError) => {
        if (error?.response?.status) {
          if (error?.response?.status > 399 && error?.response?.status < 500) {
            return false;
          }
        }
        return failureCount < 1;
      },
    },
  } as DefaultOptions,
});
