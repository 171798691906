import { UseMutationOptions } from "@tanstack/react-query";

// @ts-ignore
import { queryClient } from "~queryClient";

import {
  getGetUserQueryQueryKey,
  updateUserMutation,
  useUpdateUserMutation,
} from "../__generated__/api-keycloak";
import { UserResponse } from "../__generated__/api-keycloak/model";
import { ErrorType } from "../lib/api-instance";

interface UpdateUserDetailsMutationProps<TError = ErrorType<unknown>, TContext = unknown> {
  mutation: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserMutation>>,
    TError,
    { data: UserResponse },
    TContext
  >;
}

export function useUpdateUserDetailsMutation(props?: UpdateUserDetailsMutationProps) {
  return useUpdateUserMutation({
    mutation: {
      onMutate: ({ data: user }) => {
        const previous = queryClient.getQueryData(getGetUserQueryQueryKey());
        queryClient.setQueryData<UserResponse | undefined>(getGetUserQueryQueryKey(), user);
        return previous;
      },
      onError: (_, __, previous) => {
        queryClient.setQueryData(getGetUserQueryQueryKey(), previous);
      },
      ...props?.mutation,
    },
  });
}
