import "./Footer.scss";

import { Button, Flex, Paragraph, ToggleButton } from "@blox/design-system";
import { SvgEye, SvgEyeSlash } from "@blox/icons";
import {
  getContactUsUrl,
  getDisclaimerUrl,
  getFAQUrl,
  getPrivacyUrl,
  getUserAgreementUrl,
} from "@blox/shared/utils/getLocalizedBloxLinks";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { ReactComponent as IconFacebook } from "../../assets/img/social-facebook.svg";
import { ReactComponent as IconInstagram } from "../../assets/img/social-instagram.svg";
import { ReactComponent as IconLinkedin } from "../../assets/img/social-linkedin.svg";
import { ReactComponent as IconTelegram } from "../../assets/img/social-telegram.svg";
import { ReactComponent as IconTwitter } from "../../assets/img/social-twitter.svg";
import { ReactComponent as IconYoutube } from "../../assets/img/social-youtube.svg";
import { useLocalisation } from "../../context/LocalizeProvider";
import useAssumedUserCountry from "../../hooks/useAssumedUserCountry";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn";
import { ColorScheme, useLocale, useMutateStore, useStore } from "../../hooks/useStore";
import LanSelector from "../LanSelector/LanSelector";

function Footer() {
  const t = useLocalisation();
  const date = new Date();
  const locale = useLocale();
  const excludePhone = useBreakpoint("excludePhone");
  const userCountry = useAssumedUserCountry();
  const isLoggedIn = useIsLoggedIn();

  const colorScheme = useStore((s) => s.colorScheme);
  const mutateStore = useMutateStore();

  const hideVaultValues = useStore((s) => s.hideVaultValues);

  return (
    <div className="footer">
      <Flex gap={24} alignItems="center" flexDirection="column" $excludePhone={{ flexDirection: "row" }}>
        <div className="footer__socials">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/weareblox"
            data-tooltip-content="Facebook"
            data-tooltip-id="facebook-tooltip"
          >
            <IconFacebook />
          </a>
          <ReactTooltip id="facebook-tooltip" />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/weareblox"
            data-tooltip-content="Twitter"
            data-tooltip-id="Twitter-tooltip"
          >
            <IconTwitter />
          </a>
          <ReactTooltip id="Twitter-tooltip" />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/weareblox/"
            data-tooltip-content="LinkedIn"
            data-tooltip-id="LinkedIn-tooltip"
          >
            <IconLinkedin />
          </a>
          <ReactTooltip id="LinkedIn-tooltip" />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/c/weareblox"
            data-tooltip-content="Youtube"
            data-tooltip-id="Youtube-tooltip"
          >
            <IconYoutube />
          </a>
          <ReactTooltip id="Youtube-tooltip" />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://t.me/wearebloxnl"
            data-tooltip-content="Telegram"
            data-tooltip-id="Telegram-tooltip"
          >
            <IconTelegram />
          </a>
          <ReactTooltip id="Telegram-tooltip" />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/weareblox/"
            data-tooltip-content="Instagram"
            data-tooltip-id="Instagram-tooltip"
          >
            <IconInstagram />
          </a>
          <ReactTooltip id="Instagram-tooltip" />
        </div>
        <div>
          <LanSelector noLabel paddingTop={10} paddingBottom={10} />
        </div>
        {isLoggedIn && (
          <Button
            size="flex"
            variant="flat"
            borderColor="$light"
            fontFamily="$body"
            height={42}
            fontSize={17}
            onClick={() =>
              mutateStore((s) => {
                s.hideVaultValues = !s.hideVaultValues;
              })
            }
            Icon={hideVaultValues ? SvgEyeSlash : SvgEye}
            className="footer__toggle-vault"
          >
            {t.text("Footer", "toggleVaultValues")}
          </Button>
        )}
        <ToggleButton
          value={colorScheme!}
          onChange={(value) =>
            mutateStore((s) => {
              s.colorScheme = value as ColorScheme;
            })
          }
          options={[
            {
              label: t.text("Nav", "light"),
              value: "light",
            },
            {
              label: t.text("Nav", "dark"),
              value: "dark",
            },
          ]}
          style={{ order: excludePhone ? 1 : -1 }}
        />
      </Flex>
      <div className="footer__content">
        <Paragraph
          fontSize={16}
          className="footer__copyright"
        >{`© ${date.getFullYear()} BLOX B.V.`}</Paragraph>
        <nav className="footer__nav">
          <a href={getUserAgreementUrl(locale, userCountry)} target="_blank" rel="noreferrer">
            {t.text("Footer", "userAgreement")}
          </a>
          <a href={getDisclaimerUrl(locale, userCountry)} target="_blank" rel="noreferrer">
            {t.text("Footer", "disclaimer")}
          </a>
          <a href={getPrivacyUrl(locale, userCountry)} target="_blank" rel="noreferrer">
            {t.text("Footer", "privacy")}
          </a>
          {/*
              <Link to="/about">{t.text("Support", "about")}</Link>
          */}
          <a href={getFAQUrl(locale, userCountry)} target="_blank" rel="noreferrer">
            {t.text("Support", "faq")}
          </a>
          <a href={getContactUsUrl(locale)} target="_blank" rel="noreferrer">
            {t.text("Support", "contactUs")}
          </a>
        </nav>
      </div>
    </div>
  );
}

export default Footer;
