import { createStyledContext, styled, Text, View } from "@tamagui/core";
import { ComponentPropsWithoutRef, forwardRef, useContext } from "react";

import { Box } from "../Box";

export type InnerLabelStyle = "default" | "icon";
export type InnerLabelPosition = "left" | "right";

type InputWrapperContextType = {
  innerLabelPosition?: InnerLabelPosition;
  innerLabelStyle?: InnerLabelStyle;
  innerLabel?: boolean;
  error?: boolean;
};

export const InputWrapperContext = createStyledContext<InputWrapperContextType>({
  innerLabelPosition: "left",
  innerLabelStyle: "default",
  error: false,
  innerLabel: false,
});

export const InputInnerLabel = styled(Box, {
  name: "InputInnerLabel",
  context: InputWrapperContext,

  display: "flex",
  position: "absolute",
  top: 1,
  bottom: 1,
  left: 1,
  alignItems: "center",
  justifyContent: "center",
  width: 70,
  borderRightColor: "$light",
  borderRightWidth: 1,
  borderTopLeftRadius: "$regular",
  borderBottomLeftRadius: "$regular",
  backgroundColor: "$offWhite",
  color: "$dark",
  pointerEvents: "none",

  "$theme-dark": {
    backgroundColor: "$background",
    color: "$black",
  },

  variants: {
    innerLabelPosition: {
      left: {},
      right: {
        left: "auto",
        right: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: "$regular",
        borderBottomRightRadius: "$regular",
      },
    },

    innerLabelStyle: {
      default: {},
      icon: {
        backgroundColor: "transparent",
        width: 56,
        paddingLeft: 8,
        paddingRight: 8,
        borderRightWidth: 0,
      },
    },

    error: {
      true: {
        borderColor: "$alertLighter",
        backgroundColor: "$alertLightest",
        color: "$alert",
      },
    },
  } as const,
});

export const InputWrapper = styled(
  View,
  {
    name: "InputWrapper",
    context: InputWrapperContext,

    width: "100%",

    variants: {
      error: {
        true: {},
      },
    } as const,
  },
  {
    accept: {
      inputWrapperStyle: "style",
      inputInnerLabelStyle: "style",
    },
  }
);

export const InputWrapperBaseWithInnerLabel = styled(InputWrapper, {
  name: "InputWrapperBaseWithInnerLabel",
  context: InputWrapperContext,

  variants: {
    innerLabel: {
      true: {
        position: "relative",
      },
    },
  } as const,
});

export const InputWrapperWithInnerLabel = InputWrapperBaseWithInnerLabel.styleable<{
  innerLabelPosition?: InnerLabelPosition;
  innerLabelStyle?: InnerLabelStyle;
}>((props, ref) => <InputWrapperBaseWithInnerLabel {...props} ref={ref} />);

export const BareInput = forwardRef<HTMLInputElement, ComponentPropsWithoutRef<"input">>(
  function BareInput(props, ref) {
    return <input ref={ref} {...props} />;
  }
);

export const BareTextarea = forwardRef<HTMLTextAreaElement, ComponentPropsWithoutRef<"textarea">>(
  function BareTextarea(props, ref) {
    return <textarea ref={ref} {...props} />;
  }
);

export const BareSelect = forwardRef<HTMLSelectElement, ComponentPropsWithoutRef<"select">>(
  function BareTextarea(props, ref) {
    return <select ref={ref} {...props} />;
  }
);

export const inputElementBaseStyles = {
  color: "$darker",
  fontFamily: "$body",
  fontSize: 17,
  backgroundColor: "$white",

  "$theme-dark": {
    backgroundColor: "$light",
  },

  variants: {
    disabled: {
      true: {
        color: "$default",
      },
    },
  } as const,
} as const;

export const inputElementStyles = {
  width: "100%",
  padding: 16,
  borderWidth: 1,
  borderColor: "$light",
  borderRadius: "$regular",
  outlineColor: "$bloxBlueDark",

  focusStyle: {
    outlineStyle: "solid",
    outlineWidth: 1,
  },

  variants: {
    error: {
      true: {
        borderColor: "$alert",
      },
    },
  } as const,
} as const;

export const InputElement = styled(
  BareInput,
  {
    name: "InputElement",
    context: InputWrapperContext,

    ...inputElementBaseStyles,
    ...inputElementStyles,

    variants: {
      ...inputElementBaseStyles.variants,
      ...inputElementStyles.variants,
    } as const,
  },
  {
    acceptsClassName: true,
    isInput: true,
  }
);

export const InputElementWithInnerLabel = InputElement.styleable<{
  innerLabelPosition?: InnerLabelPosition;
  innerLabelStyle?: InnerLabelStyle;
  innerLabel?: boolean;
}>((props, ref) => {
  const {
    innerLabel,
    innerLabelStyle,
    innerLabelPosition = "left",
  }: InputWrapperContextType = useContext(InputWrapperContext);

  return (
    <InputElement
      paddingLeft={
        innerLabel && innerLabelPosition === "left"
          ? innerLabelStyle === "icon"
            ? 72
            : 85
          : props.paddingLeft
      }
      paddingRight={
        innerLabel && innerLabelPosition === "right"
          ? innerLabelStyle === "icon"
            ? 72
            : 85
          : props.paddingRight
      }
      {...props}
      ref={ref}
    />
  );
});

export const TextAreaElement = styled(
  BareTextarea,
  {
    name: "TextAreaElement",
    context: InputWrapperContext,

    ...inputElementBaseStyles,
    ...inputElementStyles,

    variants: {
      ...inputElementBaseStyles.variants,
      ...inputElementStyles.variants,
    } as const,
  },
  {
    acceptsClassName: true,
    isInput: true,
  }
);

export const SelectElement = styled(
  BareSelect,
  {
    name: "SelectElement",
    context: InputWrapperContext,

    ...inputElementBaseStyles,
    ...inputElementStyles,
    position: "relative",
    paddingRight: 32,

    variants: {
      ...inputElementBaseStyles.variants,
      ...inputElementStyles.variants,
    } as const,
  },
  {
    acceptsClassName: true,
    isInput: true,
  }
);

export const CheckboxElement = styled(
  BareInput,
  {
    name: "CheckboxElement",

    ...inputElementBaseStyles,
  },
  {
    acceptsClassName: true,
    isInput: true,
  }
);

export const LabelElement = styled(Text, {
  name: "LabelElement",
  tag: "label",

  display: "block",
  marginBottom: 10,
  color: "$dark",
  fontSize: 16,
  fontWeight: 600,
});

export const InputWrapperInput = styled(View, {
  name: "InputWrapperInput",

  position: "relative",
  zIndex: 1,
});
