import "./Paper.css";

import { GetProps } from "@tamagui/core";
import { ReactNode } from "react";

import { Box } from "../Box";
import { Heading } from "../Heading";
import { PaperBase, PaperTitle } from "./Paper.style";

export type PaperProps = {
  title?: string | ReactNode;
  contentPadding?: boolean;
  contentPaddingHorizontal?: boolean;
  contentPaddingVertical?: boolean;
} & Omit<GetProps<typeof PaperBase>, "title">;

export const Paper = ({
  title,
  contentPadding,
  contentPaddingHorizontal,
  contentPaddingVertical,
  children,
  className,
  ...rest
}: PaperProps) => {
  return (
    <PaperBase {...rest}>
      {title && (
        <PaperTitle>
          {typeof title === "string" ? (
            <Heading level={3} marginVertical="$20">
              {title}
            </Heading>
          ) : (
            title
          )}
        </PaperTitle>
      )}
      <Box
        className={className}
        padding={contentPadding ? "$20" : undefined}
        paddingHorizontal={contentPaddingHorizontal ? "$20" : undefined}
        paddingVertical={contentPaddingVertical ? "$20" : undefined}
        $excludePhone={{
          padding: contentPadding ? "$32" : undefined,
          paddingHorizontal: contentPaddingHorizontal ? "$32" : undefined,
          paddingVertical: contentPaddingVertical ? "$32" : undefined,
        }}
        data-paper-content
      >
        {children}
      </Box>
    </PaperBase>
  );
};
