import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useIsLoggedIn } from "./useIsLoggedIn";
import { useMutateStore, useStore } from "./useStore";

export function useReferralVoucher() {
  const location = useLocation();
  const history = useHistory();
  const referralData = useStore((s) => s.referralData);

  const mutateStore = useMutateStore();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const utmSource = String(searchParams.get("utm_source") ?? "").toUpperCase();
    searchParams.delete("utm_source");

    // If the utm_source starts with A_ it's an affiliate voucher
    if (!isLoggedIn && utmSource.startsWith("A_") && referralData?.code !== utmSource) {
      mutateStore((s) => {
        s.referralData = {
          code: utmSource,
          timestamp: new Date().getTime(),
        };
      });

      history.replace({
        ...location,
        search: searchParams.toString(),
      });
    }
  }, [history, location, mutateStore, referralData, isLoggedIn]);
}
