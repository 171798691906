import { Locale } from "@blox/api";
import { Loader } from "@blox/design-system";
import { locales } from "@blox/shared/constants";
import { TypeSafeI18n } from "@blox/shared/i18n/TypeSafeI18n";
import { I18nManager, TranslationDictionary } from "@shopify/react-i18n";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useCrowdin } from "../hooks/useCrowdin";
import { useLocale, useMutateStore, useStore } from "../hooks/useStore";
import type source from "../translations/source.json";

export const extendedLocales: Record<Locale, string> = {
  en: "en-GB",
  nl: "nl-NL",
  es: "es-ES",
  fr: "fr-FR",
};

export const languageLabels: Record<Locale, string> = {
  en: "English",
  nl: "Nederlands",
  es: "Español",
  fr: "Français",
};

export const isSupportedLocale = (locale: string): locale is Locale => locales.includes(locale as Locale);

export let defaultLocale = "en" as Locale;
const systemPreferenceLan = (window.navigator.language || defaultLocale).substring(0, 2);
const systemPreference = locales.find((lan) => lan === systemPreferenceLan);

if (systemPreference === "nl" || systemPreference === "en") {
  defaultLocale = systemPreference;
}

export const createI18n = (locale: Locale, translations: TranslationDictionary, hideVaultValues = false) => {
  const i18nManager = new I18nManager({
    locale,
    currency: "EUR",
    onError(error) {
      console.error(error);
    },
  });
  i18nManager.register({ id: "global" });
  return new TypeSafeI18n<Translations>(
    [translations],
    { ...i18nManager.details },
    { e2eMode: Boolean(window.sessionStorage.getItem("LANGUAGE_CI_MODE")), hideVaultValues }
  );
};

export const I18nContext = React.createContext<TypeSafeI18n<Translations>>(null!);

export default function LocalizeProvider({ children }: { children: React.ReactNode }) {
  const history = useHistory();
  const location = useLocation();
  const locale = useLocale();
  const mutateStore = useMutateStore();
  const hideVaultValues = useStore((s) => s.hideVaultValues);

  const { data: translations, isSuccess } = useCrowdin(locale);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParamLanguage = searchParams.get("lan");
    searchParams.delete("lan");

    if (queryParamLanguage && queryParamLanguage in extendedLocales) {
      mutateStore((s) => {
        s.userLocale = queryParamLanguage.toLowerCase() as Locale;
      });
      history.replace({
        ...location,
        search: searchParams.toString(),
      });
    }
  }, [history, location, location.pathname, location.search, mutateStore]);

  const i18n = React.useMemo(
    () => (isSuccess ? createI18n(locale, translations, hideVaultValues) : null),
    [hideVaultValues, isSuccess, locale, translations]
  );

  if (!i18n) {
    return <Loader variant="fullScreen" />;
  }

  return <I18nContext.Provider value={i18n}>{children}</I18nContext.Provider>;
}

export function useLocalisation() {
  return React.useContext(I18nContext);
}

export type Translations = typeof source;
